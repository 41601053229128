.jodit_container .jodit_workplace,
.jodit_container .jodit_toolbar,
.jodit_container .jodit_statusbar,
.jodit_container
  .jodit_toolbar
  > li.jodit_toolbar_btn.jodit_toolbar_btn-separator,
.jodit_container .jodit_toolbar > li.jodit_toolbar_btn.jodit_toolbar_btn-break {
  border-color: #8897a2 !important;
}
.jodit_container .jodit_toolbar,
.jodit_container .jodit_statusbar {
  background-color: #f4f7fb;
}
.jodit_container {
  background-color: #f4f7fb;
}
