.sticky-top {
  z-index: 120;
}

.modal {
  &.fsmodal {
    .modal-content {
      min-height: 100vh;
    }
    // Scale up the fs modal
    @include media-breakpoint-up(sm) {
      .modal-dialog {
        max-width: 100vw;
        margin: 0 auto;
      }

      .modal-dialog-centered {
        min-height: 100vh;
      }

      .modal-sm {
        max-width: 100vw;
      }
    }

    @include media-breakpoint-up(lg) {
      .modal-lg,
      .modal-xl {
        max-width: 100vw;
      }
    }

    @include media-breakpoint-up(xl) {
      .modal-xl {
        max-width: 100vw;
      }
    }
  }
}

html[data-layout="mobile"] {
  div[class*="col-"] {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .layout-root {
    overflow-y: hidden;
  }
}

#active-orgGlobalItem {
  > div {
    width: 24px !important;
    height: 24px !important;
    border-radius: 12px !important;
    overflow: hidden !important;
  }
}

.swan-header-img-wrapper img {
  width: 100%;
}
