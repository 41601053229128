@import "../../global/colours/mixins";
.panel {
  .panel-switcher {
    width: 1rem;
    padding: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
    appearance: caret;

    align-self: stretch;

    .switcher-button {
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $brand-01;
      color: $field-01;
      cursor: pointer;
    }
  }

  &.is-open {
    .panel-content {
      border: 1px solid $brand-01;
    }

    transition: all 0.2s;
  }
}
