@import "./form_group";

$swan-form-control-tabular--height: 2.4rem;

td,
tr {
  &.table-row-rendered {
    padding: 0;
  }

  &.table-cell-rendered {
    padding: 0;
  }

  @include swan-form-group {
    margin: 0;
    .form-control {
      //   background: transparent;
      border-bottom: 1px solid transparent;
      box-shadow: 0 1px 0 0 transparent;
      height: $swan-form-control-tabular--height;
      vertical-align: middle;

      font-size: 1rem;
      line-height: 1.5;

      &:not(textarea):not(.quill):not(.wysiwyg) {
        height: $swan-form-control-tabular--height;
      }

      &:focus {
        outline: none;
        box-shadow: 0 2px 0 0 transparent;
        transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }

    .select2-control {
      > div {
        height: $swan-form-control-tabular--height;
        background: transparent;
        box-shadow: none;
      }
    }
  }
}
