@import "../../global/vars";
@import "../../global/spacing";

// $input-label-ratio: 3;
// $input-control-ratio: 7;
// $input-label-percent: ((100 / ($input-label-ratio + $input-control-ratio)) * $input-label-ratio);
// $input-control-percent: (100 / ($input-label-ratio + $input-control-ratio)) * $input-control-ratio;

$input-label-percent: 30%;
$input-control-percent: 70%;
$input-control-100-percent: 100% !global;

@mixin swan-form-group {
  .form-group,
  .form-group-alike,
  .#{$prefix}-form-group-alike,
  .#{$prefix}-form-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin-top: $spacing-2xs;
    margin-bottom: $spacing-sm;

    select,
    label,
    .form-control {
      border: none;
      background-clip: border-box;
      border-radius: 0;
      border-style: none;
    }

    .form-check {
      flex: 1 0 70%;
      border-bottom: 1px solid transparent;
      box-shadow: 0 1px 0 0 #8897a2;
      padding-bottom: 0.4rem;

      &.form-radio {
        border-bottom: none;
        box-shadow: none;
        // padding-top: 0.4rem;
      }
    }

    &.hide-it {
      display: none;
    }

    @content;
  }
}

// 0. common  defaults
// default form-contro height
@mixin swan-form-control-default--height {
  // height: $layout-md;
  // height: auto;
  height: 2rem;
}

@mixin swan-form-control-default--height--sm {
  height: 2rem;
}

// keep this portion separate for now, without putting it into swan-form-control-default--height mixin :☝️
@mixin swan-form-control-default--line-height {
  line-height: $layout-md;
}

@mixin swan-form-control-default--line-height--sm {
  line-height: $layout-md;
}

@mixin swan-form-control-default--spacing {
  padding: $spacing-nil $spacing-sm;
}

@mixin swan-form-control-default--spacing--sm {
  padding: $spacing-nil $spacing-xs;
}

// 1. defaults when device is larger
// 1.a. form label
@mixin swan-form-label-default {
  order: -1;
  flex: 1 0 $input-label-percent;
}

// 1.b. form control
@mixin swan-form-control-default {
  order: 0;
  flex: 1 0 $input-control-percent;
}

// 1.c. form text
@mixin swan-form-text-default {
  order: 1;
  flex: 0 1 100%;
}

// 2. defualts when sm breakpoint
// 2.a. form group
@mixin swan-form-group-sm-default {
  flex-direction: row;
}

// 2.b form label
@mixin swan-form-label-sm-default {
  order: -1;
  // margin-bottom: $spacing-sm;
  margin-bottom: $spacing-4xs;
}

// 2.c form text
@mixin swan-form-text-sm-default {
  order: 0;
  text-align: left;
  // margin-bottom: $spacing-sm;
  margin-bottom: $spacing-2xs;
}

// 2.d from control
@mixin swan-form-control-sm-default {
  order: 1;
  // margin-bottom: $spacing-md;
  margin-bottom: $spacing-4xs;
}
