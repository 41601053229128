// Carbon Theme v 0.1.0
// By SWANT Team

// @todo: _index.scss is not yet seemingly supported, until that we used index.scss format

// overrides
// i. ibm font family
$font-family-mono: "ibm-plex-mono", "Menlo", "DejaVu Sans Mono",
  "Bitstream Vera Sans Mono", Courier, monospace;
$font-family-sans-serif: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
$font-family-serif: "ibm-plex-serif", "Georgia", Times, serif;
$font-family-helvetica: "IBM Helvetica", Helvetica Neue, HelveticaNeue,
  Helvetica, sans-serif;

@import "rescind/index";

// vendor
@import "vendor/bootstrap/index";
@import "vendor/datepicker/index";

// mucky overrides
@import "overrides";

// mucky
@import "global/index";
