@import "../../global/colours/mixins";

.btn {
  border-radius: 0;

  &.btn:focus {
    border: 2px solid #f4f4f4;
    outline: 2px solid #6c757d;
  }

  &.tag-button {
    padding: 0.1rem 0.25rem;
    font-size: 0.8rem;
    border-radius: 0.1rem;
  }

  &.outline {
    &.outline-clear {
      border: none;
      outline: none;
    }

    &:hover,
    &:focus,
    &:active {
      border: none !important;
      outline: none !important;
      background-color: transparent !important;
      color: $brand-01 !important;
      box-shadow: none !important;
    }
  }

  .control-label-container {
    display: inline-block;
  }

  &.loading {
    .control-label-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .control-label {
        padding-left: 0.4rem;
      }
    }

    &::after {
      display: none;
    }
  }
}
