// $datepicker__background-color: #f0f0f0 !default;
// $datepicker__border-color: #aeaeae !default;
// $datepicker__highlighted-color: #3dcc4a !default;
// $datepicker__muted-color: #ccc !default;
// $datepicker__selected-color: #216ba5 !default;
// $datepicker__text-color: #000 !default;
// $datepicker__header-color: #000 !default;
// $datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%)
//   !default;

$datepicker__border-radius: 0;
// $datepicker__day-margin: 0.166rem !default;
// $datepicker__font-size: 0.8rem !default;
// $datepicker__font-family: "Helvetica Neue", Helvetica, Arial,
//   sans-serif !default;
// $datepicker__item-size: 1.7rem !default;
// $datepicker__margin: 0.4rem !default;
// $datepicker__navigation-size: 0.45rem !default;
// $datepicker__triangle-size: 8px !default;

@import "~react-datepicker/src/stylesheets/datepicker";

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.swan-datepicker-popper {
  z-index: 999;
}

.datetime-center {
  text-align: center;
}
