@import "../../global/colours/mixins";
@import "../../global/typography/mixins";
@import "../form/form_group";

.PhoneInput {
  @include swan-form-control-default;
  background-color: $field-01;
}

@include media-breakpoint-down(sm) {
  .PhoneInput {
    @include swan-form-control-sm-default;
  }
}

.PhoneInput {
  @include swan-form-control-default--spacing;
  @include swan-form-control-default--height;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px 0 0 $ui-04;
  display: flex;
  .PhoneInputCountry {
    align-self: stretch;
  }
  .PhoneInputInput {
    align-self: stretch;
    height: 100%;
    border: none;
    background: transparent;
    @include font-size("13");

    &:focus-visible {
      outline: none;
    }
  }
}

.PhoneInput--focus {
  box-shadow: 0 2px 0 0 $brand-01;
  transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);

  & ~ label {
    color: $brand-01;
  }
}

.PhoneInputCountrySelect:focus + .PhoneInputCountrySelectArrow,
.react-phone-number-input__country.rrui__select--focus .rrui__select__arrow {
  color: $brand-01 !important;
}
