@import "../../global/vars";
@import "../../global/spacing";

.form-group,
.#{$prefix}-form-group {
  select {
    &[multiple] {
      height: auto !important;
      padding: $spacing-md !important;
    }

    & ~ label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // align-items: flex-start;
      // padding-top: $spacing-md;
    }
  }
}

@include media-breakpoint-down(sm) {
  .form-group,
  .#{$prefix}-form-group {
    select {
      &[multiple] {
        padding: $spacing-xs;
      }
      & ~ label {
        justify-content: flex-end;
        padding-top: 0;
      }
    }
  }
}
