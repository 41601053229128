@import "../../global/typography/mixins";

.select2 {
  // display: flex;
  // flex-direction: row;
  // width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .select2-label {
    flex: 1 0 30%;
    // width: 30%;
    order: 1;
  }

  & > div {
    flex-grow: 1;
    order: 2;
    // height: $layout-md;

    & > div {
      background: $field-01;
    }
  }
}

.select2-control {
  flex: 1 0 $input-control-percent;

  &.select2-control-selected {
    & ~ label {
      color: $brand-01;
    }

    & > div {
      box-shadow: 0 2px 0 0 $brand-01;
      transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
      min-height: auto !important;
    }
  }

  &[data-required],
  &.select2-control-required {
    & ~ label {
      position: relative;

      &.label-readonly {
        &::after {
          background: map-get($rainbow, "ui-05--l10");
        }
      }

      &::after {
        content: "";
        width: 2rem;
        height: 2px;
        display: inline-block;
        position: absolute;
        bottom: -3px;
        left: 0;
        background: map-get($rainbow, "support-01--l10");
      }
    }

    & ~ .form-text {
    }
  }

  .select2-label {
  }

  & > div {
    // border: none;
    @include font-size("14");
    border: none;
    // box-shadow: 0 1px 0 0 #5a6872;
    border-radius: 0;
    // background: $field-01;
    // height: 3rem;
    background-color: rgba($field-01, 0.6);
    min-height: auto !important;

    &:first-child {
      box-shadow: 0 1px 0 0 rgba($ui-04, 0.3);
    }

    & > div:last-child {
      // order: 1;

      & > span {
        display: none;
      }
    }

    & > div:first-child {
      // order: 2;
    }

    & > div {
      margin: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .select2-control {
    flex-basis: 70%;
    flex-shrink: 1;
    @include swan-form-control-sm-default;
    @include swan-form-control-default--spacing--sm;
    @include swan-form-control-default--height--sm;
    background: white;

    ~ label {
    }

    .remoteselect-selecting-button {
      @include font-size("10");
    }

    &.select2-control-selected {
      @include swan-form-control-sm-default;
      @include swan-form-control-default--spacing--sm;
      @include swan-form-control-default--height--sm;
      & ~ label {
      }

      & > div {
      }
    }

    &[data-required],
    &.select2-control-required {
      & ~ label {
        &.label-readonly {
          &::after {
          }
        }

        &::after {
          left: unset;
          right: 0;
        }
      }

      & ~ .form-text {
      }
    }

    .select2-label {
    }

    & > div {
      &:first-child {
        // height: 1.5rem;
        box-shadow: none;
        height: 100%;
        background: white;
      }

      & > div:last-child {
        // order: 1;

        & > span {
        }
      }

      & > div:first-child {
        // order: 2;
      }

      & > div {
        padding: 0;
      }
    }
  }
}
