@import "../../global/colours/mixins";

.modal-content {
  border-radius: 0;

  border-top: 4px solid $brand-01;
}

.modal-footer {
  background: $ui-02;
}

@media only screen and (min-width: 1200px) {
  .modal-xl {
    max-width: 90%;
  }
}

@media only screen and (min-width: 1824px) {
  .modal-xl {
    max-width: 1700px;
  }
}
