@media (min-width: 576px) {
  .jumbotron {
    &.swan_cta {
      padding: 2rem 2rem !important;
    }
    &.swan_cta_blue {
      padding: 2rem 2rem !important;
      background-color: #3d70b2;
      color: #fff;
      .form-group .form-control {
        background-color: #ffffff;
      }
    }
  }
}
