@import "colours/mixins";
@import "spacing";

html:not([data-layout="mobile"]) {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: $brand-02;
  }
  ::-webkit-scrollbar-thumb {
    background: $brand-01;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: map-get($rainbow, "brand-01--d10");
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: $brand-01;
}
