@import "../../global/colours/mixins";

.dropdown-menu {
  border-radius: 0;
  border: 1px solid $ui-03;

  &[data-placement="top-end"] {
    box-shadow: 0 1px 1px 0 $ui-03;
  }
  &[data-placement="bottom-end"] {
    box-shadow: 0 0 1px 1px $ui-03;
  }
}
