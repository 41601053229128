@import "../global/helpers";
@import "../global/colours/mixins";

.breadcrumb {
  @include borderless;
  background: none;
  margin-bottom: 0;
}

html:not([data-layout="mobile"]) {
  .breadcrumb {
    @include borderless;
    background: none;
    margin-bottom: 1rem;

    .breadcrumb-item.active {
      flex-basis: 100%;
      font-size: 22px;
      font-weight: bold;
      padding-left: 0;
      margin-top: 0.5rem;

      &:before {
        display: none;
      }
    }
  }
}

.main-breadcrumb {
  .breadcrumb {
    padding: 0;
  }
}

body.has-internal-breadcrumb {
  .main-breadcrumb {
    display: none;
  }
}

body.hide-bread-crumbs {
  .internal-breadcrumb {
    display: none !important;
  }
}

html[data-layout="mobile"] {
  .internal-breadcrumb {
    padding-top: 0.5rem;
  }

  .module :not(.internal-breadcrumb) {
    &.main-breadcrumb {
      padding-top: 58px;

      .breadcrumb {
        padding: 0 0.75rem 1rem;
      }
    }
  }
}
