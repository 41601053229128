.react-fine-uploader-gallery-file-input-container {
  width: 130px;
  background: #367bff;
  border: 1px solid #367bff;
}

.react-fine-uploader-gallery-file-input-container:hover {
  background: #367bff;
  border: 1px solid #367bff;
}

.react-fine-uploader-gallery-dropzone {
  border: 2px dashed #e6e6e6;
}

.react-fine-uploader-gallery-dropzone-upload-icon {
  fill: #b9b9b9;
  margin-top: -11px;
}

.react-fine-uploader-gallery-file-input-content svg {
  margin-bottom: 0;
}

.react-fine-uploader-gallery-dropzone-content {
  color: #949494;
}

.react-fine-uploader-gallery-dropzone-content,
.react-fine-uploader-gallery-nodrop-content {
  font-size: 25px;
}

.react-fine-uploader-gallery-total-progress-bar-container {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.react-fine-uploader-gallery-file {
  padding: 12px;
  height: initial;
}

.react-fine-uploader-gallery-thumbnail-container {
  display: none;
}

.react-fine-uploader-gallery-upload-success-icon {
  display: none;
}

.react-fine-uploader-gallery-thumbnail-icon-backdrop {
  display: none;
}

.react-fine-uploader-gallery-progress-bar-container {
  height: 6px !important;
}
