// colors
@import "../functions";
@import "../vars";

@mixin swan-colours {
  $brand-01: #3d70b2 !default !global;
  $brand-02: #5596e6 !default !global;
  $brand-03: #41d6c3 !default !global;
  $ui-01: #ffffff !default !global;
  $ui-02: #f4f7fb !default !global;
  $ui-03: #dfe3e6 !default !global;
  $ui-04: #8897a2 !default !global;
  $ui-05: #5a6872 !default !global;
  $ui-06: #f6f6f6 !default !global;
  $text-01: #152935 !default !global;
  $text-02: #5a6872 !default !global;
  $text-03: #cdd1d4 !default !global;
  $inverse-01: #ffffff !default !global;
  $inverse-02: #272d33 !default !global;
  $field-01: #f4f7fb !default !global;
  $field-02: #ffffff !default !global;
  $hover-primary: #30588c !default !global;
  $hover-primary-text: #294c86 !default !global;
  $hover-danger: #bd1427 !default !global;
  $hover-secondary: #3d70b2 !default !global;
  $hover-row: rgba(85, 150, 230, 0.1) !default !global;
  $support-01: #e0182d !default !global;
  $support-02: #5aa700 !default !global;
  $support-03: #efc100 !default !global;
  $support-04: #5aaafa !default !global;
}

@mixin swan-palette {
  $swan-palette: (
    BRAND_01: $brand-01,
    BRAND_02: $brand-02,
    BRAND_03: $brand-03,
    UI_01: $ui-01,
    UI_02: $ui-02,
    UI_03: $ui-03,
    UI_04: $ui-04,
    UI_05: $ui-05,
    TEXT_01: $text-01,
    TEXT_02: $text-02,
    TEXT_03: $text-03,
    INVERSE_01: $inverse-01,
    INVERSE_02: $inverse-02,
    FIELD_01: $field-01,
    FIELD_02: $field-02,
    HOVER_PRIMARY: $hover-primary,
    HOVER_PRIMARY_TEXT: $hover-primary-text,
    HOVER_DANGER: $hover-danger,
    HOVER_SECONDARY: $hover-secondary,
    HOVER_ROW: $hover-row,
    SUPPORT_01: $support-01,
    SUPPORT_02: $support-02,
    SUPPORT_03: $support-03,
    SUPPORT_04: $support-04,
  ) !default !global;
}

@mixin swan-rainbow($palette) {
  $rainbow: create-rainbow-from-palette($palette) !default !global;

  @each $color-name, $hex in $rainbow {
    .#{$color-name}--color {
      color: $hex;
    }
    .#{$color-name}--bg {
      background: $hex;
    }
  }
}
