@import "./variable";

@import "~@coreui/coreui-pro/scss/functions";
@import "~@coreui/coreui-pro/scss/variables";
@import "~@coreui/coreui-pro/scss/mixins";

@import "~@coreui/coreui-pro/scss/sidebar";

.sidebar-nav-item--group a {
  white-space: initial !important;
}

.sidebar {
  &:not(.sidebar-narrow) {
    z-index: 199;
  }
}

@media (max-width: 767.98px) {
  .sidebar:not(.sidebar-end):not(.show):not(.sidebar-narrow) {
    margin-left: 0;
  }

  .sidebar-backdrop {
    display: none;
  }

  .global-navigation.global-navigation-disabled {
    display: none;
  }
  .sidebar.sidebar-narrow {
    display: none;
  }
}
