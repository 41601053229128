@import "form_group";

.form-map-control-filter {
  flex: 1 0 16.5%;
}
.form-map-control-two-inputs {
  flex: 1 0 30%;
}
.form-map-control-input {
  flex: 1 0 60%;
}
.form-map-control-input-with-button {
  flex: 1 0 70%;
}
.form-map-control-filter .form-group {
  margin: 0px;
}

// no need to setup a flex-basis
// .filter-map-icon {
//   flex: 1 0 2.5%;
// }

.hide-map {
  display: none;
}
.map-filters-bar {
  @include swan-form-control-default;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@include media-breakpoint-down(md) {
  .form-map-control-filter {
    flex: 1 0 100%;
  }
  .map-filters-bar {
    flex-wrap: wrap;
  }
  .form-map-control-input {
    flex: 1 0 79%;
  }
  .form-map-control-input-with-button {
    flex: 1 0 60%;
  }
  .form-group .map-filters-bar .form-control {
    margin-bottom: 0px;
  }
}

@include media-breakpoint-down(xs) {
  .form-map-control-filter {
    flex: 1 0 100%;
  }
  .map-filters-bar {
    flex-wrap: wrap;
  }
  .form-map-control-two-inputs {
    flex: 1 0 100%;
  }
  .form-map-control-input {
    flex: 1 0 100%;
  }
  .form-map-control-input-with-button {
    flex: 1 0 100%;
  }
  .filter-map-icon {
    // display: block;
    justify-content: center;
  }
  .filter-map-icon .input-group-append {
    justify-content: center;
  }
}
