@import "colours/mixins";

// SWAN colors
@include swan-colours;

// SWAN colour palette
@include swan-palette;

// SWAN color and background classes: rainbow of colour classes
@include swan-rainbow($swan-palette);

::selection {
  background: $brand-03;
}
