@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@function create-rainbow-from-palette($palette) {
  $rainbow: ();
  @each $name, $hex in $palette {
    @for $i from 0 through 10 {
      $rainbow-name: str-replace(to-lower-case($name), "_", "-");
      $suffix: $i * 10;

      @if ($i == 0) {
        $rainbow: map-merge($rainbow, (#{$rainbow-name}: $hex));
      }

      @if ($i != 0) {
        $rainbow-d-name: #{$rainbow-name}--d#{$suffix};
        $rainbow-l-name: #{$rainbow-name}--l#{$suffix};

        $color-darken: darken($hex, $suffix * 1%);
        $color-lighten: lighten($hex, $suffix * 1%);

        $rainbow: map-merge(
          $rainbow,
          (
            #{$rainbow-d-name}: $color-darken,
            #{$rainbow-l-name}: $color-lighten
          )
        );
      }
    }
  }
  @return $rainbow;
}
