@import "import-once";
@import "vars"; // for prefix for now
@import "typography/mixins"; // typography mixins
@import "typography/reset"; // typographic resets
@import "typography/font-face"; // font families

// really inspired by carbon design system
@mixin typography {
  // really big
  .#{$prefix}--type-giga {
    @include typescale("giga");
    @include reset;
    @include line-height("heading");
    font-weight: 300;
  }

  .#{$prefix}--type-mega {
    @include typescale("mega");
    @include reset;
    @include line-height("heading");
    font-weight: 300;
  }

  // really small
  .#{$prefix}--type-omega {
    @include typescale("omega");
    @include reset;
    @include line-height("heading");
    font-weight: 600;
  }

  .#{$prefix}--type-caption {
    @include typescale("caption");
    @include reset;
    @include line-height("body");
    font-weight: 400;
  }

  .#{$prefix}--type-legal {
    @include typescale("legal");
    @include reset;
    @include line-height("body");
    font-weight: 400;
  }

  .#{$prefix}--type-caps {
    text-transform: uppercase;
  }

  strong,
  .#{$prefix}--type-strong {
    @include reset;
    font-weight: 700;
  }

  p {
    @include reset;
    @include typescale("p");
    @include line-height("body");
    font-weight: 400;
  }

  em {
    @include reset;
    font-style: italic;
  }

  a {
    @include reset;
    color: $brand-01;
  }

  h1,
  .#{$prefix}--type-alpha {
    @include reset;
    @include typescale("alpha");
    @include line-height("heading");
    font-weight: 300;
  }

  h2,
  .#{$prefix}--type-beta {
    @include reset;
    @include typescale("beta");
    @include line-height("heading");
    font-weight: 300;
  }

  h3,
  .#{$prefix}--type-gamma {
    @include reset;
    @include typescale("gamma");
    @include line-height("heading");
    font-weight: 300;
  }

  h4,
  .#{$prefix}--type-delta {
    @include reset;
    @include typescale("delta");
    @include line-height("heading");
    font-weight: 600;
  }

  h5,
  .#{$prefix}--type-epsilon {
    @include reset;
    @include typescale("epsilon");
    @include line-height("heading");
    font-weight: 600;
  }

  h6,
  .#{$prefix}--type-zeta {
    @include reset;
    @include typescale("zeta");
    @include line-height("heading");
    font-weight: 600;
  }
}

@include exports("css--typography") {
  @if global-variable-exists("css--typography") ==
    false or
    $css--typography ==
    true
  {
    @include typography;
  }
}
