@media (min-width: 576px) {
  .jumbotron.swan_cta {
    padding: 2rem 2rem !important; }
  .jumbotron.swan_cta_blue {
    padding: 2rem 2rem !important;
    background-color: #3d70b2;
    color: #fff; }
    .jumbotron.swan_cta_blue .form-group .form-control {
      background-color: #ffffff; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropdown-menu-left {
  right: auto;
  left: 0; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:first-child {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .dropdown-item:last-child {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.875rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8125rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card {
  overflow: hidden; }
  .accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media screen and (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-3by4::before {
  padding-top: 133.33333%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f0f0f0; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #aeaeae; }

.react-datepicker-wrapper {
  display: inline-block; }

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0;
  display: inline-block;
  position: relative; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem; }

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }
  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px; }
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px; }
  .react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px; }
    .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
      left: auto;
      right: 42px; }
  .react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px; }
    .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
      left: 42px;
      right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 8px;
  position: relative; }
  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden; }
  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #ccc; }
    .react-datepicker__navigation--previous:hover {
      border-right-color: #b3b3b3; }
    .react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #ccc; }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 80px; }
    .react-datepicker__navigation--next:hover {
      border-left-color: #b3b3b3; }
    .react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .react-datepicker__navigation--years-previous {
      top: 4px;
      border-top-color: #ccc; }
      .react-datepicker__navigation--years-previous:hover {
        border-top-color: #b3b3b3; }
    .react-datepicker__navigation--years-upcoming {
      top: -4px;
      border-bottom-color: #ccc; }
      .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: #b3b3b3; }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }
  .react-datepicker__month .react-datepicker__month-text {
    display: inline-block;
    width: 4rem;
    margin: 2px; }

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left; }
  .react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block; }
  .react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
        width: 85px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
        -moz-appearance: textfield; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px; }
  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0; }
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white; }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 70px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center; }
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (1.7rem / 2));
        overflow-y: scroll;
        padding-right: 0px;
        padding-left: 0px;
        width: 100%;
        box-sizing: content-box; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
            cursor: pointer;
            background-color: #f0f0f0; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: #216ba5;
            color: white;
            font-weight: bold; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
              background-color: #216ba5; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
            color: #ccc; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
              cursor: default;
              background-color: transparent; }

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer; }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
      border-radius: 0;
      background-color: #f0f0f0; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
  border-radius: 0;
  background-color: #216ba5;
  color: #fff; }
  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
    background-color: #1d5d90; }

.react-datepicker__month--disabled {
  color: #ccc;
  pointer-events: none; }
  .react-datepicker__month--disabled:hover {
    cursor: default;
    background-color: transparent; }

.react-datepicker__day,
.react-datepicker__month-text {
  cursor: pointer; }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover {
    border-radius: 0;
    background-color: #f0f0f0; }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today {
    font-weight: bold; }
  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted {
    border-radius: 0;
    background-color: #3dcc4a;
    color: #fff; }
    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover {
      background-color: #32be3f; }
    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1 {
      color: magenta; }
    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2 {
      color: green; }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range {
    border-radius: 0;
    background-color: #216ba5;
    color: #fff; }
    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover {
      background-color: #1d5d90; }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    border-radius: 0;
    background-color: #2a87d0;
    color: #fff; }
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover {
      background-color: #1d5d90; }
  .react-datepicker__day--in-selecting-range ,
  .react-datepicker__month-text--in-selecting-range {
    background-color: rgba(33, 107, 165, 0.5); }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range {
    background-color: #f0f0f0;
    color: #000; }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled {
    cursor: default;
    color: #ccc; }
    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover {
      background-color: transparent; }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
  background-color: #216ba5; }

.react-datepicker__month-text:hover {
  background-color: #f0f0f0; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0; }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer; }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0;
  border: 1px solid #aeaeae; }
  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer; }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #ccc; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #b3b3b3; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px; }

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 7px;
  height: 16px;
  width: 16px;
  margin: -8px auto 0; }
  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    content: "\00d7"; }

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem; }
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem; } }
  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem; }
  .react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent; }
  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc; }
    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
      border-right-color: #b3b3b3; }
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc; }
    .react-datepicker__portal .react-datepicker__navigation--next:hover {
      border-left-color: #b3b3b3; }
    .react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #e6e6e6;
      cursor: default; }

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block; }

.swan-datepicker-popper {
  z-index: 999; }

.datetime-center {
  text-align: center; }

.sticky-top {
  z-index: 120; }

.modal.fsmodal .modal-content {
  min-height: 100vh; }

@media (min-width: 576px) {
  .modal.fsmodal .modal-dialog {
    max-width: 100vw;
    margin: 0 auto; }
  .modal.fsmodal .modal-dialog-centered {
    min-height: 100vh; }
  .modal.fsmodal .modal-sm {
    max-width: 100vw; } }

@media (min-width: 992px) {
  .modal.fsmodal .modal-lg,
  .modal.fsmodal .modal-xl {
    max-width: 100vw; } }

@media (min-width: 1200px) {
  .modal.fsmodal .modal-xl {
    max-width: 100vw; } }

html[data-layout="mobile"] div[class*="col-"] {
  padding-left: 5px !important;
  padding-right: 5px !important; }

html[data-layout="mobile"] .layout-root {
  overflow-y: hidden; }

#active-orgGlobalItem > div {
  width: 24px !important;
  height: 24px !important;
  border-radius: 12px !important;
  overflow: hidden !important; }

.swan-header-img-wrapper img {
  width: 100%; }

.brand-01--color {
  color: #3d70b2; }

.brand-01--bg {
  background: #3d70b2; }

.brand-01--d10--color {
  color: #30588c; }

.brand-01--d10--bg {
  background: #30588c; }

.brand-01--l10--color {
  color: #5b8ac7; }

.brand-01--l10--bg {
  background: #5b8ac7; }

.brand-01--d20--color {
  color: #234066; }

.brand-01--d20--bg {
  background: #234066; }

.brand-01--l20--color {
  color: #81a5d4; }

.brand-01--l20--bg {
  background: #81a5d4; }

.brand-01--d30--color {
  color: #162840; }

.brand-01--d30--bg {
  background: #162840; }

.brand-01--l30--color {
  color: #a7c0e1; }

.brand-01--l30--bg {
  background: #a7c0e1; }

.brand-01--d40--color {
  color: #09101a; }

.brand-01--d40--bg {
  background: #09101a; }

.brand-01--l40--color {
  color: #cddbee; }

.brand-01--l40--bg {
  background: #cddbee; }

.brand-01--d50--color {
  color: black; }

.brand-01--d50--bg {
  background: black; }

.brand-01--l50--color {
  color: #f3f6fb; }

.brand-01--l50--bg {
  background: #f3f6fb; }

.brand-01--d60--color {
  color: black; }

.brand-01--d60--bg {
  background: black; }

.brand-01--l60--color {
  color: white; }

.brand-01--l60--bg {
  background: white; }

.brand-01--d70--color {
  color: black; }

.brand-01--d70--bg {
  background: black; }

.brand-01--l70--color {
  color: white; }

.brand-01--l70--bg {
  background: white; }

.brand-01--d80--color {
  color: black; }

.brand-01--d80--bg {
  background: black; }

.brand-01--l80--color {
  color: white; }

.brand-01--l80--bg {
  background: white; }

.brand-01--d90--color {
  color: black; }

.brand-01--d90--bg {
  background: black; }

.brand-01--l90--color {
  color: white; }

.brand-01--l90--bg {
  background: white; }

.brand-01--d100--color {
  color: black; }

.brand-01--d100--bg {
  background: black; }

.brand-01--l100--color {
  color: white; }

.brand-01--l100--bg {
  background: white; }

.brand-02--color {
  color: #5596e6; }

.brand-02--bg {
  background: #5596e6; }

.brand-02--d10--color {
  color: #297bdf; }

.brand-02--d10--bg {
  background: #297bdf; }

.brand-02--l10--color {
  color: #81b1ed; }

.brand-02--l10--bg {
  background: #81b1ed; }

.brand-02--d20--color {
  color: #1b62ba; }

.brand-02--d20--bg {
  background: #1b62ba; }

.brand-02--l20--color {
  color: #aecdf3; }

.brand-02--l20--bg {
  background: #aecdf3; }

.brand-02--d30--color {
  color: #154b8d; }

.brand-02--d30--bg {
  background: #154b8d; }

.brand-02--l30--color {
  color: #dae8fa; }

.brand-02--l30--bg {
  background: #dae8fa; }

.brand-02--d40--color {
  color: #0e3361; }

.brand-02--d40--bg {
  background: #0e3361; }

.brand-02--l40--color {
  color: white; }

.brand-02--l40--bg {
  background: white; }

.brand-02--d50--color {
  color: #081c34; }

.brand-02--d50--bg {
  background: #081c34; }

.brand-02--l50--color {
  color: white; }

.brand-02--l50--bg {
  background: white; }

.brand-02--d60--color {
  color: #010408; }

.brand-02--d60--bg {
  background: #010408; }

.brand-02--l60--color {
  color: white; }

.brand-02--l60--bg {
  background: white; }

.brand-02--d70--color {
  color: black; }

.brand-02--d70--bg {
  background: black; }

.brand-02--l70--color {
  color: white; }

.brand-02--l70--bg {
  background: white; }

.brand-02--d80--color {
  color: black; }

.brand-02--d80--bg {
  background: black; }

.brand-02--l80--color {
  color: white; }

.brand-02--l80--bg {
  background: white; }

.brand-02--d90--color {
  color: black; }

.brand-02--d90--bg {
  background: black; }

.brand-02--l90--color {
  color: white; }

.brand-02--l90--bg {
  background: white; }

.brand-02--d100--color {
  color: black; }

.brand-02--d100--bg {
  background: black; }

.brand-02--l100--color {
  color: white; }

.brand-02--l100--bg {
  background: white; }

.brand-03--color {
  color: #41d6c3; }

.brand-03--bg {
  background: #41d6c3; }

.brand-03--d10--color {
  color: #28bca9; }

.brand-03--d10--bg {
  background: #28bca9; }

.brand-03--l10--color {
  color: #6bdfd0; }

.brand-03--l10--bg {
  background: #6bdfd0; }

.brand-03--d20--color {
  color: #1f9283; }

.brand-03--d20--bg {
  background: #1f9283; }

.brand-03--l20--color {
  color: #95e8dd; }

.brand-03--l20--bg {
  background: #95e8dd; }

.brand-03--d30--color {
  color: #16685d; }

.brand-03--d30--bg {
  background: #16685d; }

.brand-03--l30--color {
  color: #bff1eb; }

.brand-03--l30--bg {
  background: #bff1eb; }

.brand-03--d40--color {
  color: #0d3e38; }

.brand-03--d40--bg {
  background: #0d3e38; }

.brand-03--l40--color {
  color: #e9faf8; }

.brand-03--l40--bg {
  background: #e9faf8; }

.brand-03--d50--color {
  color: #041412; }

.brand-03--d50--bg {
  background: #041412; }

.brand-03--l50--color {
  color: white; }

.brand-03--l50--bg {
  background: white; }

.brand-03--d60--color {
  color: black; }

.brand-03--d60--bg {
  background: black; }

.brand-03--l60--color {
  color: white; }

.brand-03--l60--bg {
  background: white; }

.brand-03--d70--color {
  color: black; }

.brand-03--d70--bg {
  background: black; }

.brand-03--l70--color {
  color: white; }

.brand-03--l70--bg {
  background: white; }

.brand-03--d80--color {
  color: black; }

.brand-03--d80--bg {
  background: black; }

.brand-03--l80--color {
  color: white; }

.brand-03--l80--bg {
  background: white; }

.brand-03--d90--color {
  color: black; }

.brand-03--d90--bg {
  background: black; }

.brand-03--l90--color {
  color: white; }

.brand-03--l90--bg {
  background: white; }

.brand-03--d100--color {
  color: black; }

.brand-03--d100--bg {
  background: black; }

.brand-03--l100--color {
  color: white; }

.brand-03--l100--bg {
  background: white; }

.ui-01--color {
  color: #ffffff; }

.ui-01--bg {
  background: #ffffff; }

.ui-01--d10--color {
  color: #e6e6e6; }

.ui-01--d10--bg {
  background: #e6e6e6; }

.ui-01--l10--color {
  color: white; }

.ui-01--l10--bg {
  background: white; }

.ui-01--d20--color {
  color: #cccccc; }

.ui-01--d20--bg {
  background: #cccccc; }

.ui-01--l20--color {
  color: white; }

.ui-01--l20--bg {
  background: white; }

.ui-01--d30--color {
  color: #b3b3b3; }

.ui-01--d30--bg {
  background: #b3b3b3; }

.ui-01--l30--color {
  color: white; }

.ui-01--l30--bg {
  background: white; }

.ui-01--d40--color {
  color: #999999; }

.ui-01--d40--bg {
  background: #999999; }

.ui-01--l40--color {
  color: white; }

.ui-01--l40--bg {
  background: white; }

.ui-01--d50--color {
  color: gray; }

.ui-01--d50--bg {
  background: gray; }

.ui-01--l50--color {
  color: white; }

.ui-01--l50--bg {
  background: white; }

.ui-01--d60--color {
  color: #666666; }

.ui-01--d60--bg {
  background: #666666; }

.ui-01--l60--color {
  color: white; }

.ui-01--l60--bg {
  background: white; }

.ui-01--d70--color {
  color: #4d4d4d; }

.ui-01--d70--bg {
  background: #4d4d4d; }

.ui-01--l70--color {
  color: white; }

.ui-01--l70--bg {
  background: white; }

.ui-01--d80--color {
  color: #333333; }

.ui-01--d80--bg {
  background: #333333; }

.ui-01--l80--color {
  color: white; }

.ui-01--l80--bg {
  background: white; }

.ui-01--d90--color {
  color: #1a1a1a; }

.ui-01--d90--bg {
  background: #1a1a1a; }

.ui-01--l90--color {
  color: white; }

.ui-01--l90--bg {
  background: white; }

.ui-01--d100--color {
  color: black; }

.ui-01--d100--bg {
  background: black; }

.ui-01--l100--color {
  color: white; }

.ui-01--l100--bg {
  background: white; }

.ui-02--color {
  color: #f4f7fb; }

.ui-02--bg {
  background: #f4f7fb; }

.ui-02--d10--color {
  color: #cfdced; }

.ui-02--d10--bg {
  background: #cfdced; }

.ui-02--l10--color {
  color: white; }

.ui-02--l10--bg {
  background: white; }

.ui-02--d20--color {
  color: #a9c1e0; }

.ui-02--d20--bg {
  background: #a9c1e0; }

.ui-02--l20--color {
  color: white; }

.ui-02--l20--bg {
  background: white; }

.ui-02--d30--color {
  color: #84a5d2; }

.ui-02--d30--bg {
  background: #84a5d2; }

.ui-02--l30--color {
  color: white; }

.ui-02--l30--bg {
  background: white; }

.ui-02--d40--color {
  color: #5e8ac5; }

.ui-02--d40--bg {
  background: #5e8ac5; }

.ui-02--l40--color {
  color: white; }

.ui-02--l40--bg {
  background: white; }

.ui-02--d50--color {
  color: #4070b0; }

.ui-02--d50--bg {
  background: #4070b0; }

.ui-02--l50--color {
  color: white; }

.ui-02--l50--bg {
  background: white; }

.ui-02--d60--color {
  color: #32588b; }

.ui-02--d60--bg {
  background: #32588b; }

.ui-02--l60--color {
  color: white; }

.ui-02--l60--bg {
  background: white; }

.ui-02--d70--color {
  color: #254065; }

.ui-02--d70--bg {
  background: #254065; }

.ui-02--l70--color {
  color: white; }

.ui-02--l70--bg {
  background: white; }

.ui-02--d80--color {
  color: #172940; }

.ui-02--d80--bg {
  background: #172940; }

.ui-02--l80--color {
  color: white; }

.ui-02--l80--bg {
  background: white; }

.ui-02--d90--color {
  color: #0a111a; }

.ui-02--d90--bg {
  background: #0a111a; }

.ui-02--l90--color {
  color: white; }

.ui-02--l90--bg {
  background: white; }

.ui-02--d100--color {
  color: black; }

.ui-02--d100--bg {
  background: black; }

.ui-02--l100--color {
  color: white; }

.ui-02--l100--bg {
  background: white; }

.ui-03--color {
  color: #dfe3e6; }

.ui-03--bg {
  background: #dfe3e6; }

.ui-03--d10--color {
  color: #c2cad0; }

.ui-03--d10--bg {
  background: #c2cad0; }

.ui-03--l10--color {
  color: #fcfcfc; }

.ui-03--l10--bg {
  background: #fcfcfc; }

.ui-03--d20--color {
  color: #a6b1b9; }

.ui-03--d20--bg {
  background: #a6b1b9; }

.ui-03--l20--color {
  color: white; }

.ui-03--l20--bg {
  background: white; }

.ui-03--d30--color {
  color: #8998a3; }

.ui-03--d30--bg {
  background: #8998a3; }

.ui-03--l30--color {
  color: white; }

.ui-03--l30--bg {
  background: white; }

.ui-03--d40--color {
  color: #6d7f8c; }

.ui-03--d40--bg {
  background: #6d7f8c; }

.ui-03--l40--color {
  color: white; }

.ui-03--l40--bg {
  background: white; }

.ui-03--d50--color {
  color: #57656f; }

.ui-03--d50--bg {
  background: #57656f; }

.ui-03--l50--color {
  color: white; }

.ui-03--l50--bg {
  background: white; }

.ui-03--d60--color {
  color: #404b53; }

.ui-03--d60--bg {
  background: #404b53; }

.ui-03--l60--color {
  color: white; }

.ui-03--l60--bg {
  background: white; }

.ui-03--d70--color {
  color: #2a3136; }

.ui-03--d70--bg {
  background: #2a3136; }

.ui-03--l70--color {
  color: white; }

.ui-03--l70--bg {
  background: white; }

.ui-03--d80--color {
  color: #141719; }

.ui-03--d80--bg {
  background: #141719; }

.ui-03--l80--color {
  color: white; }

.ui-03--l80--bg {
  background: white; }

.ui-03--d90--color {
  color: black; }

.ui-03--d90--bg {
  background: black; }

.ui-03--l90--color {
  color: white; }

.ui-03--l90--bg {
  background: white; }

.ui-03--d100--color {
  color: black; }

.ui-03--d100--bg {
  background: black; }

.ui-03--l100--color {
  color: white; }

.ui-03--l100--bg {
  background: white; }

.ui-04--color {
  color: #8897a2; }

.ui-04--bg {
  background: #8897a2; }

.ui-04--d10--color {
  color: #6c7e8b; }

.ui-04--d10--bg {
  background: #6c7e8b; }

.ui-04--l10--color {
  color: #a5b0b8; }

.ui-04--l10--bg {
  background: #a5b0b8; }

.ui-04--d20--color {
  color: #56646e; }

.ui-04--d20--bg {
  background: #56646e; }

.ui-04--l20--color {
  color: #c1c9cf; }

.ui-04--l20--bg {
  background: #c1c9cf; }

.ui-04--d30--color {
  color: #404a51; }

.ui-04--d30--bg {
  background: #404a51; }

.ui-04--l30--color {
  color: #dee2e5; }

.ui-04--l30--bg {
  background: #dee2e5; }

.ui-04--d40--color {
  color: #293035; }

.ui-04--d40--bg {
  background: #293035; }

.ui-04--l40--color {
  color: #fbfbfb; }

.ui-04--l40--bg {
  background: #fbfbfb; }

.ui-04--d50--color {
  color: #131618; }

.ui-04--d50--bg {
  background: #131618; }

.ui-04--l50--color {
  color: white; }

.ui-04--l50--bg {
  background: white; }

.ui-04--d60--color {
  color: black; }

.ui-04--d60--bg {
  background: black; }

.ui-04--l60--color {
  color: white; }

.ui-04--l60--bg {
  background: white; }

.ui-04--d70--color {
  color: black; }

.ui-04--d70--bg {
  background: black; }

.ui-04--l70--color {
  color: white; }

.ui-04--l70--bg {
  background: white; }

.ui-04--d80--color {
  color: black; }

.ui-04--d80--bg {
  background: black; }

.ui-04--l80--color {
  color: white; }

.ui-04--l80--bg {
  background: white; }

.ui-04--d90--color {
  color: black; }

.ui-04--d90--bg {
  background: black; }

.ui-04--l90--color {
  color: white; }

.ui-04--l90--bg {
  background: white; }

.ui-04--d100--color {
  color: black; }

.ui-04--d100--bg {
  background: black; }

.ui-04--l100--color {
  color: white; }

.ui-04--l100--bg {
  background: white; }

.ui-05--color {
  color: #5a6872; }

.ui-05--bg {
  background: #5a6872; }

.ui-05--d10--color {
  color: #444e56; }

.ui-05--d10--bg {
  background: #444e56; }

.ui-05--l10--color {
  color: #71828f; }

.ui-05--l10--bg {
  background: #71828f; }

.ui-05--d20--color {
  color: #2d3439; }

.ui-05--d20--bg {
  background: #2d3439; }

.ui-05--l20--color {
  color: #8d9ba5; }

.ui-05--l20--bg {
  background: #8d9ba5; }

.ui-05--d30--color {
  color: #171a1d; }

.ui-05--d30--bg {
  background: #171a1d; }

.ui-05--l30--color {
  color: #aab4bc; }

.ui-05--l30--bg {
  background: #aab4bc; }

.ui-05--d40--color {
  color: black; }

.ui-05--d40--bg {
  background: black; }

.ui-05--l40--color {
  color: #c6cdd2; }

.ui-05--l40--bg {
  background: #c6cdd2; }

.ui-05--d50--color {
  color: black; }

.ui-05--d50--bg {
  background: black; }

.ui-05--l50--color {
  color: #e3e6e9; }

.ui-05--l50--bg {
  background: #e3e6e9; }

.ui-05--d60--color {
  color: black; }

.ui-05--d60--bg {
  background: black; }

.ui-05--l60--color {
  color: white; }

.ui-05--l60--bg {
  background: white; }

.ui-05--d70--color {
  color: black; }

.ui-05--d70--bg {
  background: black; }

.ui-05--l70--color {
  color: white; }

.ui-05--l70--bg {
  background: white; }

.ui-05--d80--color {
  color: black; }

.ui-05--d80--bg {
  background: black; }

.ui-05--l80--color {
  color: white; }

.ui-05--l80--bg {
  background: white; }

.ui-05--d90--color {
  color: black; }

.ui-05--d90--bg {
  background: black; }

.ui-05--l90--color {
  color: white; }

.ui-05--l90--bg {
  background: white; }

.ui-05--d100--color {
  color: black; }

.ui-05--d100--bg {
  background: black; }

.ui-05--l100--color {
  color: white; }

.ui-05--l100--bg {
  background: white; }

.text-01--color {
  color: #152935; }

.text-01--bg {
  background: #152935; }

.text-01--d10--color {
  color: #070d10; }

.text-01--d10--bg {
  background: #070d10; }

.text-01--l10--color {
  color: #23455a; }

.text-01--l10--bg {
  background: #23455a; }

.text-01--d20--color {
  color: black; }

.text-01--d20--bg {
  background: black; }

.text-01--l20--color {
  color: #32627e; }

.text-01--l20--bg {
  background: #32627e; }

.text-01--d30--color {
  color: black; }

.text-01--d30--bg {
  background: black; }

.text-01--l30--color {
  color: #407ea3; }

.text-01--l30--bg {
  background: #407ea3; }

.text-01--d40--color {
  color: black; }

.text-01--d40--bg {
  background: black; }

.text-01--l40--color {
  color: #5998bd; }

.text-01--l40--bg {
  background: #5998bd; }

.text-01--d50--color {
  color: black; }

.text-01--d50--bg {
  background: black; }

.text-01--l50--color {
  color: #7daecc; }

.text-01--l50--bg {
  background: #7daecc; }

.text-01--d60--color {
  color: black; }

.text-01--d60--bg {
  background: black; }

.text-01--l60--color {
  color: #a2c5da; }

.text-01--l60--bg {
  background: #a2c5da; }

.text-01--d70--color {
  color: black; }

.text-01--d70--bg {
  background: black; }

.text-01--l70--color {
  color: #c6dce9; }

.text-01--l70--bg {
  background: #c6dce9; }

.text-01--d80--color {
  color: black; }

.text-01--d80--bg {
  background: black; }

.text-01--l80--color {
  color: #ebf3f7; }

.text-01--l80--bg {
  background: #ebf3f7; }

.text-01--d90--color {
  color: black; }

.text-01--d90--bg {
  background: black; }

.text-01--l90--color {
  color: white; }

.text-01--l90--bg {
  background: white; }

.text-01--d100--color {
  color: black; }

.text-01--d100--bg {
  background: black; }

.text-01--l100--color {
  color: white; }

.text-01--l100--bg {
  background: white; }

.text-02--color {
  color: #5a6872; }

.text-02--bg {
  background: #5a6872; }

.text-02--d10--color {
  color: #444e56; }

.text-02--d10--bg {
  background: #444e56; }

.text-02--l10--color {
  color: #71828f; }

.text-02--l10--bg {
  background: #71828f; }

.text-02--d20--color {
  color: #2d3439; }

.text-02--d20--bg {
  background: #2d3439; }

.text-02--l20--color {
  color: #8d9ba5; }

.text-02--l20--bg {
  background: #8d9ba5; }

.text-02--d30--color {
  color: #171a1d; }

.text-02--d30--bg {
  background: #171a1d; }

.text-02--l30--color {
  color: #aab4bc; }

.text-02--l30--bg {
  background: #aab4bc; }

.text-02--d40--color {
  color: black; }

.text-02--d40--bg {
  background: black; }

.text-02--l40--color {
  color: #c6cdd2; }

.text-02--l40--bg {
  background: #c6cdd2; }

.text-02--d50--color {
  color: black; }

.text-02--d50--bg {
  background: black; }

.text-02--l50--color {
  color: #e3e6e9; }

.text-02--l50--bg {
  background: #e3e6e9; }

.text-02--d60--color {
  color: black; }

.text-02--d60--bg {
  background: black; }

.text-02--l60--color {
  color: white; }

.text-02--l60--bg {
  background: white; }

.text-02--d70--color {
  color: black; }

.text-02--d70--bg {
  background: black; }

.text-02--l70--color {
  color: white; }

.text-02--l70--bg {
  background: white; }

.text-02--d80--color {
  color: black; }

.text-02--d80--bg {
  background: black; }

.text-02--l80--color {
  color: white; }

.text-02--l80--bg {
  background: white; }

.text-02--d90--color {
  color: black; }

.text-02--d90--bg {
  background: black; }

.text-02--l90--color {
  color: white; }

.text-02--l90--bg {
  background: white; }

.text-02--d100--color {
  color: black; }

.text-02--d100--bg {
  background: black; }

.text-02--l100--color {
  color: white; }

.text-02--l100--bg {
  background: white; }

.text-03--color {
  color: #cdd1d4; }

.text-03--bg {
  background: #cdd1d4; }

.text-03--d10--color {
  color: #b2b8bc; }

.text-03--d10--bg {
  background: #b2b8bc; }

.text-03--l10--color {
  color: #e8eaec; }

.text-03--l10--bg {
  background: #e8eaec; }

.text-03--d20--color {
  color: #969fa5; }

.text-03--d20--bg {
  background: #969fa5; }

.text-03--l20--color {
  color: white; }

.text-03--l20--bg {
  background: white; }

.text-03--d30--color {
  color: #7b858d; }

.text-03--d30--bg {
  background: #7b858d; }

.text-03--l30--color {
  color: white; }

.text-03--l30--bg {
  background: white; }

.text-03--d40--color {
  color: #626c73; }

.text-03--d40--bg {
  background: #626c73; }

.text-03--l40--color {
  color: white; }

.text-03--l40--bg {
  background: white; }

.text-03--d50--color {
  color: #4b5257; }

.text-03--d50--bg {
  background: #4b5257; }

.text-03--l50--color {
  color: white; }

.text-03--l50--bg {
  background: white; }

.text-03--d60--color {
  color: #33383c; }

.text-03--d60--bg {
  background: #33383c; }

.text-03--l60--color {
  color: white; }

.text-03--l60--bg {
  background: white; }

.text-03--d70--color {
  color: #1c1e20; }

.text-03--d70--bg {
  background: #1c1e20; }

.text-03--l70--color {
  color: white; }

.text-03--l70--bg {
  background: white; }

.text-03--d80--color {
  color: #040505; }

.text-03--d80--bg {
  background: #040505; }

.text-03--l80--color {
  color: white; }

.text-03--l80--bg {
  background: white; }

.text-03--d90--color {
  color: black; }

.text-03--d90--bg {
  background: black; }

.text-03--l90--color {
  color: white; }

.text-03--l90--bg {
  background: white; }

.text-03--d100--color {
  color: black; }

.text-03--d100--bg {
  background: black; }

.text-03--l100--color {
  color: white; }

.text-03--l100--bg {
  background: white; }

.inverse-01--color {
  color: #ffffff; }

.inverse-01--bg {
  background: #ffffff; }

.inverse-01--d10--color {
  color: #e6e6e6; }

.inverse-01--d10--bg {
  background: #e6e6e6; }

.inverse-01--l10--color {
  color: white; }

.inverse-01--l10--bg {
  background: white; }

.inverse-01--d20--color {
  color: #cccccc; }

.inverse-01--d20--bg {
  background: #cccccc; }

.inverse-01--l20--color {
  color: white; }

.inverse-01--l20--bg {
  background: white; }

.inverse-01--d30--color {
  color: #b3b3b3; }

.inverse-01--d30--bg {
  background: #b3b3b3; }

.inverse-01--l30--color {
  color: white; }

.inverse-01--l30--bg {
  background: white; }

.inverse-01--d40--color {
  color: #999999; }

.inverse-01--d40--bg {
  background: #999999; }

.inverse-01--l40--color {
  color: white; }

.inverse-01--l40--bg {
  background: white; }

.inverse-01--d50--color {
  color: gray; }

.inverse-01--d50--bg {
  background: gray; }

.inverse-01--l50--color {
  color: white; }

.inverse-01--l50--bg {
  background: white; }

.inverse-01--d60--color {
  color: #666666; }

.inverse-01--d60--bg {
  background: #666666; }

.inverse-01--l60--color {
  color: white; }

.inverse-01--l60--bg {
  background: white; }

.inverse-01--d70--color {
  color: #4d4d4d; }

.inverse-01--d70--bg {
  background: #4d4d4d; }

.inverse-01--l70--color {
  color: white; }

.inverse-01--l70--bg {
  background: white; }

.inverse-01--d80--color {
  color: #333333; }

.inverse-01--d80--bg {
  background: #333333; }

.inverse-01--l80--color {
  color: white; }

.inverse-01--l80--bg {
  background: white; }

.inverse-01--d90--color {
  color: #1a1a1a; }

.inverse-01--d90--bg {
  background: #1a1a1a; }

.inverse-01--l90--color {
  color: white; }

.inverse-01--l90--bg {
  background: white; }

.inverse-01--d100--color {
  color: black; }

.inverse-01--d100--bg {
  background: black; }

.inverse-01--l100--color {
  color: white; }

.inverse-01--l100--bg {
  background: white; }

.inverse-02--color {
  color: #272d33; }

.inverse-02--bg {
  background: #272d33; }

.inverse-02--d10--color {
  color: #111416; }

.inverse-02--d10--bg {
  background: #111416; }

.inverse-02--l10--color {
  color: #3d4750; }

.inverse-02--l10--bg {
  background: #3d4750; }

.inverse-02--d20--color {
  color: black; }

.inverse-02--d20--bg {
  background: black; }

.inverse-02--l20--color {
  color: #53606d; }

.inverse-02--l20--bg {
  background: #53606d; }

.inverse-02--d30--color {
  color: black; }

.inverse-02--d30--bg {
  background: black; }

.inverse-02--l30--color {
  color: #697a8a; }

.inverse-02--l30--bg {
  background: #697a8a; }

.inverse-02--d40--color {
  color: black; }

.inverse-02--d40--bg {
  background: black; }

.inverse-02--l40--color {
  color: #8593a1; }

.inverse-02--l40--bg {
  background: #8593a1; }

.inverse-02--d50--color {
  color: black; }

.inverse-02--d50--bg {
  background: black; }

.inverse-02--l50--color {
  color: #a2adb8; }

.inverse-02--l50--bg {
  background: #a2adb8; }

.inverse-02--d60--color {
  color: black; }

.inverse-02--d60--bg {
  background: black; }

.inverse-02--l60--color {
  color: #bec6ce; }

.inverse-02--l60--bg {
  background: #bec6ce; }

.inverse-02--d70--color {
  color: black; }

.inverse-02--d70--bg {
  background: black; }

.inverse-02--l70--color {
  color: #dbe0e4; }

.inverse-02--l70--bg {
  background: #dbe0e4; }

.inverse-02--d80--color {
  color: black; }

.inverse-02--d80--bg {
  background: black; }

.inverse-02--l80--color {
  color: #f8f9fa; }

.inverse-02--l80--bg {
  background: #f8f9fa; }

.inverse-02--d90--color {
  color: black; }

.inverse-02--d90--bg {
  background: black; }

.inverse-02--l90--color {
  color: white; }

.inverse-02--l90--bg {
  background: white; }

.inverse-02--d100--color {
  color: black; }

.inverse-02--d100--bg {
  background: black; }

.inverse-02--l100--color {
  color: white; }

.inverse-02--l100--bg {
  background: white; }

.field-01--color {
  color: #f4f7fb; }

.field-01--bg {
  background: #f4f7fb; }

.field-01--d10--color {
  color: #cfdced; }

.field-01--d10--bg {
  background: #cfdced; }

.field-01--l10--color {
  color: white; }

.field-01--l10--bg {
  background: white; }

.field-01--d20--color {
  color: #a9c1e0; }

.field-01--d20--bg {
  background: #a9c1e0; }

.field-01--l20--color {
  color: white; }

.field-01--l20--bg {
  background: white; }

.field-01--d30--color {
  color: #84a5d2; }

.field-01--d30--bg {
  background: #84a5d2; }

.field-01--l30--color {
  color: white; }

.field-01--l30--bg {
  background: white; }

.field-01--d40--color {
  color: #5e8ac5; }

.field-01--d40--bg {
  background: #5e8ac5; }

.field-01--l40--color {
  color: white; }

.field-01--l40--bg {
  background: white; }

.field-01--d50--color {
  color: #4070b0; }

.field-01--d50--bg {
  background: #4070b0; }

.field-01--l50--color {
  color: white; }

.field-01--l50--bg {
  background: white; }

.field-01--d60--color {
  color: #32588b; }

.field-01--d60--bg {
  background: #32588b; }

.field-01--l60--color {
  color: white; }

.field-01--l60--bg {
  background: white; }

.field-01--d70--color {
  color: #254065; }

.field-01--d70--bg {
  background: #254065; }

.field-01--l70--color {
  color: white; }

.field-01--l70--bg {
  background: white; }

.field-01--d80--color {
  color: #172940; }

.field-01--d80--bg {
  background: #172940; }

.field-01--l80--color {
  color: white; }

.field-01--l80--bg {
  background: white; }

.field-01--d90--color {
  color: #0a111a; }

.field-01--d90--bg {
  background: #0a111a; }

.field-01--l90--color {
  color: white; }

.field-01--l90--bg {
  background: white; }

.field-01--d100--color {
  color: black; }

.field-01--d100--bg {
  background: black; }

.field-01--l100--color {
  color: white; }

.field-01--l100--bg {
  background: white; }

.field-02--color {
  color: #ffffff; }

.field-02--bg {
  background: #ffffff; }

.field-02--d10--color {
  color: #e6e6e6; }

.field-02--d10--bg {
  background: #e6e6e6; }

.field-02--l10--color {
  color: white; }

.field-02--l10--bg {
  background: white; }

.field-02--d20--color {
  color: #cccccc; }

.field-02--d20--bg {
  background: #cccccc; }

.field-02--l20--color {
  color: white; }

.field-02--l20--bg {
  background: white; }

.field-02--d30--color {
  color: #b3b3b3; }

.field-02--d30--bg {
  background: #b3b3b3; }

.field-02--l30--color {
  color: white; }

.field-02--l30--bg {
  background: white; }

.field-02--d40--color {
  color: #999999; }

.field-02--d40--bg {
  background: #999999; }

.field-02--l40--color {
  color: white; }

.field-02--l40--bg {
  background: white; }

.field-02--d50--color {
  color: gray; }

.field-02--d50--bg {
  background: gray; }

.field-02--l50--color {
  color: white; }

.field-02--l50--bg {
  background: white; }

.field-02--d60--color {
  color: #666666; }

.field-02--d60--bg {
  background: #666666; }

.field-02--l60--color {
  color: white; }

.field-02--l60--bg {
  background: white; }

.field-02--d70--color {
  color: #4d4d4d; }

.field-02--d70--bg {
  background: #4d4d4d; }

.field-02--l70--color {
  color: white; }

.field-02--l70--bg {
  background: white; }

.field-02--d80--color {
  color: #333333; }

.field-02--d80--bg {
  background: #333333; }

.field-02--l80--color {
  color: white; }

.field-02--l80--bg {
  background: white; }

.field-02--d90--color {
  color: #1a1a1a; }

.field-02--d90--bg {
  background: #1a1a1a; }

.field-02--l90--color {
  color: white; }

.field-02--l90--bg {
  background: white; }

.field-02--d100--color {
  color: black; }

.field-02--d100--bg {
  background: black; }

.field-02--l100--color {
  color: white; }

.field-02--l100--bg {
  background: white; }

.hover-primary--color {
  color: #30588c; }

.hover-primary--bg {
  background: #30588c; }

.hover-primary--d10--color {
  color: #234066; }

.hover-primary--d10--bg {
  background: #234066; }

.hover-primary--l10--color {
  color: #3d70b2; }

.hover-primary--l10--bg {
  background: #3d70b2; }

.hover-primary--d20--color {
  color: #162840; }

.hover-primary--d20--bg {
  background: #162840; }

.hover-primary--l20--color {
  color: #5b8ac7; }

.hover-primary--l20--bg {
  background: #5b8ac7; }

.hover-primary--d30--color {
  color: #09101a; }

.hover-primary--d30--bg {
  background: #09101a; }

.hover-primary--l30--color {
  color: #81a5d4; }

.hover-primary--l30--bg {
  background: #81a5d4; }

.hover-primary--d40--color {
  color: black; }

.hover-primary--d40--bg {
  background: black; }

.hover-primary--l40--color {
  color: #a7c0e1; }

.hover-primary--l40--bg {
  background: #a7c0e1; }

.hover-primary--d50--color {
  color: black; }

.hover-primary--d50--bg {
  background: black; }

.hover-primary--l50--color {
  color: #cddbee; }

.hover-primary--l50--bg {
  background: #cddbee; }

.hover-primary--d60--color {
  color: black; }

.hover-primary--d60--bg {
  background: black; }

.hover-primary--l60--color {
  color: #f3f6fb; }

.hover-primary--l60--bg {
  background: #f3f6fb; }

.hover-primary--d70--color {
  color: black; }

.hover-primary--d70--bg {
  background: black; }

.hover-primary--l70--color {
  color: white; }

.hover-primary--l70--bg {
  background: white; }

.hover-primary--d80--color {
  color: black; }

.hover-primary--d80--bg {
  background: black; }

.hover-primary--l80--color {
  color: white; }

.hover-primary--l80--bg {
  background: white; }

.hover-primary--d90--color {
  color: black; }

.hover-primary--d90--bg {
  background: black; }

.hover-primary--l90--color {
  color: white; }

.hover-primary--l90--bg {
  background: white; }

.hover-primary--d100--color {
  color: black; }

.hover-primary--d100--bg {
  background: black; }

.hover-primary--l100--color {
  color: white; }

.hover-primary--l100--bg {
  background: white; }

.hover-primary-text--color {
  color: #294c86; }

.hover-primary-text--bg {
  background: #294c86; }

.hover-primary-text--d10--color {
  color: #1d365f; }

.hover-primary-text--d10--bg {
  background: #1d365f; }

.hover-primary-text--l10--color {
  color: #3562ad; }

.hover-primary-text--l10--bg {
  background: #3562ad; }

.hover-primary-text--d20--color {
  color: #112038; }

.hover-primary-text--d20--bg {
  background: #112038; }

.hover-primary-text--l20--color {
  color: #4d7bc8; }

.hover-primary-text--l20--bg {
  background: #4d7bc8; }

.hover-primary-text--d30--color {
  color: #050a11; }

.hover-primary-text--d30--bg {
  background: #050a11; }

.hover-primary-text--l30--color {
  color: #7498d4; }

.hover-primary-text--l30--bg {
  background: #7498d4; }

.hover-primary-text--d40--color {
  color: black; }

.hover-primary-text--d40--bg {
  background: black; }

.hover-primary-text--l40--color {
  color: #9bb5e0; }

.hover-primary-text--l40--bg {
  background: #9bb5e0; }

.hover-primary-text--d50--color {
  color: black; }

.hover-primary-text--d50--bg {
  background: black; }

.hover-primary-text--l50--color {
  color: #c2d2ec; }

.hover-primary-text--l50--bg {
  background: #c2d2ec; }

.hover-primary-text--d60--color {
  color: black; }

.hover-primary-text--d60--bg {
  background: black; }

.hover-primary-text--l60--color {
  color: #e9eff8; }

.hover-primary-text--l60--bg {
  background: #e9eff8; }

.hover-primary-text--d70--color {
  color: black; }

.hover-primary-text--d70--bg {
  background: black; }

.hover-primary-text--l70--color {
  color: white; }

.hover-primary-text--l70--bg {
  background: white; }

.hover-primary-text--d80--color {
  color: black; }

.hover-primary-text--d80--bg {
  background: black; }

.hover-primary-text--l80--color {
  color: white; }

.hover-primary-text--l80--bg {
  background: white; }

.hover-primary-text--d90--color {
  color: black; }

.hover-primary-text--d90--bg {
  background: black; }

.hover-primary-text--l90--color {
  color: white; }

.hover-primary-text--l90--bg {
  background: white; }

.hover-primary-text--d100--color {
  color: black; }

.hover-primary-text--d100--bg {
  background: black; }

.hover-primary-text--l100--color {
  color: white; }

.hover-primary-text--l100--bg {
  background: white; }

.hover-danger--color {
  color: #bd1427; }

.hover-danger--bg {
  background: #bd1427; }

.hover-danger--d10--color {
  color: #8f0f1d; }

.hover-danger--d10--bg {
  background: #8f0f1d; }

.hover-danger--l10--color {
  color: #e71d34; }

.hover-danger--l10--bg {
  background: #e71d34; }

.hover-danger--d20--color {
  color: #610a14; }

.hover-danger--d20--bg {
  background: #610a14; }

.hover-danger--l20--color {
  color: #ec4b5d; }

.hover-danger--l20--bg {
  background: #ec4b5d; }

.hover-danger--d30--color {
  color: #33050a; }

.hover-danger--d30--bg {
  background: #33050a; }

.hover-danger--l30--color {
  color: #f17987; }

.hover-danger--l30--bg {
  background: #f17987; }

.hover-danger--d40--color {
  color: #050001; }

.hover-danger--d40--bg {
  background: #050001; }

.hover-danger--l40--color {
  color: #f6a7b0; }

.hover-danger--l40--bg {
  background: #f6a7b0; }

.hover-danger--d50--color {
  color: black; }

.hover-danger--d50--bg {
  background: black; }

.hover-danger--l50--color {
  color: #fbd5da; }

.hover-danger--l50--bg {
  background: #fbd5da; }

.hover-danger--d60--color {
  color: black; }

.hover-danger--d60--bg {
  background: black; }

.hover-danger--l60--color {
  color: white; }

.hover-danger--l60--bg {
  background: white; }

.hover-danger--d70--color {
  color: black; }

.hover-danger--d70--bg {
  background: black; }

.hover-danger--l70--color {
  color: white; }

.hover-danger--l70--bg {
  background: white; }

.hover-danger--d80--color {
  color: black; }

.hover-danger--d80--bg {
  background: black; }

.hover-danger--l80--color {
  color: white; }

.hover-danger--l80--bg {
  background: white; }

.hover-danger--d90--color {
  color: black; }

.hover-danger--d90--bg {
  background: black; }

.hover-danger--l90--color {
  color: white; }

.hover-danger--l90--bg {
  background: white; }

.hover-danger--d100--color {
  color: black; }

.hover-danger--d100--bg {
  background: black; }

.hover-danger--l100--color {
  color: white; }

.hover-danger--l100--bg {
  background: white; }

.hover-secondary--color {
  color: #3d70b2; }

.hover-secondary--bg {
  background: #3d70b2; }

.hover-secondary--d10--color {
  color: #30588c; }

.hover-secondary--d10--bg {
  background: #30588c; }

.hover-secondary--l10--color {
  color: #5b8ac7; }

.hover-secondary--l10--bg {
  background: #5b8ac7; }

.hover-secondary--d20--color {
  color: #234066; }

.hover-secondary--d20--bg {
  background: #234066; }

.hover-secondary--l20--color {
  color: #81a5d4; }

.hover-secondary--l20--bg {
  background: #81a5d4; }

.hover-secondary--d30--color {
  color: #162840; }

.hover-secondary--d30--bg {
  background: #162840; }

.hover-secondary--l30--color {
  color: #a7c0e1; }

.hover-secondary--l30--bg {
  background: #a7c0e1; }

.hover-secondary--d40--color {
  color: #09101a; }

.hover-secondary--d40--bg {
  background: #09101a; }

.hover-secondary--l40--color {
  color: #cddbee; }

.hover-secondary--l40--bg {
  background: #cddbee; }

.hover-secondary--d50--color {
  color: black; }

.hover-secondary--d50--bg {
  background: black; }

.hover-secondary--l50--color {
  color: #f3f6fb; }

.hover-secondary--l50--bg {
  background: #f3f6fb; }

.hover-secondary--d60--color {
  color: black; }

.hover-secondary--d60--bg {
  background: black; }

.hover-secondary--l60--color {
  color: white; }

.hover-secondary--l60--bg {
  background: white; }

.hover-secondary--d70--color {
  color: black; }

.hover-secondary--d70--bg {
  background: black; }

.hover-secondary--l70--color {
  color: white; }

.hover-secondary--l70--bg {
  background: white; }

.hover-secondary--d80--color {
  color: black; }

.hover-secondary--d80--bg {
  background: black; }

.hover-secondary--l80--color {
  color: white; }

.hover-secondary--l80--bg {
  background: white; }

.hover-secondary--d90--color {
  color: black; }

.hover-secondary--d90--bg {
  background: black; }

.hover-secondary--l90--color {
  color: white; }

.hover-secondary--l90--bg {
  background: white; }

.hover-secondary--d100--color {
  color: black; }

.hover-secondary--d100--bg {
  background: black; }

.hover-secondary--l100--color {
  color: white; }

.hover-secondary--l100--bg {
  background: white; }

.hover-row--color {
  color: rgba(85, 150, 230, 0.1); }

.hover-row--bg {
  background: rgba(85, 150, 230, 0.1); }

.hover-row--d10--color {
  color: rgba(41, 123, 223, 0.1); }

.hover-row--d10--bg {
  background: rgba(41, 123, 223, 0.1); }

.hover-row--l10--color {
  color: rgba(129, 177, 237, 0.1); }

.hover-row--l10--bg {
  background: rgba(129, 177, 237, 0.1); }

.hover-row--d20--color {
  color: rgba(27, 98, 186, 0.1); }

.hover-row--d20--bg {
  background: rgba(27, 98, 186, 0.1); }

.hover-row--l20--color {
  color: rgba(174, 205, 243, 0.1); }

.hover-row--l20--bg {
  background: rgba(174, 205, 243, 0.1); }

.hover-row--d30--color {
  color: rgba(21, 75, 141, 0.1); }

.hover-row--d30--bg {
  background: rgba(21, 75, 141, 0.1); }

.hover-row--l30--color {
  color: rgba(218, 232, 250, 0.1); }

.hover-row--l30--bg {
  background: rgba(218, 232, 250, 0.1); }

.hover-row--d40--color {
  color: rgba(14, 51, 97, 0.1); }

.hover-row--d40--bg {
  background: rgba(14, 51, 97, 0.1); }

.hover-row--l40--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l40--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d50--color {
  color: rgba(8, 28, 52, 0.1); }

.hover-row--d50--bg {
  background: rgba(8, 28, 52, 0.1); }

.hover-row--l50--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l50--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d60--color {
  color: rgba(1, 4, 8, 0.1); }

.hover-row--d60--bg {
  background: rgba(1, 4, 8, 0.1); }

.hover-row--l60--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l60--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d70--color {
  color: rgba(0, 0, 0, 0.1); }

.hover-row--d70--bg {
  background: rgba(0, 0, 0, 0.1); }

.hover-row--l70--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l70--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d80--color {
  color: rgba(0, 0, 0, 0.1); }

.hover-row--d80--bg {
  background: rgba(0, 0, 0, 0.1); }

.hover-row--l80--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l80--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d90--color {
  color: rgba(0, 0, 0, 0.1); }

.hover-row--d90--bg {
  background: rgba(0, 0, 0, 0.1); }

.hover-row--l90--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l90--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d100--color {
  color: rgba(0, 0, 0, 0.1); }

.hover-row--d100--bg {
  background: rgba(0, 0, 0, 0.1); }

.hover-row--l100--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l100--bg {
  background: rgba(255, 255, 255, 0.1); }

.support-01--color {
  color: #e0182d; }

.support-01--bg {
  background: #e0182d; }

.support-01--d10--color {
  color: #b21324; }

.support-01--d10--bg {
  background: #b21324; }

.support-01--l10--color {
  color: #eb4052; }

.support-01--l10--bg {
  background: #eb4052; }

.support-01--d20--color {
  color: #840e1a; }

.support-01--d20--bg {
  background: #840e1a; }

.support-01--l20--color {
  color: #f06e7c; }

.support-01--l20--bg {
  background: #f06e7c; }

.support-01--d30--color {
  color: #560911; }

.support-01--d30--bg {
  background: #560911; }

.support-01--l30--color {
  color: #f49da6; }

.support-01--l30--bg {
  background: #f49da6; }

.support-01--d40--color {
  color: #280408; }

.support-01--d40--bg {
  background: #280408; }

.support-01--l40--color {
  color: #f9cbd0; }

.support-01--l40--bg {
  background: #f9cbd0; }

.support-01--d50--color {
  color: black; }

.support-01--d50--bg {
  background: black; }

.support-01--l50--color {
  color: #fef9f9; }

.support-01--l50--bg {
  background: #fef9f9; }

.support-01--d60--color {
  color: black; }

.support-01--d60--bg {
  background: black; }

.support-01--l60--color {
  color: white; }

.support-01--l60--bg {
  background: white; }

.support-01--d70--color {
  color: black; }

.support-01--d70--bg {
  background: black; }

.support-01--l70--color {
  color: white; }

.support-01--l70--bg {
  background: white; }

.support-01--d80--color {
  color: black; }

.support-01--d80--bg {
  background: black; }

.support-01--l80--color {
  color: white; }

.support-01--l80--bg {
  background: white; }

.support-01--d90--color {
  color: black; }

.support-01--d90--bg {
  background: black; }

.support-01--l90--color {
  color: white; }

.support-01--l90--bg {
  background: white; }

.support-01--d100--color {
  color: black; }

.support-01--d100--bg {
  background: black; }

.support-01--l100--color {
  color: white; }

.support-01--l100--bg {
  background: white; }

.support-02--color {
  color: #5aa700; }

.support-02--bg {
  background: #5aa700; }

.support-02--d10--color {
  color: #3f7400; }

.support-02--d10--bg {
  background: #3f7400; }

.support-02--l10--color {
  color: #75da00; }

.support-02--l10--bg {
  background: #75da00; }

.support-02--d20--color {
  color: #234100; }

.support-02--d20--bg {
  background: #234100; }

.support-02--l20--color {
  color: #90ff0e; }

.support-02--l20--bg {
  background: #90ff0e; }

.support-02--d30--color {
  color: #080e00; }

.support-02--d30--bg {
  background: #080e00; }

.support-02--l30--color {
  color: #a7ff41; }

.support-02--l30--bg {
  background: #a7ff41; }

.support-02--d40--color {
  color: black; }

.support-02--d40--bg {
  background: black; }

.support-02--l40--color {
  color: #bfff74; }

.support-02--l40--bg {
  background: #bfff74; }

.support-02--d50--color {
  color: black; }

.support-02--d50--bg {
  background: black; }

.support-02--l50--color {
  color: #d6ffa7; }

.support-02--l50--bg {
  background: #d6ffa7; }

.support-02--d60--color {
  color: black; }

.support-02--d60--bg {
  background: black; }

.support-02--l60--color {
  color: #eeffda; }

.support-02--l60--bg {
  background: #eeffda; }

.support-02--d70--color {
  color: black; }

.support-02--d70--bg {
  background: black; }

.support-02--l70--color {
  color: white; }

.support-02--l70--bg {
  background: white; }

.support-02--d80--color {
  color: black; }

.support-02--d80--bg {
  background: black; }

.support-02--l80--color {
  color: white; }

.support-02--l80--bg {
  background: white; }

.support-02--d90--color {
  color: black; }

.support-02--d90--bg {
  background: black; }

.support-02--l90--color {
  color: white; }

.support-02--l90--bg {
  background: white; }

.support-02--d100--color {
  color: black; }

.support-02--d100--bg {
  background: black; }

.support-02--l100--color {
  color: white; }

.support-02--l100--bg {
  background: white; }

.support-03--color {
  color: #efc100; }

.support-03--bg {
  background: #efc100; }

.support-03--d10--color {
  color: #bc9800; }

.support-03--d10--bg {
  background: #bc9800; }

.support-03--l10--color {
  color: #ffd523; }

.support-03--l10--bg {
  background: #ffd523; }

.support-03--d20--color {
  color: #896f00; }

.support-03--d20--bg {
  background: #896f00; }

.support-03--l20--color {
  color: #ffde56; }

.support-03--l20--bg {
  background: #ffde56; }

.support-03--d30--color {
  color: #564500; }

.support-03--d30--bg {
  background: #564500; }

.support-03--l30--color {
  color: #ffe889; }

.support-03--l30--bg {
  background: #ffe889; }

.support-03--d40--color {
  color: #231c00; }

.support-03--d40--bg {
  background: #231c00; }

.support-03--l40--color {
  color: #fff2bc; }

.support-03--l40--bg {
  background: #fff2bc; }

.support-03--d50--color {
  color: black; }

.support-03--d50--bg {
  background: black; }

.support-03--l50--color {
  color: #fffcef; }

.support-03--l50--bg {
  background: #fffcef; }

.support-03--d60--color {
  color: black; }

.support-03--d60--bg {
  background: black; }

.support-03--l60--color {
  color: white; }

.support-03--l60--bg {
  background: white; }

.support-03--d70--color {
  color: black; }

.support-03--d70--bg {
  background: black; }

.support-03--l70--color {
  color: white; }

.support-03--l70--bg {
  background: white; }

.support-03--d80--color {
  color: black; }

.support-03--d80--bg {
  background: black; }

.support-03--l80--color {
  color: white; }

.support-03--l80--bg {
  background: white; }

.support-03--d90--color {
  color: black; }

.support-03--d90--bg {
  background: black; }

.support-03--l90--color {
  color: white; }

.support-03--l90--bg {
  background: white; }

.support-03--d100--color {
  color: black; }

.support-03--d100--bg {
  background: black; }

.support-03--l100--color {
  color: white; }

.support-03--l100--bg {
  background: white; }

.support-04--color {
  color: #5aaafa; }

.support-04--bg {
  background: #5aaafa; }

.support-04--d10--color {
  color: #2991f9; }

.support-04--d10--bg {
  background: #2991f9; }

.support-04--l10--color {
  color: #8cc4fc; }

.support-04--l10--bg {
  background: #8cc4fc; }

.support-04--d20--color {
  color: #0777e7; }

.support-04--d20--bg {
  background: #0777e7; }

.support-04--l20--color {
  color: #bdddfd; }

.support-04--l20--bg {
  background: #bdddfd; }

.support-04--d30--color {
  color: #065eb6; }

.support-04--d30--bg {
  background: #065eb6; }

.support-04--l30--color {
  color: #eff7ff; }

.support-04--l30--bg {
  background: #eff7ff; }

.support-04--d40--color {
  color: #044484; }

.support-04--d40--bg {
  background: #044484; }

.support-04--l40--color {
  color: white; }

.support-04--l40--bg {
  background: white; }

.support-04--d50--color {
  color: #032b53; }

.support-04--d50--bg {
  background: #032b53; }

.support-04--l50--color {
  color: white; }

.support-04--l50--bg {
  background: white; }

.support-04--d60--color {
  color: #011121; }

.support-04--d60--bg {
  background: #011121; }

.support-04--l60--color {
  color: white; }

.support-04--l60--bg {
  background: white; }

.support-04--d70--color {
  color: black; }

.support-04--d70--bg {
  background: black; }

.support-04--l70--color {
  color: white; }

.support-04--l70--bg {
  background: white; }

.support-04--d80--color {
  color: black; }

.support-04--d80--bg {
  background: black; }

.support-04--l80--color {
  color: white; }

.support-04--l80--bg {
  background: white; }

.support-04--d90--color {
  color: black; }

.support-04--d90--bg {
  background: black; }

.support-04--l90--color {
  color: white; }

.support-04--l90--bg {
  background: white; }

.support-04--d100--color {
  color: black; }

.support-04--d100--bg {
  background: black; }

.support-04--l100--color {
  color: white; }

.support-04--l100--bg {
  background: white; }

::selection {
  background: #41d6c3; }

.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff; }
  .Toastify__toast-container--top-left {
    top: 1em;
    left: 1em; }
  .Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--top-right {
    top: 1em;
    right: 1em; }
  .Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em; }
  .Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em; }

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0; }
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
      top: 0; }
    .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
      bottom: 0; }
    .Toastify__toast-container--rtl {
      right: 0;
      left: initial; } }

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr; }
  .Toastify__toast--rtl {
    direction: rtl; }
  .Toastify__toast--default {
    background: #fff;
    color: #aaa; }
  .Toastify__toast--info {
    background: #3498db; }
  .Toastify__toast--success {
    background: #07bc0c; }
  .Toastify__toast--warning {
    background: #f1c40f; }
  .Toastify__toast--error {
    background: #e74c3c; }
  .Toastify__toast-body {
    margin: auto 0;
    flex: 1; }

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0; } }

.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start; }
  .Toastify__close-button--default {
    color: #000;
    opacity: 0.3; }
  .Toastify__close-button:hover, .Toastify__close-button:focus {
    opacity: 1; }

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1); }
  100% {
    transform: scaleX(0); } }

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left; }
  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards; }
  .Toastify__progress-bar--controlled {
    transition: transform .2s; }
  .Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right; }
  .Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown; }

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut; }

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.Toastify__flip-enter {
  animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
  animation-name: Toastify__flipOut; }

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0); } }

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0); } }

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"] {
  border-radius: 0; }

input[type="text"]::-ms-clear {
  display: none; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

sup {
  vertical-align: super; }

sub {
  vertical-align: sub; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

html[data-layout="mobile"] body,
html[data-layout="mobile"] .modal-body {
  background-color: #f6f6f6; }
  html[data-layout="mobile"] body img,
  html[data-layout="mobile"] .modal-body img {
    width: 100%; }

body {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  color: #152935;
  background-color: #ffffff;
  line-height: 1;
  font-size: 14px; }

h1 {
  margin-bottom: 1rem; }

h2 {
  margin-bottom: 0.75rem; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/IBMPlexMono-Light.woff") format("woff"); }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/IBMPlexMono-Light-Pi.woff2") format("woff2");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/IBMPlexMono-Light-Latin3.woff2") format("woff2");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/IBMPlexMono-Light-Latin2.woff2") format("woff2");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/IBMPlexMono-Light-Latin1.woff2") format("woff2");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/IBMPlexMono-Regular.woff") format("woff"); }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/IBMPlexMono-Regular-Pi.woff2") format("woff2");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/IBMPlexMono-Regular-Latin3.woff2") format("woff2");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/IBMPlexMono-Regular-Latin2.woff2") format("woff2");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/IBMPlexMono-Regular-Latin1.woff2") format("woff2");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/IBMPlexMono-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/IBMPlexMono-SemiBold-Pi.woff2") format("woff2");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/IBMPlexMono-SemiBold-Latin3.woff2") format("woff2");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/IBMPlexMono-SemiBold-Latin2.woff2") format("woff2");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/IBMPlexMono-SemiBold-Latin1.woff2") format("woff2");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/IBMPlexSans-Light.woff") format("woff"); }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/IBMPlexSans-Light-Pi.woff2") format("woff2");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/IBMPlexSans-Light-Latin3.woff2") format("woff2");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/IBMPlexSans-Light-Latin2.woff2") format("woff2");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/IBMPlexSans-Light-Latin1.woff2") format("woff2");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/IBMPlexSans-Regular.woff") format("woff"); }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/IBMPlexSans-Regular-Pi.woff2") format("woff2");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/IBMPlexSans-Regular-Latin3.woff2") format("woff2");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/IBMPlexSans-Regular-Latin2.woff2") format("woff2");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/IBMPlexSans-Regular-Latin1.woff2") format("woff2");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/IBMPlexSans-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/IBMPlexSans-SemiBold-Pi.woff2") format("woff2");
  unicode-range: "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/IBMPlexSans-SemiBold-Latin3.woff2") format("woff2");
  unicode-range: "U+0102-0103, U+1EA0-1EF9, U+20AB"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/IBMPlexSans-SemiBold-Latin2.woff2") format("woff2");
  unicode-range: "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02"; }

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/IBMPlexSans-SemiBold-Latin1.woff2") format("woff2");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"; }

.swan--type-giga {
  font-size: 4.75rem;
  line-height: 1.25;
  font-weight: 300; }

.swan--type-mega {
  font-size: 3.375rem;
  line-height: 1.25;
  font-weight: 300; }

.swan--type-omega {
  font-size: 0.75rem;
  line-height: 1.25;
  font-weight: 600; }

.swan--type-caption {
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: 400; }

.swan--type-legal {
  font-size: 0.6875rem;
  line-height: 1.5;
  font-weight: 400; }

.swan--type-caps {
  text-transform: uppercase; }

strong,
.swan--type-strong {
  font-weight: 700; }

p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400; }

em {
  font-style: italic; }

a {
  color: #3d70b2; }

h1,
.swan--type-alpha {
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: 300; }

h2,
.swan--type-beta {
  font-size: 1.75rem;
  line-height: 1.25;
  font-weight: 300; }

h3,
.swan--type-gamma {
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: 300; }

h4,
.swan--type-delta {
  font-size: 1.125rem;
  line-height: 1.25;
  font-weight: 600; }

h5,
.swan--type-epsilon {
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 600; }

h6,
.swan--type-zeta {
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 600; }

.swan--assistive-text,
.swan--visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.swan--body {
  font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
  color: #152935;
  background-color: #f4f7fb;
  line-height: 1; }

.initials-all {
  align-content: stretch;
  align-items: stretch;
  align-self: auto;
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 0s;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: none;
  animation-play-state: running;
  animation-timing-function: ease;
  azimuth: center;
  backface-visibility: visible;
  background-attachment: scroll;
  background-blend-mode: normal;
  background-clip: border-box;
  background-color: transparent;
  background-image: none;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto auto;
  block-size: auto;
  border-block-end-color: currentcolor;
  border-block-end-style: none;
  border-block-end-width: medium;
  border-block-start-color: currentcolor;
  border-block-start-style: none;
  border-block-start-width: medium;
  border-bottom-color: currentcolor;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-collapse: separate;
  border-image-outset: 0s;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-inline-end-color: currentcolor;
  border-inline-end-style: none;
  border-inline-end-width: medium;
  border-inline-start-color: currentcolor;
  border-inline-start-style: none;
  border-inline-start-width: medium;
  border-left-color: currentcolor;
  border-left-style: none;
  border-left-width: medium;
  border-right-color: currentcolor;
  border-right-style: none;
  border-right-width: medium;
  border-spacing: 0;
  border-top-color: currentcolor;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: none;
  border-top-width: medium;
  bottom: auto;
  box-decoration-break: slice;
  box-shadow: none;
  box-sizing: content-box;
  break-after: auto;
  break-before: auto;
  break-inside: auto;
  caption-side: top;
  caret-color: auto;
  clear: none;
  clip: auto;
  clip-path: none;
  color: initial;
  column-count: auto;
  column-fill: balance;
  column-gap: normal;
  column-rule-color: currentcolor;
  column-rule-style: none;
  column-rule-width: medium;
  column-span: none;
  column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  display: inline;
  empty-cells: show;
  filter: none;
  flex-basis: auto;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 1;
  flex-wrap: nowrap;
  float: none;
  font-family: initial;
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-size: medium;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-position: normal;
  font-weight: normal;
  grid-auto-columns: auto;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-column-end: auto;
  grid-column-gap: 0;
  grid-column-start: auto;
  grid-row-end: auto;
  grid-row-gap: 0;
  grid-row-start: auto;
  grid-template-areas: none;
  grid-template-columns: none;
  grid-template-rows: none;
  height: auto;
  hyphens: manual;
  image-orientation: 0deg;
  image-rendering: auto;
  image-resolution: 1dppx;
  ime-mode: auto;
  inline-size: auto;
  isolation: auto;
  justify-content: flex-start;
  left: auto;
  letter-spacing: normal;
  line-break: auto;
  line-height: normal;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: disc;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-bottom: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  mask-clip: border-box;
  mask-composite: add;
  mask-image: none;
  mask-mode: match-source;
  mask-origin: border-box;
  mask-position: 0% 0%;
  mask-repeat: repeat;
  mask-size: auto;
  mask-type: luminance;
  max-height: none;
  max-width: none;
  min-block-size: 0;
  min-height: 0;
  min-inline-size: 0;
  min-width: 0;
  mix-blend-mode: normal;
  object-fit: fill;
  object-position: 50% 50%;
  offset-block-end: auto;
  offset-block-start: auto;
  offset-inline-end: auto;
  offset-inline-start: auto;
  opacity: 1;
  order: 0;
  orphans: 2;
  outline-color: initial;
  outline-offset: 0;
  outline-style: none;
  outline-width: medium;
  overflow: visible;
  overflow-wrap: normal;
  overflow-x: visible;
  overflow-y: visible;
  padding-block-end: 0;
  padding-block-start: 0;
  padding-bottom: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  perspective: none;
  perspective-origin: 50% 50%;
  pointer-events: auto;
  position: static;
  quotes: initial;
  resize: none;
  right: auto;
  ruby-align: space-around;
  ruby-merge: separate;
  ruby-position: over;
  scroll-behavior: auto;
  scroll-snap-coordinate: none;
  scroll-snap-destination: 0px 0px;
  scroll-snap-points-x: none;
  scroll-snap-points-y: none;
  scroll-snap-type: none;
  shape-image-threshold: 0;
  shape-margin: 0;
  shape-outside: none;
  tab-size: 8;
  table-layout: auto;
  text-align: initial;
  text-align-last: auto;
  text-combine-upright: none;
  text-decoration-color: currentcolor;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-emphasis-color: currentcolor;
  text-emphasis-position: over right;
  text-emphasis-style: none;
  text-indent: 0;
  text-justify: auto;
  text-orientation: mixed;
  text-overflow: clip;
  text-rendering: auto;
  text-shadow: none;
  text-transform: none;
  text-underline-position: auto;
  top: auto;
  touch-action: auto;
  transform: none;
  transform-box: border-box;
  transform-origin: 50% 50% 0;
  transform-style: flat;
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: all;
  transition-timing-function: ease;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 2;
  width: auto;
  will-change: auto;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  writing-mode: horizontal-tb;
  z-index: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none; }

.borderless {
  border: none !important;
  border-radius: 0 !important; }

.clear-bg {
  background: transparent !important; }

.first-child-fullwidth > *:first-child {
  width: 100% !important; }

.fa-spin {
  animation: fa-spin 0.45s infinite linear; }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

html:not([data-layout="mobile"]) ::-webkit-scrollbar {
  width: 5px; }

html:not([data-layout="mobile"]) ::-webkit-scrollbar-track {
  background: #5596e6; }

html:not([data-layout="mobile"]) ::-webkit-scrollbar-thumb {
  background: #3d70b2; }

html:not([data-layout="mobile"]) ::-webkit-scrollbar-thumb:hover {
  background: #30588c; }

* {
  scrollbar-width: thin;
  scrollbar-color: #3d70b2; }

.form-group,
.form-group-alike,
.swan-form-group-alike,
.swan-form-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 0.25rem;
  margin-bottom: 0.75rem; }
  .form-group select,
  .form-group label,
  .form-group .form-control,
  .form-group-alike select,
  .form-group-alike label,
  .form-group-alike .form-control,
  .swan-form-group-alike select,
  .swan-form-group-alike label,
  .swan-form-group-alike .form-control,
  .swan-form-group select,
  .swan-form-group label,
  .swan-form-group .form-control {
    border: none;
    background-clip: border-box;
    border-radius: 0;
    border-style: none; }
  .form-group .form-check,
  .form-group-alike .form-check,
  .swan-form-group-alike .form-check,
  .swan-form-group .form-check {
    flex: 1 0 70%;
    border-bottom: 1px solid transparent;
    box-shadow: 0 1px 0 0 #8897a2;
    padding-bottom: 0.4rem; }
    .form-group .form-check.form-radio,
    .form-group-alike .form-check.form-radio,
    .swan-form-group-alike .form-check.form-radio,
    .swan-form-group .form-check.form-radio {
      border-bottom: none;
      box-shadow: none; }
  .form-group.hide-it,
  .form-group-alike.hide-it,
  .swan-form-group-alike.hide-it,
  .swan-form-group.hide-it {
    display: none; }
  .form-group label,
  .form-group-alike label,
  .swan-form-group-alike label,
  .swan-form-group label {
    order: -1;
    flex: 1 0 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid transparent;
    box-shadow: 0 1px 0 0 rgba(136, 151, 162, 0.3);
    margin-bottom: 0;
    padding-left: 0.25rem;
    font-size: 0.815rem;
    font-weight: 600; }
  .form-group .form-control,
  .form-group-alike .form-control,
  .swan-form-group-alike .form-control,
  .swan-form-group .form-control {
    order: 0;
    flex: 1 0 70%;
    font-size: 0.815rem;
    color: #152935;
    background-color: rgba(244, 247, 251, 0.6);
    border-bottom: 1px solid transparent;
    box-shadow: 0 1px 0 0 rgba(136, 151, 162, 0.3); }
    .form-group .form-control.no-label,
    .form-group-alike .form-control.no-label,
    .swan-form-group-alike .form-control.no-label,
    .swan-form-group .form-control.no-label {
      flex: 1 0 100%; }
    .form-group .form-control.form-control-checkbox,
    .form-group-alike .form-control.form-control-checkbox,
    .swan-form-group-alike .form-control.form-control-checkbox,
    .swan-form-group .form-control.form-control-checkbox {
      display: flex;
      flex-direction: column; }
      .form-group .form-control.form-control-checkbox .form-check,
      .form-group-alike .form-control.form-control-checkbox .form-check,
      .swan-form-group-alike .form-control.form-control-checkbox .form-check,
      .swan-form-group .form-control.form-control-checkbox .form-check {
        flex: 1;
        border-bottom: none;
        box-shadow: none; }
    .form-group .form-control.form-control-readonly,
    .form-group-alike .form-control.form-control-readonly,
    .swan-form-group-alike .form-control.form-control-readonly,
    .swan-form-group .form-control.form-control-readonly {
      background-color: transparent;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .form-group .form-control.form-control-readonly:not(.wysiwyg-readonly),
      .form-group-alike .form-control.form-control-readonly:not(.wysiwyg-readonly),
      .swan-form-group-alike .form-control.form-control-readonly:not(.wysiwyg-readonly),
      .swan-form-group .form-control.form-control-readonly:not(.wysiwyg-readonly) {
        line-height: 2rem !important; }
      .form-group .form-control.form-control-readonly.wysiwyg-readonly,
      .form-group-alike .form-control.form-control-readonly.wysiwyg-readonly,
      .swan-form-group-alike .form-control.form-control-readonly.wysiwyg-readonly,
      .swan-form-group .form-control.form-control-readonly.wysiwyg-readonly {
        min-height: 6rem;
        overflow: auto;
        display: -webkit-box !important;
        -webkit-line-clamp: 4 !important;
        -webkit-box-orient: vertical !important; }
      .form-group .form-control.form-control-readonly .checkbox-label-readonly,
      .form-group-alike .form-control.form-control-readonly .checkbox-label-readonly,
      .swan-form-group-alike .form-control.form-control-readonly .checkbox-label-readonly,
      .swan-form-group .form-control.form-control-readonly .checkbox-label-readonly {
        margin-bottom: 0;
        padding-left: 0.25rem;
        font-size: 0.815rem;
        font-weight: 600; }
    .form-group .form-control:not(textarea):not(.quill):not(.wysiwyg),
    .form-group-alike .form-control:not(textarea):not(.quill):not(.wysiwyg),
    .swan-form-group-alike .form-control:not(textarea):not(.quill):not(.wysiwyg),
    .swan-form-group .form-control:not(textarea):not(.quill):not(.wysiwyg) {
      padding: 0 0.75rem;
      height: 2rem; }
    .form-group .form-control.control-inside,
    .form-group-alike .form-control.control-inside,
    .swan-form-group-alike .form-control.control-inside,
    .swan-form-group .form-control.control-inside {
      padding-right: 0 !important;
      padding-left: 0 !important; }
      .form-group .form-control.control-inside .input-group-prepend ~ input[type="number"],
      .form-group-alike .form-control.control-inside .input-group-prepend ~ input[type="number"],
      .swan-form-group-alike .form-control.control-inside .input-group-prepend ~ input[type="number"],
      .swan-form-group .form-control.control-inside .input-group-prepend ~ input[type="number"] {
        flex: 1; }
    .form-group .form-control.text-only,
    .form-group-alike .form-control.text-only,
    .swan-form-group-alike .form-control.text-only,
    .swan-form-group .form-control.text-only {
      line-height: 2rem !important; }
    .form-group .form-control::placeholder,
    .form-group-alike .form-control::placeholder,
    .swan-form-group-alike .form-control::placeholder,
    .swan-form-group .form-control::placeholder {
      color: #cdd1d4; }
    .form-group .form-control:focus,
    .form-group-alike .form-control:focus,
    .swan-form-group-alike .form-control:focus,
    .swan-form-group .form-control:focus {
      outline: none;
      box-shadow: 0 2px 0 0 #3d70b2;
      transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1); }
      .form-group .form-control:focus ~ label,
      .form-group-alike .form-control:focus ~ label,
      .swan-form-group-alike .form-control:focus ~ label,
      .swan-form-group .form-control:focus ~ label {
        color: #3d70b2;
        font-weight: 600;
        transition: font-weight 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
    .form-group .form-control[data-invalid],
    .form-group-alike .form-control[data-invalid],
    .swan-form-group-alike .form-control[data-invalid],
    .swan-form-group .form-control[data-invalid] {
      box-shadow: 0 1px 0 0 #e0182d; }
      .form-group .form-control[data-invalid] ~ .form-text,
      .form-group-alike .form-control[data-invalid] ~ .form-text,
      .swan-form-group-alike .form-control[data-invalid] ~ .form-text,
      .swan-form-group .form-control[data-invalid] ~ .form-text {
        color: #e0182d; }
      .form-group .form-control[data-invalid]:focus,
      .form-group-alike .form-control[data-invalid]:focus,
      .swan-form-group-alike .form-control[data-invalid]:focus,
      .swan-form-group .form-control[data-invalid]:focus {
        box-shadow: 0 2px 0 0 #e0182d; }
        .form-group .form-control[data-invalid]:focus ~ label,
        .form-group-alike .form-control[data-invalid]:focus ~ label,
        .swan-form-group-alike .form-control[data-invalid]:focus ~ label,
        .swan-form-group .form-control[data-invalid]:focus ~ label {
          color: #e0182d; }
    .form-group .form-control[data-required] ~ label,
    .form-group-alike .form-control[data-required] ~ label,
    .swan-form-group-alike .form-control[data-required] ~ label,
    .swan-form-group .form-control[data-required] ~ label {
      position: relative; }
      .form-group .form-control[data-required] ~ label.label-readonly::after,
      .form-group-alike .form-control[data-required] ~ label.label-readonly::after,
      .swan-form-group-alike .form-control[data-required] ~ label.label-readonly::after,
      .swan-form-group .form-control[data-required] ~ label.label-readonly::after {
        background: #71828f; }
      .form-group .form-control[data-required] ~ label::after,
      .form-group-alike .form-control[data-required] ~ label::after,
      .swan-form-group-alike .form-control[data-required] ~ label::after,
      .swan-form-group .form-control[data-required] ~ label::after {
        content: "";
        width: 2rem;
        height: 2px;
        display: inline-block;
        position: absolute;
        bottom: -3px;
        left: 0;
        background: #eb4052; }
  .form-group .form-control.swan-datepicker-input,
  .form-group-alike .form-control.swan-datepicker-input,
  .swan-form-group-alike .form-control.swan-datepicker-input,
  .swan-form-group .form-control.swan-datepicker-input {
    box-shadow: none !important; }
  .form-group .form-text,
  .form-group-alike .form-text,
  .swan-form-group-alike .form-text,
  .swan-form-group .form-text {
    order: 1;
    flex: 0 1 100%;
    text-align: right;
    font-size: 0.75rem;
    color: #5a6872; }

@media (max-width: 767.98px) {
  .form-group,
  .form-group-alike,
  .swan-form-group,
  .swan-form-group-alike {
    margin-top: 0.5rem;
    margin-bottom: 0.0625rem;
    flex-direction: row; }
    .form-group:not(.form-group-of--mapField):not(.form-group-of--textarea):not(.form-group-of--longText),
    .form-group-alike:not(.form-group-of--mapField):not(.form-group-of--textarea):not(.form-group-of--longText),
    .swan-form-group:not(.form-group-of--mapField):not(.form-group-of--textarea):not(.form-group-of--longText),
    .swan-form-group-alike:not(.form-group-of--mapField):not(.form-group-of--textarea):not(.form-group-of--longText) {
      margin-top: 0.5rem !important;
      flex-wrap: nowrap; }
      .form-group:not(.form-group-of--mapField):not(.form-group-of--textarea):not(.form-group-of--longText) label,
      .form-group-alike:not(.form-group-of--mapField):not(.form-group-of--textarea):not(.form-group-of--longText) label,
      .swan-form-group:not(.form-group-of--mapField):not(.form-group-of--textarea):not(.form-group-of--longText) label,
      .swan-form-group-alike:not(.form-group-of--mapField):not(.form-group-of--textarea):not(.form-group-of--longText) label {
        justify-content: flex-end;
        word-break: break-word; }
    .form-group.form-group-of--textarea label, .form-group.form-group-of--maField label, .form-group.form-group-of--longText label,
    .form-group-alike.form-group-of--textarea label,
    .form-group-alike.form-group-of--maField label,
    .form-group-alike.form-group-of--longText label,
    .swan-form-group.form-group-of--textarea label,
    .swan-form-group.form-group-of--maField label,
    .swan-form-group.form-group-of--longText label,
    .swan-form-group-alike.form-group-of--textarea label,
    .swan-form-group-alike.form-group-of--maField label,
    .swan-form-group-alike.form-group-of--longText label {
      text-align: left; }
    .form-group label,
    .form-group .label-alike,
    .form-group-alike label,
    .form-group-alike .label-alike,
    .swan-form-group label,
    .swan-form-group .label-alike,
    .swan-form-group-alike label,
    .swan-form-group-alike .label-alike {
      box-shadow: none;
      order: -1;
      margin-bottom: 0.0625rem;
      font-size: 0.65rem;
      display: flex;
      margin-right: 0.5rem;
      font-weight: 400; }
    .form-group .form-text,
    .form-group-alike .form-text,
    .swan-form-group .form-text,
    .swan-form-group-alike .form-text {
      order: 0;
      text-align: left;
      margin-bottom: 0.25rem; }
    .form-group .form-control,
    .form-group .PhoneInput,
    .form-group-alike .form-control,
    .form-group-alike .PhoneInput,
    .swan-form-group .form-control,
    .swan-form-group .PhoneInput,
    .swan-form-group-alike .form-control,
    .swan-form-group-alike .PhoneInput {
      order: 1;
      margin-bottom: 0.0625rem;
      box-shadow: none;
      background-color: white; }
      .form-group .form-control[data-required] ~ label,
      .form-group .PhoneInput[data-required] ~ label,
      .form-group-alike .form-control[data-required] ~ label,
      .form-group-alike .PhoneInput[data-required] ~ label,
      .swan-form-group .form-control[data-required] ~ label,
      .swan-form-group .PhoneInput[data-required] ~ label,
      .swan-form-group-alike .form-control[data-required] ~ label,
      .swan-form-group-alike .PhoneInput[data-required] ~ label {
        position: relative; }
        .form-group .form-control[data-required] ~ label::after,
        .form-group .PhoneInput[data-required] ~ label::after,
        .form-group-alike .form-control[data-required] ~ label::after,
        .form-group-alike .PhoneInput[data-required] ~ label::after,
        .swan-form-group .form-control[data-required] ~ label::after,
        .swan-form-group .PhoneInput[data-required] ~ label::after,
        .swan-form-group-alike .form-control[data-required] ~ label::after,
        .swan-form-group-alike .PhoneInput[data-required] ~ label::after {
          content: "*";
          position: relative;
          color: #e0182d;
          width: 0;
          height: 0;
          bottom: 0; }
      .form-group .form-control.form-control-readonly,
      .form-group .PhoneInput.form-control-readonly,
      .form-group-alike .form-control.form-control-readonly,
      .form-group-alike .PhoneInput.form-control-readonly,
      .swan-form-group .form-control.form-control-readonly,
      .swan-form-group .PhoneInput.form-control-readonly,
      .swan-form-group-alike .form-control.form-control-readonly,
      .swan-form-group-alike .PhoneInput.form-control-readonly {
        background-color: white; }
        .form-group .form-control.form-control-readonly ~ label,
        .form-group .PhoneInput.form-control-readonly ~ label,
        .form-group-alike .form-control.form-control-readonly ~ label,
        .form-group-alike .PhoneInput.form-control-readonly ~ label,
        .swan-form-group .form-control.form-control-readonly ~ label,
        .swan-form-group .PhoneInput.form-control-readonly ~ label,
        .swan-form-group-alike .form-control.form-control-readonly ~ label,
        .swan-form-group-alike .PhoneInput.form-control-readonly ~ label {
          text-align: right; }
      .form-group .form-control.form-control-readonly--remoteselect,
      .form-group .PhoneInput.form-control-readonly--remoteselect,
      .form-group-alike .form-control.form-control-readonly--remoteselect,
      .form-group-alike .PhoneInput.form-control-readonly--remoteselect,
      .swan-form-group .form-control.form-control-readonly--remoteselect,
      .swan-form-group .PhoneInput.form-control-readonly--remoteselect,
      .swan-form-group-alike .form-control.form-control-readonly--remoteselect,
      .swan-form-group-alike .PhoneInput.form-control-readonly--remoteselect {
        display: flex;
        padding-right: 0 !important; }
        .form-group .form-control.form-control-readonly--remoteselect .remoteselect-button,
        .form-group .PhoneInput.form-control-readonly--remoteselect .remoteselect-button,
        .form-group-alike .form-control.form-control-readonly--remoteselect .remoteselect-button,
        .form-group-alike .PhoneInput.form-control-readonly--remoteselect .remoteselect-button,
        .swan-form-group .form-control.form-control-readonly--remoteselect .remoteselect-button,
        .swan-form-group .PhoneInput.form-control-readonly--remoteselect .remoteselect-button,
        .swan-form-group-alike .form-control.form-control-readonly--remoteselect .remoteselect-button,
        .swan-form-group-alike .PhoneInput.form-control-readonly--remoteselect .remoteselect-button {
          flex-basis: 0.75rem;
          flex-grow: 0;
          padding-right: 0;
          padding-left: 0;
          color: gray; }
        .form-group .form-control.form-control-readonly--remoteselect .copy-text-wrapper,
        .form-group .PhoneInput.form-control-readonly--remoteselect .copy-text-wrapper,
        .form-group-alike .form-control.form-control-readonly--remoteselect .copy-text-wrapper,
        .form-group-alike .PhoneInput.form-control-readonly--remoteselect .copy-text-wrapper,
        .swan-form-group .form-control.form-control-readonly--remoteselect .copy-text-wrapper,
        .swan-form-group .PhoneInput.form-control-readonly--remoteselect .copy-text-wrapper,
        .swan-form-group-alike .form-control.form-control-readonly--remoteselect .copy-text-wrapper,
        .swan-form-group-alike .PhoneInput.form-control-readonly--remoteselect .copy-text-wrapper {
          flex-basis: 90%;
          flex-grow: 1; }
      .form-group .form-control:not(textarea):not(.quill):not(.wysiwyg),
      .form-group .PhoneInput:not(textarea):not(.quill):not(.wysiwyg),
      .form-group-alike .form-control:not(textarea):not(.quill):not(.wysiwyg),
      .form-group-alike .PhoneInput:not(textarea):not(.quill):not(.wysiwyg),
      .swan-form-group .form-control:not(textarea):not(.quill):not(.wysiwyg),
      .swan-form-group .PhoneInput:not(textarea):not(.quill):not(.wysiwyg),
      .swan-form-group-alike .form-control:not(textarea):not(.quill):not(.wysiwyg),
      .swan-form-group-alike .PhoneInput:not(textarea):not(.quill):not(.wysiwyg) {
        padding: 0 0.5rem;
        height: 2rem; }
    .form-group .form-control,
    .form-group-alike .form-control,
    .swan-form-group .form-control,
    .swan-form-group-alike .form-control {
      flex-basis: 70%;
      flex-shrink: 1; }
    .form-group label,
    .form-group .label-alike,
    .form-group-alike label,
    .form-group-alike .label-alike,
    .swan-form-group label,
    .swan-form-group .label-alike,
    .swan-form-group-alike label,
    .swan-form-group-alike .label-alike {
      flex-basis: 30%;
      text-align: right; }
    .form-group .label-alike,
    .form-group-alike .label-alike,
    .swan-form-group .label-alike,
    .swan-form-group-alike .label-alike {
      flex-grow: 0;
      justify-content: flex-end;
      word-break: break-all; } }

.select2-label-selected {
  color: #3d70b2;
  font-weight: 600;
  transition: font-weight 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
  .select2-label-selected ~ .select2-control {
    outline: none;
    box-shadow: 0 2px 0 0 #3d70b2;
    transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1); }

.select2-control {
  font-size: font-size("14"); }

.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
  border: none;
  border-radius: 0;
  font-size: inherit; }

.form-control.input-group {
  padding-left: 0 !important; }
  .form-control.input-group input:focus ~ .input-group-prepend {
    box-shadow: 0 2px 0 0 #3d70b2;
    transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1); }

html[data-layout="mobile"] .form-group-of--tel {
  overflow: hidden !important; }

.form-group select[multiple],
.swan-form-group select[multiple] {
  height: auto !important;
  padding: 1rem !important; }

.form-group select ~ label,
.swan-form-group select ~ label {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

@media (max-width: 767.98px) {
  .form-group select[multiple],
  .swan-form-group select[multiple] {
    padding: 0.5rem; }
  .form-group select ~ label,
  .swan-form-group select ~ label {
    justify-content: flex-end;
    padding-top: 0; } }

.select2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .select2 .select2-label {
    flex: 1 0 30%;
    order: 1; }
  .select2 > div {
    flex-grow: 1;
    order: 2; }
    .select2 > div > div {
      background: #f4f7fb; }

.select2-control {
  flex: 1 0 70%; }
  .select2-control.select2-control-selected ~ label {
    color: #3d70b2; }
  .select2-control.select2-control-selected > div {
    box-shadow: 0 2px 0 0 #3d70b2;
    transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
    min-height: auto !important; }
  .select2-control[data-required] ~ label, .select2-control.select2-control-required ~ label {
    position: relative; }
    .select2-control[data-required] ~ label.label-readonly::after, .select2-control.select2-control-required ~ label.label-readonly::after {
      background: #71828f; }
    .select2-control[data-required] ~ label::after, .select2-control.select2-control-required ~ label::after {
      content: "";
      width: 2rem;
      height: 2px;
      display: inline-block;
      position: absolute;
      bottom: -3px;
      left: 0;
      background: #eb4052; }
  .select2-control > div {
    font-size: 0.875rem;
    border: none;
    border-radius: 0;
    background-color: rgba(244, 247, 251, 0.6);
    min-height: auto !important; }
    .select2-control > div:first-child {
      box-shadow: 0 1px 0 0 rgba(136, 151, 162, 0.3); }
    .select2-control > div > div:last-child > span {
      display: none; }
    .select2-control > div > div {
      margin: 0; }

@media (max-width: 767.98px) {
  .select2-control {
    flex-basis: 70%;
    flex-shrink: 1;
    order: 1;
    margin-bottom: 0.0625rem;
    padding: 0 0.5rem;
    height: 2rem;
    background: white; }
    .select2-control .remoteselect-selecting-button {
      font-size: 0.65rem; }
    .select2-control.select2-control-selected {
      order: 1;
      margin-bottom: 0.0625rem;
      padding: 0 0.5rem;
      height: 2rem; }
    .select2-control[data-required] ~ label::after, .select2-control.select2-control-required ~ label::after {
      left: unset;
      right: 0; }
    .select2-control > div:first-child {
      box-shadow: none;
      height: 100%;
      background: white; }
    .select2-control > div > div {
      padding: 0; } }

.form-group textarea,
.form-group .wysiwyg,
.swan-form-group textarea,
.swan-form-group .wysiwyg {
  padding: 1rem;
  resize: vertical;
  height: auto; }
  .form-group textarea ~ label,
  .form-group .wysiwyg ~ label,
  .swan-form-group textarea ~ label,
  .swan-form-group .wysiwyg ~ label {
    align-items: flex-start;
    padding-top: 1rem; }

.form-group .form-control.quill, .form-group .form-control.wysiwyg,
.swan-form-group .form-control.quill,
.swan-form-group .form-control.wysiwyg {
  height: 100%; }

@media (max-width: 767.98px) {
  .form-group textarea,
  .swan-form-group textarea {
    padding: 0.5rem; }
    .form-group textarea ~ label,
    .swan-form-group textarea ~ label {
      padding-top: 0; } }

fieldset.form-group {
  display: block;
  box-sizing: border-box;
  box-shadow: 0 1px 0 0 #8897a2;
  border-bottom: 1px solid transparent; }
  fieldset.form-group .control-label {
    display: inline-block !important;
    width: 30%;
    height: 2rem; }
    fieldset.form-group .control-label label {
      border-bottom: none;
      box-shadow: none; }
  fieldset.form-group .control-wrapper {
    display: inline-block !important;
    width: 65%;
    height: 2rem; }

.form-check {
  position: relative;
  display: inline-block;
  align-items: center;
  margin-top: 0.5rem;
  padding-left: 0px; }
  .form-check label {
    box-shadow: none;
    border-bottom: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem; }
    .form-check label .radio-appearance {
      background-color: #fff;
      border-radius: 50%;
      border: 2px solid #5a6872;
      flex-shrink: 0;
      height: 1.125rem;
      width: 1.125rem;
      margin-right: 0.5rem; }
  .form-check input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    visibility: visible;
    white-space: nowrap;
    visibility: unset; }
    .form-check input[type="checkbox"] + label {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      padding-left: 1.5rem;
      min-height: 1rem; }
      .form-check input[type="checkbox"] + label::before {
        box-sizing: border-box;
        content: "";
        position: absolute;
        left: 0;
        top: calc(50% - 9px);
        height: 1.125rem;
        width: 1.125rem;
        border: 2px solid #5a6872;
        background-color: #fff; }
      .form-check input[type="checkbox"] + label::after {
        box-sizing: border-box;
        content: "";
        width: 9px;
        height: 5px;
        background: none;
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        -webkit-transform: scale(0) rotate(-45deg);
        transform: scale(0) rotate(-45deg);
        position: absolute;
        left: 0.3125rem;
        top: 50%;
        margin-top: -0.1875rem; }
    .form-check input[type="checkbox"]:checked ~ label::before {
      background-color: #3d70b2;
      border-color: #3d70b2; }
    .form-check input[type="checkbox"]:checked ~ label::after {
      opacity: 1;
      -webkit-transform: scale(1) rotate(-45deg);
      transform: scale(1) rotate(-45deg); }
  .form-check input[type="radio"],
  .form-check input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    visibility: visible;
    white-space: nowrap;
    visibility: unset; }
    .form-check input[type="radio"]:checked + label .radio-appearance,
    .form-check input[type="checkbox"]:checked + label .radio-appearance {
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #3d70b2; }
      .form-check input[type="radio"]:checked + label .radio-appearance::before,
      .form-check input[type="checkbox"]:checked + label .radio-appearance::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: #3d70b2; }

.form-map-control-filter {
  flex: 1 0 16.5%; }

.form-map-control-two-inputs {
  flex: 1 0 30%; }

.form-map-control-input {
  flex: 1 0 60%; }

.form-map-control-input-with-button {
  flex: 1 0 70%; }

.form-map-control-filter .form-group {
  margin: 0px; }

.hide-map {
  display: none; }

.map-filters-bar {
  order: 0;
  flex: 1 0 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }

@media (max-width: 991.98px) {
  .form-map-control-filter {
    flex: 1 0 100%; }
  .map-filters-bar {
    flex-wrap: wrap; }
  .form-map-control-input {
    flex: 1 0 79%; }
  .form-map-control-input-with-button {
    flex: 1 0 60%; }
  .form-group .map-filters-bar .form-control {
    margin-bottom: 0px; } }

@media (max-width: 575.98px) {
  .form-map-control-filter {
    flex: 1 0 100%; }
  .map-filters-bar {
    flex-wrap: wrap; }
  .form-map-control-two-inputs {
    flex: 1 0 100%; }
  .form-map-control-input {
    flex: 1 0 100%; }
  .form-map-control-input-with-button {
    flex: 1 0 100%; }
  .filter-map-icon {
    justify-content: center; }
  .filter-map-icon .input-group-append {
    justify-content: center; } }

td.table-row-rendered,
tr.table-row-rendered {
  padding: 0; }

td.table-cell-rendered,
tr.table-cell-rendered {
  padding: 0; }

td .form-group,
td .form-group-alike,
td .swan-form-group-alike,
td .swan-form-group,
tr .form-group,
tr .form-group-alike,
tr .swan-form-group-alike,
tr .swan-form-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
  margin: 0; }
  td .form-group select,
  td .form-group label,
  td .form-group .form-control,
  td .form-group-alike select,
  td .form-group-alike label,
  td .form-group-alike .form-control,
  td .swan-form-group-alike select,
  td .swan-form-group-alike label,
  td .swan-form-group-alike .form-control,
  td .swan-form-group select,
  td .swan-form-group label,
  td .swan-form-group .form-control,
  tr .form-group select,
  tr .form-group label,
  tr .form-group .form-control,
  tr .form-group-alike select,
  tr .form-group-alike label,
  tr .form-group-alike .form-control,
  tr .swan-form-group-alike select,
  tr .swan-form-group-alike label,
  tr .swan-form-group-alike .form-control,
  tr .swan-form-group select,
  tr .swan-form-group label,
  tr .swan-form-group .form-control {
    border: none;
    background-clip: border-box;
    border-radius: 0;
    border-style: none; }
  td .form-group .form-check,
  td .form-group-alike .form-check,
  td .swan-form-group-alike .form-check,
  td .swan-form-group .form-check,
  tr .form-group .form-check,
  tr .form-group-alike .form-check,
  tr .swan-form-group-alike .form-check,
  tr .swan-form-group .form-check {
    flex: 1 0 70%;
    border-bottom: 1px solid transparent;
    box-shadow: 0 1px 0 0 #8897a2;
    padding-bottom: 0.4rem; }
    td .form-group .form-check.form-radio,
    td .form-group-alike .form-check.form-radio,
    td .swan-form-group-alike .form-check.form-radio,
    td .swan-form-group .form-check.form-radio,
    tr .form-group .form-check.form-radio,
    tr .form-group-alike .form-check.form-radio,
    tr .swan-form-group-alike .form-check.form-radio,
    tr .swan-form-group .form-check.form-radio {
      border-bottom: none;
      box-shadow: none; }
  td .form-group.hide-it,
  td .form-group-alike.hide-it,
  td .swan-form-group-alike.hide-it,
  td .swan-form-group.hide-it,
  tr .form-group.hide-it,
  tr .form-group-alike.hide-it,
  tr .swan-form-group-alike.hide-it,
  tr .swan-form-group.hide-it {
    display: none; }
  td .form-group .form-control,
  td .form-group-alike .form-control,
  td .swan-form-group-alike .form-control,
  td .swan-form-group .form-control,
  tr .form-group .form-control,
  tr .form-group-alike .form-control,
  tr .swan-form-group-alike .form-control,
  tr .swan-form-group .form-control {
    border-bottom: 1px solid transparent;
    box-shadow: 0 1px 0 0 transparent;
    height: 2.4rem;
    vertical-align: middle;
    font-size: 1rem;
    line-height: 1.5; }
    td .form-group .form-control:not(textarea):not(.quill):not(.wysiwyg),
    td .form-group-alike .form-control:not(textarea):not(.quill):not(.wysiwyg),
    td .swan-form-group-alike .form-control:not(textarea):not(.quill):not(.wysiwyg),
    td .swan-form-group .form-control:not(textarea):not(.quill):not(.wysiwyg),
    tr .form-group .form-control:not(textarea):not(.quill):not(.wysiwyg),
    tr .form-group-alike .form-control:not(textarea):not(.quill):not(.wysiwyg),
    tr .swan-form-group-alike .form-control:not(textarea):not(.quill):not(.wysiwyg),
    tr .swan-form-group .form-control:not(textarea):not(.quill):not(.wysiwyg) {
      height: 2.4rem; }
    td .form-group .form-control:focus,
    td .form-group-alike .form-control:focus,
    td .swan-form-group-alike .form-control:focus,
    td .swan-form-group .form-control:focus,
    tr .form-group .form-control:focus,
    tr .form-group-alike .form-control:focus,
    tr .swan-form-group-alike .form-control:focus,
    tr .swan-form-group .form-control:focus {
      outline: none;
      box-shadow: 0 2px 0 0 transparent;
      transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1); }
  td .form-group .select2-control > div,
  td .form-group-alike .select2-control > div,
  td .swan-form-group-alike .select2-control > div,
  td .swan-form-group .select2-control > div,
  tr .form-group .select2-control > div,
  tr .form-group-alike .select2-control > div,
  tr .swan-form-group-alike .select2-control > div,
  tr .swan-form-group .select2-control > div {
    height: 2.4rem;
    background: transparent;
    box-shadow: none; }

.btn {
  border-radius: 0; }
  .btn.btn:focus {
    border: 2px solid #f4f4f4;
    outline: 2px solid #6c757d; }
  .btn.tag-button {
    padding: 0.1rem 0.25rem;
    font-size: 0.8rem;
    border-radius: 0.1rem; }
  .btn.outline.outline-clear {
    border: none;
    outline: none; }
  .btn.outline:hover, .btn.outline:focus, .btn.outline:active {
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
    color: #3d70b2 !important;
    box-shadow: none !important; }
  .btn .control-label-container {
    display: inline-block; }
  .btn.loading .control-label-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .btn.loading .control-label-container .control-label {
      padding-left: 0.4rem; }
  .btn.loading::after {
    display: none; }

.modal-content {
  border-radius: 0;
  border-top: 4px solid #3d70b2; }

.modal-footer {
  background: #f4f7fb; }

@media only screen and (min-width: 1200px) {
  .modal-xl {
    max-width: 90%; } }

@media only screen and (min-width: 1824px) {
  .modal-xl {
    max-width: 1700px; } }

.panel .panel-switcher {
  width: 1rem;
  padding: 0;
  border: none;
  box-shadow: none;
  border-radius: 0;
  appearance: caret;
  align-self: stretch; }
  .panel .panel-switcher .switcher-button {
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3d70b2;
    color: #f4f7fb;
    cursor: pointer; }

.panel.is-open {
  transition: all 0.2s; }
  .panel.is-open .panel-content {
    border: 1px solid #3d70b2; }

@media (max-width: 767.98px) {
  .cwt--wrapper.cwt--wrapper-with-closed {
    padding: 0 1rem !important;
    margin-left: -1rem !important;
    margin-right: -1rem !important;
    position: relative;
    z-index: 100;
    background-color: #e7e7e7;
    height: 2rem;
    margin-bottom: 0.0625rem !important; }
    .cwt--wrapper.cwt--wrapper-with-closed .cwt--title-filler {
      position: absolute;
      background-color: #e7e7e7;
      left: -1rem;
      right: -1rem;
      width: calc(100% + 2rem);
      z-index: -100;
      height: 2rem; }
    .cwt--wrapper.cwt--wrapper-with-closed .cwt--title {
      padding-left: 1rem;
      padding-right: 0rem; }
  .cwt--wrapper.cwt--wrapper-with-open {
    padding: 0 1rem !important;
    position: relative;
    z-index: 100; }
    .cwt--wrapper.cwt--wrapper-with-open .cwt--title-filler {
      position: absolute;
      background-color: #e7e7e7;
      left: -1rem;
      right: -1rem;
      width: calc(100% + 2rem);
      z-index: -100;
      height: 2rem; }
    .cwt--wrapper.cwt--wrapper-with-open .cwt--title {
      padding-right: 0px;
      margin-right: -1rem; }
  .cwt--wrapper .cwt--content-with-open {
    padding-top: 1rem; }
  .cwt--wrapper .cwt--title {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    border-bottom: none;
    line-height: 2rem; }
  .cwt--toggle-button button {
    border: none; }
    .cwt--toggle-button button:focus, .cwt--toggle-button button:hover, .cwt--toggle-button button:active {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      background: none !important; } }

.initials-all {
  align-content: stretch;
  align-items: stretch;
  align-self: auto;
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 0s;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: none;
  animation-play-state: running;
  animation-timing-function: ease;
  azimuth: center;
  backface-visibility: visible;
  background-attachment: scroll;
  background-blend-mode: normal;
  background-clip: border-box;
  background-color: transparent;
  background-image: none;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto auto;
  block-size: auto;
  border-block-end-color: currentcolor;
  border-block-end-style: none;
  border-block-end-width: medium;
  border-block-start-color: currentcolor;
  border-block-start-style: none;
  border-block-start-width: medium;
  border-bottom-color: currentcolor;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-collapse: separate;
  border-image-outset: 0s;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-inline-end-color: currentcolor;
  border-inline-end-style: none;
  border-inline-end-width: medium;
  border-inline-start-color: currentcolor;
  border-inline-start-style: none;
  border-inline-start-width: medium;
  border-left-color: currentcolor;
  border-left-style: none;
  border-left-width: medium;
  border-right-color: currentcolor;
  border-right-style: none;
  border-right-width: medium;
  border-spacing: 0;
  border-top-color: currentcolor;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: none;
  border-top-width: medium;
  bottom: auto;
  box-decoration-break: slice;
  box-shadow: none;
  box-sizing: content-box;
  break-after: auto;
  break-before: auto;
  break-inside: auto;
  caption-side: top;
  caret-color: auto;
  clear: none;
  clip: auto;
  clip-path: none;
  color: initial;
  column-count: auto;
  column-fill: balance;
  column-gap: normal;
  column-rule-color: currentcolor;
  column-rule-style: none;
  column-rule-width: medium;
  column-span: none;
  column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  display: inline;
  empty-cells: show;
  filter: none;
  flex-basis: auto;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 1;
  flex-wrap: nowrap;
  float: none;
  font-family: initial;
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-size: medium;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-position: normal;
  font-weight: normal;
  grid-auto-columns: auto;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-column-end: auto;
  grid-column-gap: 0;
  grid-column-start: auto;
  grid-row-end: auto;
  grid-row-gap: 0;
  grid-row-start: auto;
  grid-template-areas: none;
  grid-template-columns: none;
  grid-template-rows: none;
  height: auto;
  hyphens: manual;
  image-orientation: 0deg;
  image-rendering: auto;
  image-resolution: 1dppx;
  ime-mode: auto;
  inline-size: auto;
  isolation: auto;
  justify-content: flex-start;
  left: auto;
  letter-spacing: normal;
  line-break: auto;
  line-height: normal;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: disc;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-bottom: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  mask-clip: border-box;
  mask-composite: add;
  mask-image: none;
  mask-mode: match-source;
  mask-origin: border-box;
  mask-position: 0% 0%;
  mask-repeat: repeat;
  mask-size: auto;
  mask-type: luminance;
  max-height: none;
  max-width: none;
  min-block-size: 0;
  min-height: 0;
  min-inline-size: 0;
  min-width: 0;
  mix-blend-mode: normal;
  object-fit: fill;
  object-position: 50% 50%;
  offset-block-end: auto;
  offset-block-start: auto;
  offset-inline-end: auto;
  offset-inline-start: auto;
  opacity: 1;
  order: 0;
  orphans: 2;
  outline-color: initial;
  outline-offset: 0;
  outline-style: none;
  outline-width: medium;
  overflow: visible;
  overflow-wrap: normal;
  overflow-x: visible;
  overflow-y: visible;
  padding-block-end: 0;
  padding-block-start: 0;
  padding-bottom: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  perspective: none;
  perspective-origin: 50% 50%;
  pointer-events: auto;
  position: static;
  quotes: initial;
  resize: none;
  right: auto;
  ruby-align: space-around;
  ruby-merge: separate;
  ruby-position: over;
  scroll-behavior: auto;
  scroll-snap-coordinate: none;
  scroll-snap-destination: 0px 0px;
  scroll-snap-points-x: none;
  scroll-snap-points-y: none;
  scroll-snap-type: none;
  shape-image-threshold: 0;
  shape-margin: 0;
  shape-outside: none;
  tab-size: 8;
  table-layout: auto;
  text-align: initial;
  text-align-last: auto;
  text-combine-upright: none;
  text-decoration-color: currentcolor;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-emphasis-color: currentcolor;
  text-emphasis-position: over right;
  text-emphasis-style: none;
  text-indent: 0;
  text-justify: auto;
  text-orientation: mixed;
  text-overflow: clip;
  text-rendering: auto;
  text-shadow: none;
  text-transform: none;
  text-underline-position: auto;
  top: auto;
  touch-action: auto;
  transform: none;
  transform-box: border-box;
  transform-origin: 50% 50% 0;
  transform-style: flat;
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: all;
  transition-timing-function: ease;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 2;
  width: auto;
  will-change: auto;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  writing-mode: horizontal-tb;
  z-index: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none; }

.borderless {
  border: none !important;
  border-radius: 0 !important; }

.clear-bg {
  background: transparent !important; }

.first-child-fullwidth > *:first-child {
  width: 100% !important; }

.fa-spin {
  animation: fa-spin 0.45s infinite linear; }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.breadcrumb {
  border: none !important;
  border-radius: 0 !important;
  background: none;
  margin-bottom: 0; }

html:not([data-layout="mobile"]) .breadcrumb {
  border: none !important;
  border-radius: 0 !important;
  background: none;
  margin-bottom: 1rem; }
  html:not([data-layout="mobile"]) .breadcrumb .breadcrumb-item.active {
    flex-basis: 100%;
    font-size: 22px;
    font-weight: bold;
    padding-left: 0;
    margin-top: 0.5rem; }
    html:not([data-layout="mobile"]) .breadcrumb .breadcrumb-item.active:before {
      display: none; }

.main-breadcrumb .breadcrumb {
  padding: 0; }

body.has-internal-breadcrumb .main-breadcrumb {
  display: none; }

body.hide-bread-crumbs .internal-breadcrumb {
  display: none !important; }

html[data-layout="mobile"] .internal-breadcrumb {
  padding-top: 0.5rem; }

html[data-layout="mobile"] .module :not(.internal-breadcrumb).main-breadcrumb {
  padding-top: 58px; }
  html[data-layout="mobile"] .module :not(.internal-breadcrumb).main-breadcrumb .breadcrumb {
    padding: 0 0.75rem 1rem; }

.nav-tabs {
  border: none;
  border-bottom: 1px solid #dfe3e6;
  margin-bottom: 1rem;
  margin-top: 0.5rem; }
  .nav-tabs .nav-item {
    cursor: pointer;
    margin-bottom: 0;
    margin-right: 5px; }
    .nav-tabs .nav-item .nav-link {
      border: none;
      border-radius: 0;
      font-size: 0.85rem;
      text-transform: uppercase;
      background: transparent; }
      .nav-tabs .nav-item .nav-link.active {
        box-shadow: 0 2px 0 0 #3d70b2;
        transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
        color: #3d70b2; }
      .nav-tabs .nav-item .nav-link:hover {
        box-shadow: 0 2px 0 0 #5596e6;
        transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .nav-tabs .nav-item a.tab-link .icon {
      display: none; }
  .nav-tabs.flex-column {
    background: #dfe3e6;
    margin-top: 0;
    box-shadow: -2px 0 0 0 #dfe3e6; }
    .nav-tabs.flex-column .nav-item {
      margin-right: 0; }
      .nav-tabs.flex-column .nav-item .nav-link {
        padding: 0.8rem 1rem; }
        .nav-tabs.flex-column .nav-item .nav-link.active {
          background-color: #ffffff;
          box-shadow: -2px 0 0 0 #3d70b2; }
        .nav-tabs.flex-column .nav-item .nav-link:hover {
          box-shadow: -2px 0 0 0 #5596e6; }
  .nav-tabs + div[direction^="vertical"] .tab-content {
    padding-left: 1rem; }

.mobile-navigator {
  display: none; }

html[data-layout="mobile"] .swan-main .tabs-nav:not(.tabs-nav-first-of-type) li a {
  padding: 0.5rem 0.5rem; }

html[data-layout="mobile"] .swan-main .mobile-navigator {
  display: none; }

html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  overflow: auto; }
  html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav {
    flex-wrap: nowrap;
    white-space: nowrap;
    position: fixed;
    height: auto;
    left: 0;
    bottom: 0;
    right: 0;
    background: #f4f5f7;
    padding: 0 30px;
    margin: 0;
    z-index: 198;
    display: flex;
    flex-direction: row;
    border-bottom: none;
    box-shadow: inset 0 2px 1px -1px #ede3e6;
    overflow-y: scroll; }
    html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav > li {
      flex: 1;
      text-align: center;
      margin: 0;
      padding: 0 10px; }
      html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav > li:last-child {
        padding-right: 30px; }
      html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav > li a.label-only {
        font-size: 0.9rem;
        padding: 1rem;
        font-weight: 900; }
      html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav > li a:not(.active) .icon {
        color: #7d7c7c; }
      html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav > li a:not(.label-only) {
        box-shadow: none;
        padding: 0.3rem;
        font-weight: 900;
        font-size: 0.7rem; }
        html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav > li a:not(.label-only).tab-link {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center; }
          html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav > li a:not(.label-only).tab-link .icon {
            display: flex;
            width: 21px;
            height: 21px; }
            html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav > li a:not(.label-only).tab-link .icon > svg {
              height: 100%;
              width: 100%;
              display: flex; }
          html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav > li a:not(.label-only).tab-link .label {
            padding-top: 0.4rem;
            font-size: 0.6rem; }
    html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav + .mobile-navigator {
      display: block !important;
      height: 46px;
      left: 0;
      bottom: -46px;
      right: 0;
      position: fixed;
      z-index: 199; }
      html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav + .mobile-navigator .arrows {
        position: relative;
        height: 100%; }
        html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav + .mobile-navigator .arrows .arrow {
          position: absolute;
          justify-content: center;
          align-items: center;
          bottom: 46px;
          height: 100%;
          width: 30px;
          display: flex;
          background: #f4f5f7;
          justify-content: center;
          color: #737373; }
        html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav + .mobile-navigator .arrows .left-arrow {
          left: 0; }
        html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav + .mobile-navigator .arrows .right-arrow {
          right: 0; }
  html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav-compact {
    padding: 0; }
    html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav-compact li:last-child {
      padding: 0; }
    html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type > .tabs-nav-compact + .mobile-navigator {
      display: none !important; }
  html[data-layout="mobile"] .swan-main .tabs-wrapper-first-of-type .tabs-content {
    overflow: auto;
    box-shadow: inset 0px -4px 12px -10px lightgray;
    height: calc(100vh - 150px); }

.dropdown-menu {
  border-radius: 0;
  border: 1px solid #dfe3e6; }
  .dropdown-menu[data-placement="top-end"] {
    box-shadow: 0 1px 1px 0 #dfe3e6; }
  .dropdown-menu[data-placement="bottom-end"] {
    box-shadow: 0 0 1px 1px #dfe3e6; }

.swan-datepicker .form-group {
  display: flex;
  flex-direction: row; }
  .swan-datepicker .form-group label {
    order: 1; }
  .swan-datepicker .form-group .SingleDatePicker {
    order: 2;
    flex-basis: 70%;
    box-shadow: 0 1px 0 0 #8897a2; }
    .swan-datepicker .form-group .SingleDatePicker .DateInput_input__focused {
      border-bottom-color: transparent;
      outline: none;
      box-shadow: 0 2px 0 0 #3d70b2;
      transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .swan-datepicker .form-group .SingleDatePicker .SingleDatePickerInput__withBorder {
      border: none; }
    .swan-datepicker .form-group .SingleDatePicker .CalendarDay__selected,
    .swan-datepicker .form-group .SingleDatePicker .CalendarDay__selected:active,
    .swan-datepicker .form-group .SingleDatePicker .CalendarDay__selected:hover {
      background: #3d70b2;
      border: 1px double #3d70b2; }
    .swan-datepicker .form-group .SingleDatePicker .DayPickerKeyboardShortcuts_show__bottomRight {
      display: none; }
    .swan-datepicker .form-group .SingleDatePicker .DayPickerKeyboardShortcuts_show__bottomRight::before {
      border-right: 33px solid #3d70b2; }
    .swan-datepicker .form-group .SingleDatePicker .DateInput input {
      border-color: transparent; }

.swan-datepicker .CalendarDay {
  vertical-align: middle; }

.datetime-picker > div,
.datetime-picker > div > div > div {
  background: transparent !important;
  border: none !important; }

.brand-01--color {
  color: #3d70b2; }

.brand-01--bg {
  background: #3d70b2; }

.brand-01--d10--color {
  color: #30588c; }

.brand-01--d10--bg {
  background: #30588c; }

.brand-01--l10--color {
  color: #5b8ac7; }

.brand-01--l10--bg {
  background: #5b8ac7; }

.brand-01--d20--color {
  color: #234066; }

.brand-01--d20--bg {
  background: #234066; }

.brand-01--l20--color {
  color: #81a5d4; }

.brand-01--l20--bg {
  background: #81a5d4; }

.brand-01--d30--color {
  color: #162840; }

.brand-01--d30--bg {
  background: #162840; }

.brand-01--l30--color {
  color: #a7c0e1; }

.brand-01--l30--bg {
  background: #a7c0e1; }

.brand-01--d40--color {
  color: #09101a; }

.brand-01--d40--bg {
  background: #09101a; }

.brand-01--l40--color {
  color: #cddbee; }

.brand-01--l40--bg {
  background: #cddbee; }

.brand-01--d50--color {
  color: black; }

.brand-01--d50--bg {
  background: black; }

.brand-01--l50--color {
  color: #f3f6fb; }

.brand-01--l50--bg {
  background: #f3f6fb; }

.brand-01--d60--color {
  color: black; }

.brand-01--d60--bg {
  background: black; }

.brand-01--l60--color {
  color: white; }

.brand-01--l60--bg {
  background: white; }

.brand-01--d70--color {
  color: black; }

.brand-01--d70--bg {
  background: black; }

.brand-01--l70--color {
  color: white; }

.brand-01--l70--bg {
  background: white; }

.brand-01--d80--color {
  color: black; }

.brand-01--d80--bg {
  background: black; }

.brand-01--l80--color {
  color: white; }

.brand-01--l80--bg {
  background: white; }

.brand-01--d90--color {
  color: black; }

.brand-01--d90--bg {
  background: black; }

.brand-01--l90--color {
  color: white; }

.brand-01--l90--bg {
  background: white; }

.brand-01--d100--color {
  color: black; }

.brand-01--d100--bg {
  background: black; }

.brand-01--l100--color {
  color: white; }

.brand-01--l100--bg {
  background: white; }

.brand-02--color {
  color: #5596e6; }

.brand-02--bg {
  background: #5596e6; }

.brand-02--d10--color {
  color: #297bdf; }

.brand-02--d10--bg {
  background: #297bdf; }

.brand-02--l10--color {
  color: #81b1ed; }

.brand-02--l10--bg {
  background: #81b1ed; }

.brand-02--d20--color {
  color: #1b62ba; }

.brand-02--d20--bg {
  background: #1b62ba; }

.brand-02--l20--color {
  color: #aecdf3; }

.brand-02--l20--bg {
  background: #aecdf3; }

.brand-02--d30--color {
  color: #154b8d; }

.brand-02--d30--bg {
  background: #154b8d; }

.brand-02--l30--color {
  color: #dae8fa; }

.brand-02--l30--bg {
  background: #dae8fa; }

.brand-02--d40--color {
  color: #0e3361; }

.brand-02--d40--bg {
  background: #0e3361; }

.brand-02--l40--color {
  color: white; }

.brand-02--l40--bg {
  background: white; }

.brand-02--d50--color {
  color: #081c34; }

.brand-02--d50--bg {
  background: #081c34; }

.brand-02--l50--color {
  color: white; }

.brand-02--l50--bg {
  background: white; }

.brand-02--d60--color {
  color: #010408; }

.brand-02--d60--bg {
  background: #010408; }

.brand-02--l60--color {
  color: white; }

.brand-02--l60--bg {
  background: white; }

.brand-02--d70--color {
  color: black; }

.brand-02--d70--bg {
  background: black; }

.brand-02--l70--color {
  color: white; }

.brand-02--l70--bg {
  background: white; }

.brand-02--d80--color {
  color: black; }

.brand-02--d80--bg {
  background: black; }

.brand-02--l80--color {
  color: white; }

.brand-02--l80--bg {
  background: white; }

.brand-02--d90--color {
  color: black; }

.brand-02--d90--bg {
  background: black; }

.brand-02--l90--color {
  color: white; }

.brand-02--l90--bg {
  background: white; }

.brand-02--d100--color {
  color: black; }

.brand-02--d100--bg {
  background: black; }

.brand-02--l100--color {
  color: white; }

.brand-02--l100--bg {
  background: white; }

.brand-03--color {
  color: #41d6c3; }

.brand-03--bg {
  background: #41d6c3; }

.brand-03--d10--color {
  color: #28bca9; }

.brand-03--d10--bg {
  background: #28bca9; }

.brand-03--l10--color {
  color: #6bdfd0; }

.brand-03--l10--bg {
  background: #6bdfd0; }

.brand-03--d20--color {
  color: #1f9283; }

.brand-03--d20--bg {
  background: #1f9283; }

.brand-03--l20--color {
  color: #95e8dd; }

.brand-03--l20--bg {
  background: #95e8dd; }

.brand-03--d30--color {
  color: #16685d; }

.brand-03--d30--bg {
  background: #16685d; }

.brand-03--l30--color {
  color: #bff1eb; }

.brand-03--l30--bg {
  background: #bff1eb; }

.brand-03--d40--color {
  color: #0d3e38; }

.brand-03--d40--bg {
  background: #0d3e38; }

.brand-03--l40--color {
  color: #e9faf8; }

.brand-03--l40--bg {
  background: #e9faf8; }

.brand-03--d50--color {
  color: #041412; }

.brand-03--d50--bg {
  background: #041412; }

.brand-03--l50--color {
  color: white; }

.brand-03--l50--bg {
  background: white; }

.brand-03--d60--color {
  color: black; }

.brand-03--d60--bg {
  background: black; }

.brand-03--l60--color {
  color: white; }

.brand-03--l60--bg {
  background: white; }

.brand-03--d70--color {
  color: black; }

.brand-03--d70--bg {
  background: black; }

.brand-03--l70--color {
  color: white; }

.brand-03--l70--bg {
  background: white; }

.brand-03--d80--color {
  color: black; }

.brand-03--d80--bg {
  background: black; }

.brand-03--l80--color {
  color: white; }

.brand-03--l80--bg {
  background: white; }

.brand-03--d90--color {
  color: black; }

.brand-03--d90--bg {
  background: black; }

.brand-03--l90--color {
  color: white; }

.brand-03--l90--bg {
  background: white; }

.brand-03--d100--color {
  color: black; }

.brand-03--d100--bg {
  background: black; }

.brand-03--l100--color {
  color: white; }

.brand-03--l100--bg {
  background: white; }

.ui-01--color {
  color: #ffffff; }

.ui-01--bg {
  background: #ffffff; }

.ui-01--d10--color {
  color: #e6e6e6; }

.ui-01--d10--bg {
  background: #e6e6e6; }

.ui-01--l10--color {
  color: white; }

.ui-01--l10--bg {
  background: white; }

.ui-01--d20--color {
  color: #cccccc; }

.ui-01--d20--bg {
  background: #cccccc; }

.ui-01--l20--color {
  color: white; }

.ui-01--l20--bg {
  background: white; }

.ui-01--d30--color {
  color: #b3b3b3; }

.ui-01--d30--bg {
  background: #b3b3b3; }

.ui-01--l30--color {
  color: white; }

.ui-01--l30--bg {
  background: white; }

.ui-01--d40--color {
  color: #999999; }

.ui-01--d40--bg {
  background: #999999; }

.ui-01--l40--color {
  color: white; }

.ui-01--l40--bg {
  background: white; }

.ui-01--d50--color {
  color: gray; }

.ui-01--d50--bg {
  background: gray; }

.ui-01--l50--color {
  color: white; }

.ui-01--l50--bg {
  background: white; }

.ui-01--d60--color {
  color: #666666; }

.ui-01--d60--bg {
  background: #666666; }

.ui-01--l60--color {
  color: white; }

.ui-01--l60--bg {
  background: white; }

.ui-01--d70--color {
  color: #4d4d4d; }

.ui-01--d70--bg {
  background: #4d4d4d; }

.ui-01--l70--color {
  color: white; }

.ui-01--l70--bg {
  background: white; }

.ui-01--d80--color {
  color: #333333; }

.ui-01--d80--bg {
  background: #333333; }

.ui-01--l80--color {
  color: white; }

.ui-01--l80--bg {
  background: white; }

.ui-01--d90--color {
  color: #1a1a1a; }

.ui-01--d90--bg {
  background: #1a1a1a; }

.ui-01--l90--color {
  color: white; }

.ui-01--l90--bg {
  background: white; }

.ui-01--d100--color {
  color: black; }

.ui-01--d100--bg {
  background: black; }

.ui-01--l100--color {
  color: white; }

.ui-01--l100--bg {
  background: white; }

.ui-02--color {
  color: #f4f7fb; }

.ui-02--bg {
  background: #f4f7fb; }

.ui-02--d10--color {
  color: #cfdced; }

.ui-02--d10--bg {
  background: #cfdced; }

.ui-02--l10--color {
  color: white; }

.ui-02--l10--bg {
  background: white; }

.ui-02--d20--color {
  color: #a9c1e0; }

.ui-02--d20--bg {
  background: #a9c1e0; }

.ui-02--l20--color {
  color: white; }

.ui-02--l20--bg {
  background: white; }

.ui-02--d30--color {
  color: #84a5d2; }

.ui-02--d30--bg {
  background: #84a5d2; }

.ui-02--l30--color {
  color: white; }

.ui-02--l30--bg {
  background: white; }

.ui-02--d40--color {
  color: #5e8ac5; }

.ui-02--d40--bg {
  background: #5e8ac5; }

.ui-02--l40--color {
  color: white; }

.ui-02--l40--bg {
  background: white; }

.ui-02--d50--color {
  color: #4070b0; }

.ui-02--d50--bg {
  background: #4070b0; }

.ui-02--l50--color {
  color: white; }

.ui-02--l50--bg {
  background: white; }

.ui-02--d60--color {
  color: #32588b; }

.ui-02--d60--bg {
  background: #32588b; }

.ui-02--l60--color {
  color: white; }

.ui-02--l60--bg {
  background: white; }

.ui-02--d70--color {
  color: #254065; }

.ui-02--d70--bg {
  background: #254065; }

.ui-02--l70--color {
  color: white; }

.ui-02--l70--bg {
  background: white; }

.ui-02--d80--color {
  color: #172940; }

.ui-02--d80--bg {
  background: #172940; }

.ui-02--l80--color {
  color: white; }

.ui-02--l80--bg {
  background: white; }

.ui-02--d90--color {
  color: #0a111a; }

.ui-02--d90--bg {
  background: #0a111a; }

.ui-02--l90--color {
  color: white; }

.ui-02--l90--bg {
  background: white; }

.ui-02--d100--color {
  color: black; }

.ui-02--d100--bg {
  background: black; }

.ui-02--l100--color {
  color: white; }

.ui-02--l100--bg {
  background: white; }

.ui-03--color {
  color: #dfe3e6; }

.ui-03--bg {
  background: #dfe3e6; }

.ui-03--d10--color {
  color: #c2cad0; }

.ui-03--d10--bg {
  background: #c2cad0; }

.ui-03--l10--color {
  color: #fcfcfc; }

.ui-03--l10--bg {
  background: #fcfcfc; }

.ui-03--d20--color {
  color: #a6b1b9; }

.ui-03--d20--bg {
  background: #a6b1b9; }

.ui-03--l20--color {
  color: white; }

.ui-03--l20--bg {
  background: white; }

.ui-03--d30--color {
  color: #8998a3; }

.ui-03--d30--bg {
  background: #8998a3; }

.ui-03--l30--color {
  color: white; }

.ui-03--l30--bg {
  background: white; }

.ui-03--d40--color {
  color: #6d7f8c; }

.ui-03--d40--bg {
  background: #6d7f8c; }

.ui-03--l40--color {
  color: white; }

.ui-03--l40--bg {
  background: white; }

.ui-03--d50--color {
  color: #57656f; }

.ui-03--d50--bg {
  background: #57656f; }

.ui-03--l50--color {
  color: white; }

.ui-03--l50--bg {
  background: white; }

.ui-03--d60--color {
  color: #404b53; }

.ui-03--d60--bg {
  background: #404b53; }

.ui-03--l60--color {
  color: white; }

.ui-03--l60--bg {
  background: white; }

.ui-03--d70--color {
  color: #2a3136; }

.ui-03--d70--bg {
  background: #2a3136; }

.ui-03--l70--color {
  color: white; }

.ui-03--l70--bg {
  background: white; }

.ui-03--d80--color {
  color: #141719; }

.ui-03--d80--bg {
  background: #141719; }

.ui-03--l80--color {
  color: white; }

.ui-03--l80--bg {
  background: white; }

.ui-03--d90--color {
  color: black; }

.ui-03--d90--bg {
  background: black; }

.ui-03--l90--color {
  color: white; }

.ui-03--l90--bg {
  background: white; }

.ui-03--d100--color {
  color: black; }

.ui-03--d100--bg {
  background: black; }

.ui-03--l100--color {
  color: white; }

.ui-03--l100--bg {
  background: white; }

.ui-04--color {
  color: #8897a2; }

.ui-04--bg {
  background: #8897a2; }

.ui-04--d10--color {
  color: #6c7e8b; }

.ui-04--d10--bg {
  background: #6c7e8b; }

.ui-04--l10--color {
  color: #a5b0b8; }

.ui-04--l10--bg {
  background: #a5b0b8; }

.ui-04--d20--color {
  color: #56646e; }

.ui-04--d20--bg {
  background: #56646e; }

.ui-04--l20--color {
  color: #c1c9cf; }

.ui-04--l20--bg {
  background: #c1c9cf; }

.ui-04--d30--color {
  color: #404a51; }

.ui-04--d30--bg {
  background: #404a51; }

.ui-04--l30--color {
  color: #dee2e5; }

.ui-04--l30--bg {
  background: #dee2e5; }

.ui-04--d40--color {
  color: #293035; }

.ui-04--d40--bg {
  background: #293035; }

.ui-04--l40--color {
  color: #fbfbfb; }

.ui-04--l40--bg {
  background: #fbfbfb; }

.ui-04--d50--color {
  color: #131618; }

.ui-04--d50--bg {
  background: #131618; }

.ui-04--l50--color {
  color: white; }

.ui-04--l50--bg {
  background: white; }

.ui-04--d60--color {
  color: black; }

.ui-04--d60--bg {
  background: black; }

.ui-04--l60--color {
  color: white; }

.ui-04--l60--bg {
  background: white; }

.ui-04--d70--color {
  color: black; }

.ui-04--d70--bg {
  background: black; }

.ui-04--l70--color {
  color: white; }

.ui-04--l70--bg {
  background: white; }

.ui-04--d80--color {
  color: black; }

.ui-04--d80--bg {
  background: black; }

.ui-04--l80--color {
  color: white; }

.ui-04--l80--bg {
  background: white; }

.ui-04--d90--color {
  color: black; }

.ui-04--d90--bg {
  background: black; }

.ui-04--l90--color {
  color: white; }

.ui-04--l90--bg {
  background: white; }

.ui-04--d100--color {
  color: black; }

.ui-04--d100--bg {
  background: black; }

.ui-04--l100--color {
  color: white; }

.ui-04--l100--bg {
  background: white; }

.ui-05--color {
  color: #5a6872; }

.ui-05--bg {
  background: #5a6872; }

.ui-05--d10--color {
  color: #444e56; }

.ui-05--d10--bg {
  background: #444e56; }

.ui-05--l10--color {
  color: #71828f; }

.ui-05--l10--bg {
  background: #71828f; }

.ui-05--d20--color {
  color: #2d3439; }

.ui-05--d20--bg {
  background: #2d3439; }

.ui-05--l20--color {
  color: #8d9ba5; }

.ui-05--l20--bg {
  background: #8d9ba5; }

.ui-05--d30--color {
  color: #171a1d; }

.ui-05--d30--bg {
  background: #171a1d; }

.ui-05--l30--color {
  color: #aab4bc; }

.ui-05--l30--bg {
  background: #aab4bc; }

.ui-05--d40--color {
  color: black; }

.ui-05--d40--bg {
  background: black; }

.ui-05--l40--color {
  color: #c6cdd2; }

.ui-05--l40--bg {
  background: #c6cdd2; }

.ui-05--d50--color {
  color: black; }

.ui-05--d50--bg {
  background: black; }

.ui-05--l50--color {
  color: #e3e6e9; }

.ui-05--l50--bg {
  background: #e3e6e9; }

.ui-05--d60--color {
  color: black; }

.ui-05--d60--bg {
  background: black; }

.ui-05--l60--color {
  color: white; }

.ui-05--l60--bg {
  background: white; }

.ui-05--d70--color {
  color: black; }

.ui-05--d70--bg {
  background: black; }

.ui-05--l70--color {
  color: white; }

.ui-05--l70--bg {
  background: white; }

.ui-05--d80--color {
  color: black; }

.ui-05--d80--bg {
  background: black; }

.ui-05--l80--color {
  color: white; }

.ui-05--l80--bg {
  background: white; }

.ui-05--d90--color {
  color: black; }

.ui-05--d90--bg {
  background: black; }

.ui-05--l90--color {
  color: white; }

.ui-05--l90--bg {
  background: white; }

.ui-05--d100--color {
  color: black; }

.ui-05--d100--bg {
  background: black; }

.ui-05--l100--color {
  color: white; }

.ui-05--l100--bg {
  background: white; }

.text-01--color {
  color: #152935; }

.text-01--bg {
  background: #152935; }

.text-01--d10--color {
  color: #070d10; }

.text-01--d10--bg {
  background: #070d10; }

.text-01--l10--color {
  color: #23455a; }

.text-01--l10--bg {
  background: #23455a; }

.text-01--d20--color {
  color: black; }

.text-01--d20--bg {
  background: black; }

.text-01--l20--color {
  color: #32627e; }

.text-01--l20--bg {
  background: #32627e; }

.text-01--d30--color {
  color: black; }

.text-01--d30--bg {
  background: black; }

.text-01--l30--color {
  color: #407ea3; }

.text-01--l30--bg {
  background: #407ea3; }

.text-01--d40--color {
  color: black; }

.text-01--d40--bg {
  background: black; }

.text-01--l40--color {
  color: #5998bd; }

.text-01--l40--bg {
  background: #5998bd; }

.text-01--d50--color {
  color: black; }

.text-01--d50--bg {
  background: black; }

.text-01--l50--color {
  color: #7daecc; }

.text-01--l50--bg {
  background: #7daecc; }

.text-01--d60--color {
  color: black; }

.text-01--d60--bg {
  background: black; }

.text-01--l60--color {
  color: #a2c5da; }

.text-01--l60--bg {
  background: #a2c5da; }

.text-01--d70--color {
  color: black; }

.text-01--d70--bg {
  background: black; }

.text-01--l70--color {
  color: #c6dce9; }

.text-01--l70--bg {
  background: #c6dce9; }

.text-01--d80--color {
  color: black; }

.text-01--d80--bg {
  background: black; }

.text-01--l80--color {
  color: #ebf3f7; }

.text-01--l80--bg {
  background: #ebf3f7; }

.text-01--d90--color {
  color: black; }

.text-01--d90--bg {
  background: black; }

.text-01--l90--color {
  color: white; }

.text-01--l90--bg {
  background: white; }

.text-01--d100--color {
  color: black; }

.text-01--d100--bg {
  background: black; }

.text-01--l100--color {
  color: white; }

.text-01--l100--bg {
  background: white; }

.text-02--color {
  color: #5a6872; }

.text-02--bg {
  background: #5a6872; }

.text-02--d10--color {
  color: #444e56; }

.text-02--d10--bg {
  background: #444e56; }

.text-02--l10--color {
  color: #71828f; }

.text-02--l10--bg {
  background: #71828f; }

.text-02--d20--color {
  color: #2d3439; }

.text-02--d20--bg {
  background: #2d3439; }

.text-02--l20--color {
  color: #8d9ba5; }

.text-02--l20--bg {
  background: #8d9ba5; }

.text-02--d30--color {
  color: #171a1d; }

.text-02--d30--bg {
  background: #171a1d; }

.text-02--l30--color {
  color: #aab4bc; }

.text-02--l30--bg {
  background: #aab4bc; }

.text-02--d40--color {
  color: black; }

.text-02--d40--bg {
  background: black; }

.text-02--l40--color {
  color: #c6cdd2; }

.text-02--l40--bg {
  background: #c6cdd2; }

.text-02--d50--color {
  color: black; }

.text-02--d50--bg {
  background: black; }

.text-02--l50--color {
  color: #e3e6e9; }

.text-02--l50--bg {
  background: #e3e6e9; }

.text-02--d60--color {
  color: black; }

.text-02--d60--bg {
  background: black; }

.text-02--l60--color {
  color: white; }

.text-02--l60--bg {
  background: white; }

.text-02--d70--color {
  color: black; }

.text-02--d70--bg {
  background: black; }

.text-02--l70--color {
  color: white; }

.text-02--l70--bg {
  background: white; }

.text-02--d80--color {
  color: black; }

.text-02--d80--bg {
  background: black; }

.text-02--l80--color {
  color: white; }

.text-02--l80--bg {
  background: white; }

.text-02--d90--color {
  color: black; }

.text-02--d90--bg {
  background: black; }

.text-02--l90--color {
  color: white; }

.text-02--l90--bg {
  background: white; }

.text-02--d100--color {
  color: black; }

.text-02--d100--bg {
  background: black; }

.text-02--l100--color {
  color: white; }

.text-02--l100--bg {
  background: white; }

.text-03--color {
  color: #cdd1d4; }

.text-03--bg {
  background: #cdd1d4; }

.text-03--d10--color {
  color: #b2b8bc; }

.text-03--d10--bg {
  background: #b2b8bc; }

.text-03--l10--color {
  color: #e8eaec; }

.text-03--l10--bg {
  background: #e8eaec; }

.text-03--d20--color {
  color: #969fa5; }

.text-03--d20--bg {
  background: #969fa5; }

.text-03--l20--color {
  color: white; }

.text-03--l20--bg {
  background: white; }

.text-03--d30--color {
  color: #7b858d; }

.text-03--d30--bg {
  background: #7b858d; }

.text-03--l30--color {
  color: white; }

.text-03--l30--bg {
  background: white; }

.text-03--d40--color {
  color: #626c73; }

.text-03--d40--bg {
  background: #626c73; }

.text-03--l40--color {
  color: white; }

.text-03--l40--bg {
  background: white; }

.text-03--d50--color {
  color: #4b5257; }

.text-03--d50--bg {
  background: #4b5257; }

.text-03--l50--color {
  color: white; }

.text-03--l50--bg {
  background: white; }

.text-03--d60--color {
  color: #33383c; }

.text-03--d60--bg {
  background: #33383c; }

.text-03--l60--color {
  color: white; }

.text-03--l60--bg {
  background: white; }

.text-03--d70--color {
  color: #1c1e20; }

.text-03--d70--bg {
  background: #1c1e20; }

.text-03--l70--color {
  color: white; }

.text-03--l70--bg {
  background: white; }

.text-03--d80--color {
  color: #040505; }

.text-03--d80--bg {
  background: #040505; }

.text-03--l80--color {
  color: white; }

.text-03--l80--bg {
  background: white; }

.text-03--d90--color {
  color: black; }

.text-03--d90--bg {
  background: black; }

.text-03--l90--color {
  color: white; }

.text-03--l90--bg {
  background: white; }

.text-03--d100--color {
  color: black; }

.text-03--d100--bg {
  background: black; }

.text-03--l100--color {
  color: white; }

.text-03--l100--bg {
  background: white; }

.inverse-01--color {
  color: #ffffff; }

.inverse-01--bg {
  background: #ffffff; }

.inverse-01--d10--color {
  color: #e6e6e6; }

.inverse-01--d10--bg {
  background: #e6e6e6; }

.inverse-01--l10--color {
  color: white; }

.inverse-01--l10--bg {
  background: white; }

.inverse-01--d20--color {
  color: #cccccc; }

.inverse-01--d20--bg {
  background: #cccccc; }

.inverse-01--l20--color {
  color: white; }

.inverse-01--l20--bg {
  background: white; }

.inverse-01--d30--color {
  color: #b3b3b3; }

.inverse-01--d30--bg {
  background: #b3b3b3; }

.inverse-01--l30--color {
  color: white; }

.inverse-01--l30--bg {
  background: white; }

.inverse-01--d40--color {
  color: #999999; }

.inverse-01--d40--bg {
  background: #999999; }

.inverse-01--l40--color {
  color: white; }

.inverse-01--l40--bg {
  background: white; }

.inverse-01--d50--color {
  color: gray; }

.inverse-01--d50--bg {
  background: gray; }

.inverse-01--l50--color {
  color: white; }

.inverse-01--l50--bg {
  background: white; }

.inverse-01--d60--color {
  color: #666666; }

.inverse-01--d60--bg {
  background: #666666; }

.inverse-01--l60--color {
  color: white; }

.inverse-01--l60--bg {
  background: white; }

.inverse-01--d70--color {
  color: #4d4d4d; }

.inverse-01--d70--bg {
  background: #4d4d4d; }

.inverse-01--l70--color {
  color: white; }

.inverse-01--l70--bg {
  background: white; }

.inverse-01--d80--color {
  color: #333333; }

.inverse-01--d80--bg {
  background: #333333; }

.inverse-01--l80--color {
  color: white; }

.inverse-01--l80--bg {
  background: white; }

.inverse-01--d90--color {
  color: #1a1a1a; }

.inverse-01--d90--bg {
  background: #1a1a1a; }

.inverse-01--l90--color {
  color: white; }

.inverse-01--l90--bg {
  background: white; }

.inverse-01--d100--color {
  color: black; }

.inverse-01--d100--bg {
  background: black; }

.inverse-01--l100--color {
  color: white; }

.inverse-01--l100--bg {
  background: white; }

.inverse-02--color {
  color: #272d33; }

.inverse-02--bg {
  background: #272d33; }

.inverse-02--d10--color {
  color: #111416; }

.inverse-02--d10--bg {
  background: #111416; }

.inverse-02--l10--color {
  color: #3d4750; }

.inverse-02--l10--bg {
  background: #3d4750; }

.inverse-02--d20--color {
  color: black; }

.inverse-02--d20--bg {
  background: black; }

.inverse-02--l20--color {
  color: #53606d; }

.inverse-02--l20--bg {
  background: #53606d; }

.inverse-02--d30--color {
  color: black; }

.inverse-02--d30--bg {
  background: black; }

.inverse-02--l30--color {
  color: #697a8a; }

.inverse-02--l30--bg {
  background: #697a8a; }

.inverse-02--d40--color {
  color: black; }

.inverse-02--d40--bg {
  background: black; }

.inverse-02--l40--color {
  color: #8593a1; }

.inverse-02--l40--bg {
  background: #8593a1; }

.inverse-02--d50--color {
  color: black; }

.inverse-02--d50--bg {
  background: black; }

.inverse-02--l50--color {
  color: #a2adb8; }

.inverse-02--l50--bg {
  background: #a2adb8; }

.inverse-02--d60--color {
  color: black; }

.inverse-02--d60--bg {
  background: black; }

.inverse-02--l60--color {
  color: #bec6ce; }

.inverse-02--l60--bg {
  background: #bec6ce; }

.inverse-02--d70--color {
  color: black; }

.inverse-02--d70--bg {
  background: black; }

.inverse-02--l70--color {
  color: #dbe0e4; }

.inverse-02--l70--bg {
  background: #dbe0e4; }

.inverse-02--d80--color {
  color: black; }

.inverse-02--d80--bg {
  background: black; }

.inverse-02--l80--color {
  color: #f8f9fa; }

.inverse-02--l80--bg {
  background: #f8f9fa; }

.inverse-02--d90--color {
  color: black; }

.inverse-02--d90--bg {
  background: black; }

.inverse-02--l90--color {
  color: white; }

.inverse-02--l90--bg {
  background: white; }

.inverse-02--d100--color {
  color: black; }

.inverse-02--d100--bg {
  background: black; }

.inverse-02--l100--color {
  color: white; }

.inverse-02--l100--bg {
  background: white; }

.field-01--color {
  color: #f4f7fb; }

.field-01--bg {
  background: #f4f7fb; }

.field-01--d10--color {
  color: #cfdced; }

.field-01--d10--bg {
  background: #cfdced; }

.field-01--l10--color {
  color: white; }

.field-01--l10--bg {
  background: white; }

.field-01--d20--color {
  color: #a9c1e0; }

.field-01--d20--bg {
  background: #a9c1e0; }

.field-01--l20--color {
  color: white; }

.field-01--l20--bg {
  background: white; }

.field-01--d30--color {
  color: #84a5d2; }

.field-01--d30--bg {
  background: #84a5d2; }

.field-01--l30--color {
  color: white; }

.field-01--l30--bg {
  background: white; }

.field-01--d40--color {
  color: #5e8ac5; }

.field-01--d40--bg {
  background: #5e8ac5; }

.field-01--l40--color {
  color: white; }

.field-01--l40--bg {
  background: white; }

.field-01--d50--color {
  color: #4070b0; }

.field-01--d50--bg {
  background: #4070b0; }

.field-01--l50--color {
  color: white; }

.field-01--l50--bg {
  background: white; }

.field-01--d60--color {
  color: #32588b; }

.field-01--d60--bg {
  background: #32588b; }

.field-01--l60--color {
  color: white; }

.field-01--l60--bg {
  background: white; }

.field-01--d70--color {
  color: #254065; }

.field-01--d70--bg {
  background: #254065; }

.field-01--l70--color {
  color: white; }

.field-01--l70--bg {
  background: white; }

.field-01--d80--color {
  color: #172940; }

.field-01--d80--bg {
  background: #172940; }

.field-01--l80--color {
  color: white; }

.field-01--l80--bg {
  background: white; }

.field-01--d90--color {
  color: #0a111a; }

.field-01--d90--bg {
  background: #0a111a; }

.field-01--l90--color {
  color: white; }

.field-01--l90--bg {
  background: white; }

.field-01--d100--color {
  color: black; }

.field-01--d100--bg {
  background: black; }

.field-01--l100--color {
  color: white; }

.field-01--l100--bg {
  background: white; }

.field-02--color {
  color: #ffffff; }

.field-02--bg {
  background: #ffffff; }

.field-02--d10--color {
  color: #e6e6e6; }

.field-02--d10--bg {
  background: #e6e6e6; }

.field-02--l10--color {
  color: white; }

.field-02--l10--bg {
  background: white; }

.field-02--d20--color {
  color: #cccccc; }

.field-02--d20--bg {
  background: #cccccc; }

.field-02--l20--color {
  color: white; }

.field-02--l20--bg {
  background: white; }

.field-02--d30--color {
  color: #b3b3b3; }

.field-02--d30--bg {
  background: #b3b3b3; }

.field-02--l30--color {
  color: white; }

.field-02--l30--bg {
  background: white; }

.field-02--d40--color {
  color: #999999; }

.field-02--d40--bg {
  background: #999999; }

.field-02--l40--color {
  color: white; }

.field-02--l40--bg {
  background: white; }

.field-02--d50--color {
  color: gray; }

.field-02--d50--bg {
  background: gray; }

.field-02--l50--color {
  color: white; }

.field-02--l50--bg {
  background: white; }

.field-02--d60--color {
  color: #666666; }

.field-02--d60--bg {
  background: #666666; }

.field-02--l60--color {
  color: white; }

.field-02--l60--bg {
  background: white; }

.field-02--d70--color {
  color: #4d4d4d; }

.field-02--d70--bg {
  background: #4d4d4d; }

.field-02--l70--color {
  color: white; }

.field-02--l70--bg {
  background: white; }

.field-02--d80--color {
  color: #333333; }

.field-02--d80--bg {
  background: #333333; }

.field-02--l80--color {
  color: white; }

.field-02--l80--bg {
  background: white; }

.field-02--d90--color {
  color: #1a1a1a; }

.field-02--d90--bg {
  background: #1a1a1a; }

.field-02--l90--color {
  color: white; }

.field-02--l90--bg {
  background: white; }

.field-02--d100--color {
  color: black; }

.field-02--d100--bg {
  background: black; }

.field-02--l100--color {
  color: white; }

.field-02--l100--bg {
  background: white; }

.hover-primary--color {
  color: #30588c; }

.hover-primary--bg {
  background: #30588c; }

.hover-primary--d10--color {
  color: #234066; }

.hover-primary--d10--bg {
  background: #234066; }

.hover-primary--l10--color {
  color: #3d70b2; }

.hover-primary--l10--bg {
  background: #3d70b2; }

.hover-primary--d20--color {
  color: #162840; }

.hover-primary--d20--bg {
  background: #162840; }

.hover-primary--l20--color {
  color: #5b8ac7; }

.hover-primary--l20--bg {
  background: #5b8ac7; }

.hover-primary--d30--color {
  color: #09101a; }

.hover-primary--d30--bg {
  background: #09101a; }

.hover-primary--l30--color {
  color: #81a5d4; }

.hover-primary--l30--bg {
  background: #81a5d4; }

.hover-primary--d40--color {
  color: black; }

.hover-primary--d40--bg {
  background: black; }

.hover-primary--l40--color {
  color: #a7c0e1; }

.hover-primary--l40--bg {
  background: #a7c0e1; }

.hover-primary--d50--color {
  color: black; }

.hover-primary--d50--bg {
  background: black; }

.hover-primary--l50--color {
  color: #cddbee; }

.hover-primary--l50--bg {
  background: #cddbee; }

.hover-primary--d60--color {
  color: black; }

.hover-primary--d60--bg {
  background: black; }

.hover-primary--l60--color {
  color: #f3f6fb; }

.hover-primary--l60--bg {
  background: #f3f6fb; }

.hover-primary--d70--color {
  color: black; }

.hover-primary--d70--bg {
  background: black; }

.hover-primary--l70--color {
  color: white; }

.hover-primary--l70--bg {
  background: white; }

.hover-primary--d80--color {
  color: black; }

.hover-primary--d80--bg {
  background: black; }

.hover-primary--l80--color {
  color: white; }

.hover-primary--l80--bg {
  background: white; }

.hover-primary--d90--color {
  color: black; }

.hover-primary--d90--bg {
  background: black; }

.hover-primary--l90--color {
  color: white; }

.hover-primary--l90--bg {
  background: white; }

.hover-primary--d100--color {
  color: black; }

.hover-primary--d100--bg {
  background: black; }

.hover-primary--l100--color {
  color: white; }

.hover-primary--l100--bg {
  background: white; }

.hover-primary-text--color {
  color: #294c86; }

.hover-primary-text--bg {
  background: #294c86; }

.hover-primary-text--d10--color {
  color: #1d365f; }

.hover-primary-text--d10--bg {
  background: #1d365f; }

.hover-primary-text--l10--color {
  color: #3562ad; }

.hover-primary-text--l10--bg {
  background: #3562ad; }

.hover-primary-text--d20--color {
  color: #112038; }

.hover-primary-text--d20--bg {
  background: #112038; }

.hover-primary-text--l20--color {
  color: #4d7bc8; }

.hover-primary-text--l20--bg {
  background: #4d7bc8; }

.hover-primary-text--d30--color {
  color: #050a11; }

.hover-primary-text--d30--bg {
  background: #050a11; }

.hover-primary-text--l30--color {
  color: #7498d4; }

.hover-primary-text--l30--bg {
  background: #7498d4; }

.hover-primary-text--d40--color {
  color: black; }

.hover-primary-text--d40--bg {
  background: black; }

.hover-primary-text--l40--color {
  color: #9bb5e0; }

.hover-primary-text--l40--bg {
  background: #9bb5e0; }

.hover-primary-text--d50--color {
  color: black; }

.hover-primary-text--d50--bg {
  background: black; }

.hover-primary-text--l50--color {
  color: #c2d2ec; }

.hover-primary-text--l50--bg {
  background: #c2d2ec; }

.hover-primary-text--d60--color {
  color: black; }

.hover-primary-text--d60--bg {
  background: black; }

.hover-primary-text--l60--color {
  color: #e9eff8; }

.hover-primary-text--l60--bg {
  background: #e9eff8; }

.hover-primary-text--d70--color {
  color: black; }

.hover-primary-text--d70--bg {
  background: black; }

.hover-primary-text--l70--color {
  color: white; }

.hover-primary-text--l70--bg {
  background: white; }

.hover-primary-text--d80--color {
  color: black; }

.hover-primary-text--d80--bg {
  background: black; }

.hover-primary-text--l80--color {
  color: white; }

.hover-primary-text--l80--bg {
  background: white; }

.hover-primary-text--d90--color {
  color: black; }

.hover-primary-text--d90--bg {
  background: black; }

.hover-primary-text--l90--color {
  color: white; }

.hover-primary-text--l90--bg {
  background: white; }

.hover-primary-text--d100--color {
  color: black; }

.hover-primary-text--d100--bg {
  background: black; }

.hover-primary-text--l100--color {
  color: white; }

.hover-primary-text--l100--bg {
  background: white; }

.hover-danger--color {
  color: #bd1427; }

.hover-danger--bg {
  background: #bd1427; }

.hover-danger--d10--color {
  color: #8f0f1d; }

.hover-danger--d10--bg {
  background: #8f0f1d; }

.hover-danger--l10--color {
  color: #e71d34; }

.hover-danger--l10--bg {
  background: #e71d34; }

.hover-danger--d20--color {
  color: #610a14; }

.hover-danger--d20--bg {
  background: #610a14; }

.hover-danger--l20--color {
  color: #ec4b5d; }

.hover-danger--l20--bg {
  background: #ec4b5d; }

.hover-danger--d30--color {
  color: #33050a; }

.hover-danger--d30--bg {
  background: #33050a; }

.hover-danger--l30--color {
  color: #f17987; }

.hover-danger--l30--bg {
  background: #f17987; }

.hover-danger--d40--color {
  color: #050001; }

.hover-danger--d40--bg {
  background: #050001; }

.hover-danger--l40--color {
  color: #f6a7b0; }

.hover-danger--l40--bg {
  background: #f6a7b0; }

.hover-danger--d50--color {
  color: black; }

.hover-danger--d50--bg {
  background: black; }

.hover-danger--l50--color {
  color: #fbd5da; }

.hover-danger--l50--bg {
  background: #fbd5da; }

.hover-danger--d60--color {
  color: black; }

.hover-danger--d60--bg {
  background: black; }

.hover-danger--l60--color {
  color: white; }

.hover-danger--l60--bg {
  background: white; }

.hover-danger--d70--color {
  color: black; }

.hover-danger--d70--bg {
  background: black; }

.hover-danger--l70--color {
  color: white; }

.hover-danger--l70--bg {
  background: white; }

.hover-danger--d80--color {
  color: black; }

.hover-danger--d80--bg {
  background: black; }

.hover-danger--l80--color {
  color: white; }

.hover-danger--l80--bg {
  background: white; }

.hover-danger--d90--color {
  color: black; }

.hover-danger--d90--bg {
  background: black; }

.hover-danger--l90--color {
  color: white; }

.hover-danger--l90--bg {
  background: white; }

.hover-danger--d100--color {
  color: black; }

.hover-danger--d100--bg {
  background: black; }

.hover-danger--l100--color {
  color: white; }

.hover-danger--l100--bg {
  background: white; }

.hover-secondary--color {
  color: #3d70b2; }

.hover-secondary--bg {
  background: #3d70b2; }

.hover-secondary--d10--color {
  color: #30588c; }

.hover-secondary--d10--bg {
  background: #30588c; }

.hover-secondary--l10--color {
  color: #5b8ac7; }

.hover-secondary--l10--bg {
  background: #5b8ac7; }

.hover-secondary--d20--color {
  color: #234066; }

.hover-secondary--d20--bg {
  background: #234066; }

.hover-secondary--l20--color {
  color: #81a5d4; }

.hover-secondary--l20--bg {
  background: #81a5d4; }

.hover-secondary--d30--color {
  color: #162840; }

.hover-secondary--d30--bg {
  background: #162840; }

.hover-secondary--l30--color {
  color: #a7c0e1; }

.hover-secondary--l30--bg {
  background: #a7c0e1; }

.hover-secondary--d40--color {
  color: #09101a; }

.hover-secondary--d40--bg {
  background: #09101a; }

.hover-secondary--l40--color {
  color: #cddbee; }

.hover-secondary--l40--bg {
  background: #cddbee; }

.hover-secondary--d50--color {
  color: black; }

.hover-secondary--d50--bg {
  background: black; }

.hover-secondary--l50--color {
  color: #f3f6fb; }

.hover-secondary--l50--bg {
  background: #f3f6fb; }

.hover-secondary--d60--color {
  color: black; }

.hover-secondary--d60--bg {
  background: black; }

.hover-secondary--l60--color {
  color: white; }

.hover-secondary--l60--bg {
  background: white; }

.hover-secondary--d70--color {
  color: black; }

.hover-secondary--d70--bg {
  background: black; }

.hover-secondary--l70--color {
  color: white; }

.hover-secondary--l70--bg {
  background: white; }

.hover-secondary--d80--color {
  color: black; }

.hover-secondary--d80--bg {
  background: black; }

.hover-secondary--l80--color {
  color: white; }

.hover-secondary--l80--bg {
  background: white; }

.hover-secondary--d90--color {
  color: black; }

.hover-secondary--d90--bg {
  background: black; }

.hover-secondary--l90--color {
  color: white; }

.hover-secondary--l90--bg {
  background: white; }

.hover-secondary--d100--color {
  color: black; }

.hover-secondary--d100--bg {
  background: black; }

.hover-secondary--l100--color {
  color: white; }

.hover-secondary--l100--bg {
  background: white; }

.hover-row--color {
  color: rgba(85, 150, 230, 0.1); }

.hover-row--bg {
  background: rgba(85, 150, 230, 0.1); }

.hover-row--d10--color {
  color: rgba(41, 123, 223, 0.1); }

.hover-row--d10--bg {
  background: rgba(41, 123, 223, 0.1); }

.hover-row--l10--color {
  color: rgba(129, 177, 237, 0.1); }

.hover-row--l10--bg {
  background: rgba(129, 177, 237, 0.1); }

.hover-row--d20--color {
  color: rgba(27, 98, 186, 0.1); }

.hover-row--d20--bg {
  background: rgba(27, 98, 186, 0.1); }

.hover-row--l20--color {
  color: rgba(174, 205, 243, 0.1); }

.hover-row--l20--bg {
  background: rgba(174, 205, 243, 0.1); }

.hover-row--d30--color {
  color: rgba(21, 75, 141, 0.1); }

.hover-row--d30--bg {
  background: rgba(21, 75, 141, 0.1); }

.hover-row--l30--color {
  color: rgba(218, 232, 250, 0.1); }

.hover-row--l30--bg {
  background: rgba(218, 232, 250, 0.1); }

.hover-row--d40--color {
  color: rgba(14, 51, 97, 0.1); }

.hover-row--d40--bg {
  background: rgba(14, 51, 97, 0.1); }

.hover-row--l40--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l40--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d50--color {
  color: rgba(8, 28, 52, 0.1); }

.hover-row--d50--bg {
  background: rgba(8, 28, 52, 0.1); }

.hover-row--l50--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l50--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d60--color {
  color: rgba(1, 4, 8, 0.1); }

.hover-row--d60--bg {
  background: rgba(1, 4, 8, 0.1); }

.hover-row--l60--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l60--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d70--color {
  color: rgba(0, 0, 0, 0.1); }

.hover-row--d70--bg {
  background: rgba(0, 0, 0, 0.1); }

.hover-row--l70--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l70--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d80--color {
  color: rgba(0, 0, 0, 0.1); }

.hover-row--d80--bg {
  background: rgba(0, 0, 0, 0.1); }

.hover-row--l80--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l80--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d90--color {
  color: rgba(0, 0, 0, 0.1); }

.hover-row--d90--bg {
  background: rgba(0, 0, 0, 0.1); }

.hover-row--l90--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l90--bg {
  background: rgba(255, 255, 255, 0.1); }

.hover-row--d100--color {
  color: rgba(0, 0, 0, 0.1); }

.hover-row--d100--bg {
  background: rgba(0, 0, 0, 0.1); }

.hover-row--l100--color {
  color: rgba(255, 255, 255, 0.1); }

.hover-row--l100--bg {
  background: rgba(255, 255, 255, 0.1); }

.support-01--color {
  color: #e0182d; }

.support-01--bg {
  background: #e0182d; }

.support-01--d10--color {
  color: #b21324; }

.support-01--d10--bg {
  background: #b21324; }

.support-01--l10--color {
  color: #eb4052; }

.support-01--l10--bg {
  background: #eb4052; }

.support-01--d20--color {
  color: #840e1a; }

.support-01--d20--bg {
  background: #840e1a; }

.support-01--l20--color {
  color: #f06e7c; }

.support-01--l20--bg {
  background: #f06e7c; }

.support-01--d30--color {
  color: #560911; }

.support-01--d30--bg {
  background: #560911; }

.support-01--l30--color {
  color: #f49da6; }

.support-01--l30--bg {
  background: #f49da6; }

.support-01--d40--color {
  color: #280408; }

.support-01--d40--bg {
  background: #280408; }

.support-01--l40--color {
  color: #f9cbd0; }

.support-01--l40--bg {
  background: #f9cbd0; }

.support-01--d50--color {
  color: black; }

.support-01--d50--bg {
  background: black; }

.support-01--l50--color {
  color: #fef9f9; }

.support-01--l50--bg {
  background: #fef9f9; }

.support-01--d60--color {
  color: black; }

.support-01--d60--bg {
  background: black; }

.support-01--l60--color {
  color: white; }

.support-01--l60--bg {
  background: white; }

.support-01--d70--color {
  color: black; }

.support-01--d70--bg {
  background: black; }

.support-01--l70--color {
  color: white; }

.support-01--l70--bg {
  background: white; }

.support-01--d80--color {
  color: black; }

.support-01--d80--bg {
  background: black; }

.support-01--l80--color {
  color: white; }

.support-01--l80--bg {
  background: white; }

.support-01--d90--color {
  color: black; }

.support-01--d90--bg {
  background: black; }

.support-01--l90--color {
  color: white; }

.support-01--l90--bg {
  background: white; }

.support-01--d100--color {
  color: black; }

.support-01--d100--bg {
  background: black; }

.support-01--l100--color {
  color: white; }

.support-01--l100--bg {
  background: white; }

.support-02--color {
  color: #5aa700; }

.support-02--bg {
  background: #5aa700; }

.support-02--d10--color {
  color: #3f7400; }

.support-02--d10--bg {
  background: #3f7400; }

.support-02--l10--color {
  color: #75da00; }

.support-02--l10--bg {
  background: #75da00; }

.support-02--d20--color {
  color: #234100; }

.support-02--d20--bg {
  background: #234100; }

.support-02--l20--color {
  color: #90ff0e; }

.support-02--l20--bg {
  background: #90ff0e; }

.support-02--d30--color {
  color: #080e00; }

.support-02--d30--bg {
  background: #080e00; }

.support-02--l30--color {
  color: #a7ff41; }

.support-02--l30--bg {
  background: #a7ff41; }

.support-02--d40--color {
  color: black; }

.support-02--d40--bg {
  background: black; }

.support-02--l40--color {
  color: #bfff74; }

.support-02--l40--bg {
  background: #bfff74; }

.support-02--d50--color {
  color: black; }

.support-02--d50--bg {
  background: black; }

.support-02--l50--color {
  color: #d6ffa7; }

.support-02--l50--bg {
  background: #d6ffa7; }

.support-02--d60--color {
  color: black; }

.support-02--d60--bg {
  background: black; }

.support-02--l60--color {
  color: #eeffda; }

.support-02--l60--bg {
  background: #eeffda; }

.support-02--d70--color {
  color: black; }

.support-02--d70--bg {
  background: black; }

.support-02--l70--color {
  color: white; }

.support-02--l70--bg {
  background: white; }

.support-02--d80--color {
  color: black; }

.support-02--d80--bg {
  background: black; }

.support-02--l80--color {
  color: white; }

.support-02--l80--bg {
  background: white; }

.support-02--d90--color {
  color: black; }

.support-02--d90--bg {
  background: black; }

.support-02--l90--color {
  color: white; }

.support-02--l90--bg {
  background: white; }

.support-02--d100--color {
  color: black; }

.support-02--d100--bg {
  background: black; }

.support-02--l100--color {
  color: white; }

.support-02--l100--bg {
  background: white; }

.support-03--color {
  color: #efc100; }

.support-03--bg {
  background: #efc100; }

.support-03--d10--color {
  color: #bc9800; }

.support-03--d10--bg {
  background: #bc9800; }

.support-03--l10--color {
  color: #ffd523; }

.support-03--l10--bg {
  background: #ffd523; }

.support-03--d20--color {
  color: #896f00; }

.support-03--d20--bg {
  background: #896f00; }

.support-03--l20--color {
  color: #ffde56; }

.support-03--l20--bg {
  background: #ffde56; }

.support-03--d30--color {
  color: #564500; }

.support-03--d30--bg {
  background: #564500; }

.support-03--l30--color {
  color: #ffe889; }

.support-03--l30--bg {
  background: #ffe889; }

.support-03--d40--color {
  color: #231c00; }

.support-03--d40--bg {
  background: #231c00; }

.support-03--l40--color {
  color: #fff2bc; }

.support-03--l40--bg {
  background: #fff2bc; }

.support-03--d50--color {
  color: black; }

.support-03--d50--bg {
  background: black; }

.support-03--l50--color {
  color: #fffcef; }

.support-03--l50--bg {
  background: #fffcef; }

.support-03--d60--color {
  color: black; }

.support-03--d60--bg {
  background: black; }

.support-03--l60--color {
  color: white; }

.support-03--l60--bg {
  background: white; }

.support-03--d70--color {
  color: black; }

.support-03--d70--bg {
  background: black; }

.support-03--l70--color {
  color: white; }

.support-03--l70--bg {
  background: white; }

.support-03--d80--color {
  color: black; }

.support-03--d80--bg {
  background: black; }

.support-03--l80--color {
  color: white; }

.support-03--l80--bg {
  background: white; }

.support-03--d90--color {
  color: black; }

.support-03--d90--bg {
  background: black; }

.support-03--l90--color {
  color: white; }

.support-03--l90--bg {
  background: white; }

.support-03--d100--color {
  color: black; }

.support-03--d100--bg {
  background: black; }

.support-03--l100--color {
  color: white; }

.support-03--l100--bg {
  background: white; }

.support-04--color {
  color: #5aaafa; }

.support-04--bg {
  background: #5aaafa; }

.support-04--d10--color {
  color: #2991f9; }

.support-04--d10--bg {
  background: #2991f9; }

.support-04--l10--color {
  color: #8cc4fc; }

.support-04--l10--bg {
  background: #8cc4fc; }

.support-04--d20--color {
  color: #0777e7; }

.support-04--d20--bg {
  background: #0777e7; }

.support-04--l20--color {
  color: #bdddfd; }

.support-04--l20--bg {
  background: #bdddfd; }

.support-04--d30--color {
  color: #065eb6; }

.support-04--d30--bg {
  background: #065eb6; }

.support-04--l30--color {
  color: #eff7ff; }

.support-04--l30--bg {
  background: #eff7ff; }

.support-04--d40--color {
  color: #044484; }

.support-04--d40--bg {
  background: #044484; }

.support-04--l40--color {
  color: white; }

.support-04--l40--bg {
  background: white; }

.support-04--d50--color {
  color: #032b53; }

.support-04--d50--bg {
  background: #032b53; }

.support-04--l50--color {
  color: white; }

.support-04--l50--bg {
  background: white; }

.support-04--d60--color {
  color: #011121; }

.support-04--d60--bg {
  background: #011121; }

.support-04--l60--color {
  color: white; }

.support-04--l60--bg {
  background: white; }

.support-04--d70--color {
  color: black; }

.support-04--d70--bg {
  background: black; }

.support-04--l70--color {
  color: white; }

.support-04--l70--bg {
  background: white; }

.support-04--d80--color {
  color: black; }

.support-04--d80--bg {
  background: black; }

.support-04--l80--color {
  color: white; }

.support-04--l80--bg {
  background: white; }

.support-04--d90--color {
  color: black; }

.support-04--d90--bg {
  background: black; }

.support-04--l90--color {
  color: white; }

.support-04--l90--bg {
  background: white; }

.support-04--d100--color {
  color: black; }

.support-04--d100--bg {
  background: black; }

.support-04--l100--color {
  color: white; }

.support-04--l100--bg {
  background: white; }

::selection {
  background: #41d6c3; }

.header-enabled-swan-top-bar:not(.is-mobile) {
  position: relative;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important; }

.header-enabled-swan-top-bar:not(.is-mobile) .right {
  position: absolute;
  top: calc(-40px + -6rem) !important;
  right: 0.5rem !important; }

.header-enabled-swan-top-bar:not(.is-mobile) .right .btn,
.header-enabled-swan-top-bar:not(.is-mobile) .right .btn-group {
  margin: 0.1rem;
  border-radius: 17.5px !important;
  min-width: 100px; }

.custom-item-icon {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  color: #3d70b2;
  border: 1px solid #d6d6d6;
  margin: auto; }

.actions-container .btn,
.actions-container .btn-group {
  margin: 0.1rem;
  border-radius: 1.1rem; }

@media only screen and (max-width: 768px) {
  .custom-item-icon {
    width: 70px;
    height: 70px; }
  .bottom-action-container {
    background-color: transparent !important;
    left: auto !important;
    top: auto !important;
    bottom: 42px !important;
    margin: 0 !important;
    padding: 0 !important;
    min-height: auto !important; } }

.form-control-upload {
  height: auto !important; }
  .form-control-upload ~ label {
    margin-top: 0.75rem;
    align-items: flex-start; }

.dropzone-upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden; }

.dropzone-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem; }

.dropzone-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 100%; }
  .dropzone-actions:empty {
    display: none;
    height: 0; }

.dropzone-file-input {
  display: none !important; }

.dropzone {
  height: 4rem;
  width: 100%;
  background-color: #fff;
  border: 2px dashed rgba(136, 151, 162, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .dropzone.highlight {
    background-color: #dfe3e6; }
  .dropzone.disabled {
    cursor: default;
    border: 2px dashed rgba(136, 151, 162, 0.3); }

.dropzone-actions {
  display: flex;
  align-items: center;
  justify-content: center; }

.stats-text-sm {
  font-size: 13px; }

.stats-text-black {
  color: #000; }

.PhoneInput {
  order: 0;
  flex: 1 0 70%;
  background-color: #f4f7fb; }

@media (max-width: 767.98px) {
  .PhoneInput {
    order: 1;
    margin-bottom: 0.0625rem; } }

.PhoneInput {
  padding: 0 0.75rem;
  height: 2rem;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px 0 0 #8897a2;
  display: flex; }
  .PhoneInput .PhoneInputCountry {
    align-self: stretch; }
  .PhoneInput .PhoneInputInput {
    align-self: stretch;
    height: 100%;
    border: none;
    background: transparent;
    font-size: 0.815rem; }
    .PhoneInput .PhoneInputInput:focus-visible {
      outline: none; }

.PhoneInput--focus {
  box-shadow: 0 2px 0 0 #3d70b2;
  transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .PhoneInput--focus ~ label {
    color: #3d70b2; }

.PhoneInputCountrySelect:focus + .PhoneInputCountrySelectArrow,
.react-phone-number-input__country.rrui__select--focus .rrui__select__arrow {
  color: #3d70b2 !important; }

.swan-main {
  padding: 0; }
  @media (min-width: 768px) {
    .swan-main {
      padding: 0; } }
  .swan-main .module {
    padding: 0; }
    @media (min-width: 768px) {
      .swan-main .module {
        padding: 0; } }
    .swan-main .module .module-content {
      padding: 0;
      background: white; }
      .swan-main .module .module-content > .row:first-child {
        margin-left: 0;
        margin-right: 0; }
      @media (min-width: 768px) {
        .swan-main .module .module-content {
          padding: 1rem; } }
    .swan-main .module .all-modules-dashboard-container {
      background: #f1f4f9 !important;
      min-height: 100vh; }

html[data-layout="mobile"] .swan-main {
  padding: 0.5rem; }
  html[data-layout="mobile"] .swan-main .module .module-content {
    background: #f6f6f6; }

.sidebar {
  --cui-sidebar-width: 16rem;
  position: relative;
  display: flex;
  flex: 0 0 var(--cui-sidebar-width);
  flex-direction: column;
  order: -1;
  width: var(--cui-sidebar-width);
  padding: 0 0;
  color: var(--cui-sidebar-color, #152935);
  background: var(--cui-sidebar-bg, #395998);
  border-right: var(--cui-sidebar-border-width, 0) solid var(--cui-sidebar-border-color, transparent);
  box-shadow: none;
  transition: margin-left 0.15s, margin-right 0.15s, box-shadow 0.075s, transform 0.15s, width 0.15s, z-index 0s ease 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .sidebar {
      transition: none; } }
  .sidebar:not(.sidebar-end) {
    margin-left: 0; }
    .sidebar:not(.sidebar-end) ~ * {
      --cui-sidebar-occupy-start: 16rem; }
  .sidebar.sidebar-end {
    order: 99;
    margin-right: 0; }
    .sidebar.sidebar-end ~ * {
      --cui-sidebar-occupy-end: 16rem; }
  @media (min-width: 768px) {
    .sidebar.hide:not(.sidebar-end) {
      margin-left: calc(-1 * var(--cui-sidebar-width)); }
      .sidebar.hide:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 0; }
    .sidebar.hide.sidebar-end {
      margin-right: calc(-1 * var(--cui-sidebar-width)); }
      .sidebar.hide.sidebar-end ~ * {
        --cui-sidebar-occupy-end: 0; } }
  .sidebar[class*="bg-"] {
    border-color: rgba(0, 0, 0, 0.1); }
  .sidebar.sidebar-sm {
    --cui-sidebar-width: 12rem; }
    .sidebar.sidebar-sm:not(.sidebar-end) ~ * {
      --cui-sidebar-occupy-start: 12rem; }
    .sidebar.sidebar-sm.sidebar-end ~ * {
      --cui-sidebar-occupy-end: 12rem; }
  .sidebar.sidebar-lg {
    --cui-sidebar-width: 20rem; }
    .sidebar.sidebar-lg:not(.sidebar-end) ~ * {
      --cui-sidebar-occupy-start: 20rem; }
    .sidebar.sidebar-lg.sidebar-end ~ * {
      --cui-sidebar-occupy-end: 20rem; }
  .sidebar.sidebar-xl {
    --cui-sidebar-width: 24rem; }
    .sidebar.sidebar-xl:not(.sidebar-end) ~ * {
      --cui-sidebar-occupy-start: 24rem; }
    .sidebar.sidebar-xl.sidebar-end ~ * {
      --cui-sidebar-occupy-end: 24rem; }
  @media (min-width: 768px) {
    .sidebar.sidebar-fixed {
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 1030;
      box-shadow: 0 2px 2px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 3px 1px -2px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 1px 5px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .20); }
      .sidebar.sidebar-fixed:not([class*="border"]) {
        border: 0; }
      .sidebar.sidebar-fixed:not(.sidebar-end) {
        left: 0; }
      .sidebar.sidebar-fixed.sidebar-end {
        right: 0; } }
  @media (min-width: 768px) {
    .sidebar.sidebar-sticky {
      position: sticky;
      top: 0;
      height: 100vh; } }
  .sidebar.sidebar-overlaid {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1032; }
    .sidebar.sidebar-overlaid:not(.hide) {
      box-shadow: 0 3px 4px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 3px 3px -2px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 1px 8px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .20); }
      .sidebar.sidebar-overlaid:not(.hide):not([class*="border"]) {
        border: 0; }
    .sidebar.sidebar-overlaid:not(.sidebar-end) {
      left: 0; }
      .sidebar.sidebar-overlaid:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 0; }
    .sidebar.sidebar-overlaid.sidebar-end {
      right: 0; }
      .sidebar.sidebar-overlaid.sidebar-end ~ * {
        --cui-sidebar-occupy-end: 0; }
  @media (max-width: 767.98px) {
    .sidebar {
      --cui-is-mobile: true;
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 1031; }
      .sidebar:not(.sidebar-end) {
        left: 0; }
        .sidebar:not(.sidebar-end) ~ * {
          --cui-sidebar-occupy-start: 0 !important; }
        .sidebar:not(.sidebar-end):not(.show) {
          margin-left: calc(-1 * var(--cui-sidebar-width)); }
      .sidebar.sidebar-end {
        right: 0; }
        .sidebar.sidebar-end ~ * {
          --cui-sidebar-occupy-end: 0 !important; }
        .sidebar.sidebar-end:not(.show) {
          margin-right: calc(-1 * var(--cui-sidebar-width)); } }

.sidebar-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  color: var(--cui-sidebar-color, #152935);
  background: transparent;
  border: 0; }
  .sidebar-close:hover {
    text-decoration: none; }
  .sidebar-close:focus {
    outline: 0; }

.sidebar-brand {
  display: flex;
  flex: 0 0 4rem;
  align-items: center;
  justify-content: center;
  color: var(--cui-sidebar-brand-color, rgba(255, 255, 255, 0.87));
  background: var(--cui-sidebar-brand-bg, #395998); }
  .sidebar-brand .sidebar-brand-narrow {
    display: none; }

.sidebar-header {
  flex: 0 0 4rem;
  padding: 0.75rem 1rem;
  text-align: center;
  background: var(--cui-sidebar-header-bg, rgba(0, 0, 0, 0.2));
  transition: height 0.15s, padding 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .sidebar-header {
      transition: none; } }
  .sidebar-header .nav-link {
    display: flex;
    align-items: center;
    min-height: 4rem; }

.sidebar-footer {
  flex: 0 0 auto;
  padding: 0.75rem 1rem;
  background: var(--cui-sidebar-footer-bg, #395998);
  transition: height 0.15s, padding 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .sidebar-footer {
      transition: none; } }

.sidebar-toggler {
  display: flex;
  flex: 0 0 3rem;
  justify-content: flex-end;
  width: inherit;
  padding: 0;
  cursor: pointer;
  background-color: var(--cui-sidebar-toggler-bg, #395998);
  border: 0; }
  @media (max-width: 767.98px) {
    .sidebar-toggler {
      display: none; } }
  .sidebar-toggler::before {
    display: block;
    width: 4rem;
    height: 3rem;
    content: "";
    background-image: var(--cui-sidebar-toggler-indicator, url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%236c757d' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.75rem;
    transition: transform 0.15s; }
    @media (prefers-reduced-motion: reduce) {
      .sidebar-toggler::before {
        transition: none; } }
  .sidebar-toggler:focus {
    outline: 0; }
  .sidebar-toggler:hover {
    background-color: var(--cui-sidebar-toggler-hover-bg, #1f4083); }
    .sidebar-toggler:hover::before {
      background-image: var(--cui-sidebar-toggler-indicator-hover, url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba%28255, 255, 255, 0.87%29' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E")); }
  .sidebar-end .sidebar-toggler {
    justify-content: flex-start; }
    .sidebar-end .sidebar-toggler::before {
      transform: rotate(-180deg); }

@media (max-width: 767.98px) {
  .sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1030;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    transition: opacity 0.15s linear; } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .sidebar-backdrop {
      transition: none; } }

@media (max-width: 767.98px) {
    .sidebar-backdrop.fade {
      opacity: 0; }
    .sidebar-backdrop.show {
      opacity: 0.5; } }

.sidebar-light {
  --cui-sidebar-color: #212529;
  --cui-sidebar-bg: #fff;
  --cui-sidebar-border-width: 0;
  --cui-sidebar-border-color: transparent;
  --cui-sidebar-brand-color: #fff;
  --cui-sidebar-brand-bg: #007bff;
  --cui-sidebar-header-bg: rgba(0, 0, 0, 0.2);
  --cui-sidebar-nav-title-color: rgba(44, 56, 74, 0.681);
  --cui-sidebar-nav-link-color: rgba(44, 56, 74, 0.681);
  --cui-sidebar-nav-link-bg: transparent;
  --cui-sidebar-nav-link-icon-color: rgba(44, 56, 74, 0.681);
  --cui-sidebar-nav-link-hover-color: rgba(44, 56, 74, 0.95);
  --cui-sidebar-nav-link-hover-bg: #007bff;
  --cui-sidebar-nav-link-hover-icon-color: rgba(44, 56, 74, 0.95);
  --cui-sidebar-nav-link-active-color: rgba(44, 56, 74, 0.95);
  --cui-sidebar-nav-link-active-bg: rgba(255, 255, 255, 0.05);
  --cui-sidebar-nav-link-active-icon-color: rgba(44, 56, 74, 0.95);
  --cui-sidebar-nav-link-disabled-color: rgba(44, 56, 74, 0.38);
  --cui-sidebar-nav-link-disabled-icon-color: rgba(44, 56, 74, 0.681);
  --cui-sidebar-nav-group-bg: rgba(0, 0, 0, 0.05);
  --cui-sidebar-nav-group-toggle-show-color: rgba(44, 56, 74, 0.681);
  --cui-sidebar-nav-group-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(44, 56, 74, 0.681)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  --cui-sidebar-nav-group-indicator-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(44, 56, 74, 0.95)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  --cui-sidebar-footer-bg: rgba(0, 0, 0, 0.1);
  --cui-sidebar-toggler-bg: rgba(0, 0, 0, 0.1);
  --cui-sidebar-toggler-hover-bg: rgba(0, 0, 0, 0.2);
  --cui-sidebar-toggler-indicator: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(44, 56, 74, 0.681)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  --cui-sidebar-toggler-indicator-hover: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(44, 56, 74, 0.95)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"); }

.sidebar-nav {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none; }
  .sidebar-nav .nav-title {
    padding: 0.75rem 1rem;
    margin-top: 1rem;
    font-size: 80%;
    font-weight: 700;
    color: var(--cui-sidebar-nav-title-color, #ffffff);
    text-transform: uppercase;
    transition: height 0.15s, margin 0.15s; }
    @media (prefers-reduced-motion: reduce) {
      .sidebar-nav .nav-title {
        transition: none; } }
  .sidebar-nav .nav-link {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0.8445rem 1rem;
    color: var(--cui-sidebar-nav-link-color, #ffffff);
    text-decoration: none;
    white-space: nowrap;
    background: var(--cui-sidebar-nav-link-bg, transparent);
    transition: background 0.15s ease, color 0.15s ease; }
    @media (prefers-reduced-motion: reduce) {
      .sidebar-nav .nav-link {
        transition: none; } }
    .sidebar-nav .nav-link.active {
      color: var(--cui-sidebar-nav-link-active-color, rgba(255, 255, 255, 0.87));
      background: var(--cui-sidebar-nav-link-active-bg, rgba(255, 255, 255, 0.05)); }
      .sidebar-nav .nav-link.active .nav-icon {
        color: var(--cui-sidebar-nav-link-active-icon-color, rgba(255, 255, 255, 0.87)); }
    .sidebar-nav .nav-link.disabled {
      color: var(--cui-sidebar-nav-link-disabled-color, rgba(255, 255, 255, 0.38));
      pointer-events: none;
      cursor: not-allowed;
      background: transparent; }
      .sidebar-nav .nav-link.disabled .nav-icon {
        color: var(--cui-sidebar-nav-link-disabled-icon-color, rgba(255, 255, 255, 0.1)); }
      .sidebar-nav .nav-link.disabled:hover {
        color: var(--cui-sidebar-nav-link-disabled-color, rgba(255, 255, 255, 0.38)); }
        .sidebar-nav .nav-link.disabled:hover .nav-icon {
          color: var(--cui-sidebar-nav-link-disabled-icon-color, rgba(255, 255, 255, 0.1)); }
        .sidebar-nav .nav-link.disabled:hover.nav-dropdown-toggle::after {
          background-image: var(--cui-sidebar-nav-group-indicator-hover, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23cdced0'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")); }
    @media (hover: hover), (-ms-high-contrast: none) {
      .sidebar-nav .nav-link:hover {
        color: var(--cui-sidebar-nav-link-hover-color, rgba(255, 255, 255, 0.87));
        text-decoration: none;
        background: var(--cui-sidebar-nav-link-hover-bg, #506ea4); }
        .sidebar-nav .nav-link:hover .nav-icon {
          color: var(--cui-sidebar-nav-link-hover-icon-color, rgba(255, 255, 255, 0.87)); }
        .sidebar-nav .nav-link:hover.nav-group-toggle::after {
          background-image: var(--cui-sidebar-nav-group-indicator-hover, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23cdced0'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")); } }
  .sidebar-nav .nav-icon {
    flex: 0 0 4rem;
    height: 1.25rem;
    font-size: 1.25rem;
    color: var(--cui-sidebar-nav-link-icon-color, rgba(255, 255, 255, 0.1));
    text-align: center;
    fill: currentColor;
    transition: inherit; }
    @media (prefers-reduced-motion: reduce) {
      .sidebar-nav .nav-icon {
        transition: none; } }
    .sidebar-nav .nav-icon:first-child {
      margin-left: -1rem; }
  .sidebar-nav .nav-group {
    position: relative;
    transition: background 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .sidebar-nav .nav-group {
        transition: none; } }
    .sidebar-nav .nav-group .nav-group-items {
      overflow: hidden;
      transition: height 0.15s ease; }
      @media (prefers-reduced-motion: reduce) {
        .sidebar-nav .nav-group .nav-group-items {
          transition: none; } }
    .sidebar-nav .nav-group:not(.show) .nav-group-items {
      display: none; }
    .sidebar-nav .nav-group.show {
      background: var(--cui-sidebar-nav-group-bg, #395998); }
      .sidebar-nav .nav-group.show .nav-group-toggle {
        color: var(--cui-sidebar-nav-group-toggle-show-color, #ffffff); }
      .sidebar-nav .nav-group.show > .nav-group-toggle::after {
        transform: rotate(180deg); }
      .sidebar-nav .nav-group.show + .show {
        margin-top: 1px; }
  .sidebar-nav .nav-group-toggle {
    cursor: pointer; }
    .sidebar-nav .nav-group-toggle::after {
      display: block;
      flex: 0 12px;
      height: 12px;
      margin-left: auto;
      content: "";
      background-image: var(--cui-sidebar-nav-group-indicator, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"));
      background-repeat: no-repeat;
      background-position: center;
      transition: transform 0.15s; }
      @media (prefers-reduced-motion: reduce) {
        .sidebar-nav .nav-group-toggle::after {
          transition: none; } }
  .sidebar-nav .nav-group-items {
    padding: 0;
    list-style: none; }
    .sidebar-nav .nav-group-items .nav-link {
      padding-left: 4rem; }
      .sidebar-nav .nav-group-items .nav-link .nav-icon {
        margin-left: -4rem; }
  .sidebar-nav.compact .nav-link,
  .sidebar-nav .compact .nav-link {
    padding-top: 0.42225rem;
    padding-bottom: 0.42225rem; }

@media (min-width: 768px) {
  .sidebar-narrow, .sidebar-narrow-unfoldable:not(:hover) {
    z-index: 1031;
    flex: 0 0 4rem;
    width: 4rem;
    padding-bottom: 3rem;
    overflow: visible; }
    .sidebar-fixed.sidebar-narrow, .sidebar-fixed.sidebar-narrow-unfoldable:not(:hover) {
      z-index: 1031;
      width: 4rem; }
    .sidebar-narrow .sidebar-brand-full, .sidebar-narrow-unfoldable:not(:hover) .sidebar-brand-full {
      display: none; }
    .sidebar-narrow .sidebar-brand-narrow, .sidebar-narrow-unfoldable:not(:hover) .sidebar-brand-narrow {
      display: block; }
    .sidebar-narrow .d-narrow-none, .sidebar-narrow-unfoldable:not(:hover) .d-narrow-none,
    .sidebar-narrow .nav-label,
    .sidebar-narrow-unfoldable:not(:hover) .nav-label,
    .sidebar-narrow .nav-title,
    .sidebar-narrow-unfoldable:not(:hover) .nav-title,
    .sidebar-narrow .nav-group-items,
    .sidebar-narrow-unfoldable:not(:hover) .nav-group-items,
    .sidebar-narrow .nav-group.show .nav-group-items,
    .sidebar-narrow-unfoldable:not(:hover) .nav-group.show .nav-group-items,
    .sidebar-narrow .sidebar-footer,
    .sidebar-narrow-unfoldable:not(:hover) .sidebar-footer,
    .sidebar-narrow .sidebar-form,
    .sidebar-narrow-unfoldable:not(:hover) .sidebar-form,
    .sidebar-narrow .sidebar-header,
    .sidebar-narrow-unfoldable:not(:hover) .sidebar-header {
      height: 0 !important;
      padding: 0;
      margin: 0;
      visibility: hidden;
      opacity: 0; }
    .sidebar-narrow .sidebar-toggler, .sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler {
      position: fixed;
      bottom: 0; }
      .sidebar-narrow .sidebar-toggler::before, .sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler::before {
        transform: rotate(-180deg); }
    .sidebar-end.sidebar-narrow .sidebar-toggler::before, .sidebar-end.sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler::before {
      transform: rotate(0deg); } }

.sidebar-narrow:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 4rem; }

.sidebar-narrow.sidebar-end ~ * {
  --cui-sidebar-occupy-end: 4rem; }

.sidebar-narrow-unfoldable {
  position: fixed;
  z-index: 1031; }
  .sidebar-narrow-unfoldable:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 4rem; }
  .sidebar-narrow-unfoldable.sidebar-end ~ * {
    --cui-sidebar-occupy-end: 4rem; }
  .sidebar-narrow-unfoldable:hover .sidebar-toggler::before {
    transform: rotate(-180deg); }
  .sidebar-narrow-unfoldable:hover.sidebar-end .sidebar-toggler::before {
    transform: rotate(0deg); }

.sidebar:not(.show).sidebar-self-hiding.sidebar-narrow:not(.sidebar-end), .sidebar:not(.show).sidebar-self-hiding.sidebar-narrow-unfoldable:not(.sidebar-end) {
  margin-left: -4rem; }

.sidebar:not(.show).sidebar-self-hiding.sidebar-narrow.sidebar-end, .sidebar:not(.show).sidebar-self-hiding.sidebar-narrow-unfoldable.sidebar-end {
  margin-right: -4rem; }

@media (max-width: 575.98px) {
  .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow:not(.sidebar-end), .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-left: -4rem; }
  .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow.sidebar-end, .sidebar:not(.show).sidebar-self-hiding-sm.sidebar-narrow-unfoldable.sidebar-end {
    margin-right: -4rem; } }

@media (max-width: 767.98px) {
  .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow:not(.sidebar-end), .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-left: -4rem; }
  .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow.sidebar-end, .sidebar:not(.show).sidebar-self-hiding-md.sidebar-narrow-unfoldable.sidebar-end {
    margin-right: -4rem; } }

@media (max-width: 991.98px) {
  .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow:not(.sidebar-end), .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-left: -4rem; }
  .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow.sidebar-end, .sidebar:not(.show).sidebar-self-hiding-lg.sidebar-narrow-unfoldable.sidebar-end {
    margin-right: -4rem; } }

@media (max-width: 1199.98px) {
  .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow:not(.sidebar-end), .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow-unfoldable:not(.sidebar-end) {
    margin-left: -4rem; }
  .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow.sidebar-end, .sidebar:not(.show).sidebar-self-hiding-xl.sidebar-narrow-unfoldable.sidebar-end {
    margin-right: -4rem; } }

.sidebar-nav-item--group a {
  white-space: initial !important; }

.sidebar:not(.sidebar-narrow) {
  z-index: 199; }

@media (max-width: 767.98px) {
  .sidebar:not(.sidebar-end):not(.show):not(.sidebar-narrow) {
    margin-left: 0; }
  .sidebar-backdrop {
    display: none; }
  .global-navigation.global-navigation-disabled {
    display: none; }
  .sidebar.sidebar-narrow {
    display: none; } }
