@import "../../global/colours/mixins";

fieldset {
  &.form-group {
    display: block;
    box-sizing: border-box;
    box-shadow: 0 1px 0 0 $ui-04;
    border-bottom: 1px solid transparent;
    // display: flex;
    // flex-direction: row;
    .control-label {
      display: inline-block !important;
      width: 30%;
      height: 2rem;
      //   flex: 1 0 30% !important;
      label {
        border-bottom: none;
        box-shadow: none;
      }
    }

    .control-wrapper {
      display: inline-block !important;
      width: 65%;
      height: 2rem;
      //   float: right;
      //   flex: 1 0 70% !important;
    }
  }
}

.form-check {
  position: relative;
  //   display: flex;
  display: inline-block;
  align-items: center;
  margin-top: 0.5rem;
  padding-left: 0px;

  label {
    box-shadow: none;
    border-bottom: none;

    display: flex;
    // display: inline-block;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem;

    .radio-appearance {
      background-color: #fff;
      border-radius: 50%;
      border: 2px solid #5a6872;
      flex-shrink: 0;
      height: 1.125rem;
      width: 1.125rem;
      margin-right: 0.5rem;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    visibility: visible;
    white-space: nowrap;
    visibility: unset;

    & + label {
      @include font-size("14");
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      padding-left: 1.5rem;
      min-height: 1rem;

      &::before {
        box-sizing: border-box;
        content: "";
        position: absolute;
        left: 0;
        top: calc(50% - 9px);
        height: 1.125rem;
        width: 1.125rem;
        border: 2px solid #5a6872;
        background-color: #fff;
      }

      &::after {
        box-sizing: border-box;
        content: "";
        width: 9px;
        height: 5px;
        background: none;
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        -webkit-transform: scale(0) rotate(-45deg);
        transform: scale(0) rotate(-45deg);
        position: absolute;
        left: 0.3125rem;
        top: 50%;
        margin-top: -0.1875rem;
      }
    }

    &:checked ~ label {
      &::before {
        background-color: $brand-01;
        border-color: $brand-01;
      }

      &::after {
        opacity: 1;
        -webkit-transform: scale(1) rotate(-45deg);
        transform: scale(1) rotate(-45deg);
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    visibility: visible;
    white-space: nowrap;
    visibility: unset;

    &:checked + label .radio-appearance {
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: $brand-01;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: $brand-01;
      }
    }
  }
}
