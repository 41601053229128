@import "../global/colours/mixins";

.swan-datepicker {
  .form-group {
    display: flex;
    flex-direction: row;

    label {
      order: 1;
    }

    .SingleDatePicker {
      order: 2;
      flex-basis: 70%;

      box-shadow: 0 1px 0 0 $ui-04;

      .DateInput_input__focused {
        border-bottom-color: transparent;
        outline: none;
        box-shadow: 0 2px 0 0 $brand-01;
        transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      .SingleDatePickerInput__withBorder {
        border: none;
      }

      .CalendarDay__selected,
      .CalendarDay__selected:active,
      .CalendarDay__selected:hover {
        background: $brand-01;
        border: 1px double $brand-01;
      }

      .DayPickerKeyboardShortcuts_show__bottomRight {
        display: none;
      }

      .DayPickerKeyboardShortcuts_show__bottomRight::before {
        border-right: 33px solid $brand-01;
      }

      .DateInput {
        input {
          border-color: transparent;
        }
      }
    }
  }
  .CalendarDay {
    vertical-align: middle;
  }
}

.datetime-picker > div,
.datetime-picker > div > div > div {
  background: transparent !important;
  border: none !important;
}
