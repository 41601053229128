$spacing-baseline: 1rem !default;

$spacing-4xs: $spacing-baseline * 0.0625 !default;
$spacing-3xs: $spacing-baseline * 0.125 !default;
$spacing-2xs: $spacing-baseline * 0.25 !default;
$spacing-xs: $spacing-baseline * 0.5 !default;
$spacing-sm: $spacing-baseline * 0.75 !default;
$spacing-md: $spacing-baseline !default;
$spacing-lg: $spacing-baseline * 1.5 !default;
$spacing-xl: $spacing-baseline * 2 !default;
$spacing-2xl: $spacing-baseline * 2.5 !default;
$spacing-3xl: $spacing-baseline * 3 !default;

$layout-2xs: $spacing-baseline !default;
$layout-xs: $spacing-baseline * 1.5 !default;
$layout-sm: $spacing-baseline * 2 !default;
$layout-md: $spacing-baseline * 3 !default;
$layout-lg: $spacing-baseline * 4 !default;
$layout-xl: $spacing-baseline * 6 !default;
$layout-2xl: $spacing-baseline * 10 !default;

$spacing-nil: 0 !default;

@mixin min-width-spacing-break-point(
  $width,
  $breaking-space,
  $padding-default: 0
) {
  // padding: $padding-default;
  padding: 0;
  @media (min-width:  #{$width}) {
    padding: $breaking-space;
  }
}

//   Size in px based on 16px base
//
//   Spacing Scale
//   ==========================================
//   4xs  ||  1px
//   3xs  ||  2px
//   2xs  ||  4px
//   xs   ||  8px
//   sm   ||  12px
//   md   ||  16px
//   lg   ||  24px
//   xl   ||  32px
//   2xl  ||  40px
//   3xl  ||  48px
//   ==========================================
//
//   Layout Scale
//   ==========================================
//   2xs  ||  16px
//   xs   ||  24px
//   sm   ||  32px
//   md   ||  48px
//   lg   ||  64px
//   xl   ||  96px
//   2xl  ||  160px
//   ==========================================
