@import "../../global/vars";
@import "../../global/spacing";

.form-group,
.#{$prefix}-form-group {
  textarea,
  // .quill,
  .wysiwyg {
    padding: $spacing-md;
    resize: vertical;
    height: auto;

    & ~ label {
      align-items: flex-start;
      padding-top: $spacing-md;
    }
  }

  .form-control {
    &.quill,
    &.wysiwyg {
      height: 100%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .form-group,
  .#{$prefix}-form-group {
    textarea {
      padding: $spacing-xs;

      & ~ label {
        padding-top: 0;
      }
    }
  }
}
