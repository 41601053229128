@import "../../global/spacing";
@import "../../global/colours";
.navbar-light {
  // background: $ui-03 !important;
}

.sticky-top-navbar {
  // margin: -2 * $spacing-md;
  // margin-bottom: $spacing-md;
  // padding: 10px (2 * $spacing-md) !important;
  // background: $ui-03 !important;
}

.header-enabled-swan-top-bar:not(.is-mobile) {
  position: relative;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
}
.header-enabled-swan-top-bar:not(.is-mobile) .right {
  position: absolute;
  top: calc(-40px + -6rem) !important;
  right: 0.5rem !important;
}

.header-enabled-swan-top-bar:not(.is-mobile) .right .btn,
.header-enabled-swan-top-bar:not(.is-mobile) .right .btn-group {
  margin: 0.1rem;
  border-radius: 17.5px !important;
  min-width: 100px;
}

.custom-item-icon {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  color: #3d70b2;
  // background-color: #3d70b2;
  border: 1px solid #d6d6d6;
  margin: auto;
}

.actions-container {
  // background-color: transparent !important;
  .btn,
  .btn-group {
    margin: 0.1rem;
    border-radius: 1.1rem;
  }
}

// .sticky-top-navbar .right .btn,
// .sticky-top-navbar .right .btn-group {
//   margin: .1rem;
//   border-radius: 17.5px !important;
//   width: 100px;
// }

// .sticky-top-navbar .right .btn-group .btn {
//   background: #3b5a92;
//   border-color: #3b5a92 !important;
// }

@media only screen and (max-width: 768px) {
  .custom-item-icon {
    width: 70px;
    height: 70px;
  }

  .bottom-action-container {
    background-color: transparent !important;
    left: auto !important;
    top: auto !important;
    bottom: 42px !important;
    margin: 0 !important;
    padding: 0 !important;
    min-height: auto !important;
  }
}
