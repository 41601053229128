@import "../../global/colours/mixins";
@import "../../global/typography/mixins";

.nav-tabs {
  border: none;
  border-bottom: 1px solid $ui-03;
  margin-bottom: 1rem;
  margin-top: 0.5rem;

  .nav-item {
    cursor: pointer;
    margin-bottom: 0;
    margin-right: 5px;

    .nav-link {
      border: none;
      border-radius: 0;
      @include font-size("13.5");
      // font-weight: bold;
      text-transform: uppercase;
      background: transparent;

      &.active {
        box-shadow: 0 2px 0 0 $brand-01;
        transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
        color: $brand-01;
      }

      &:hover {
        box-shadow: 0 2px 0 0 $brand-02;
        transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }

    a.tab-link {
      .icon {
        display: none;
      }
    }
  }

  &.flex-column {
    background: $ui-03;
    margin-top: 0;
    box-shadow: -2px 0 0 0 $ui-03;

    .nav-item {
      margin-right: 0;
      // background-color: $ui-02;
      .nav-link {
        padding: 0.8rem 1rem;

        &.active {
          background-color: $ui-01;
          box-shadow: -2px 0 0 0 $brand-01;
        }

        &:hover {
          box-shadow: -2px 0 0 0 $brand-02;
        }
      }
    }
  }
  + div[direction^="vertical"] {
    .tab-content {
      padding-left: 1rem;
    }
  }
}

.mobile-navigator {
  display: none;
}

html[data-layout="mobile"] {
  .swan-main {
    .tabs-nav:not(.tabs-nav-first-of-type) {
      li {
        a {
          padding: 0.5rem 0.5rem;
        }
      }
    }
    .mobile-navigator {
      display: none;
    }
    .tabs-wrapper-first-of-type {
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      overflow: auto;

      & > .tabs-nav {
        flex-wrap: nowrap;
        white-space: nowrap;
        position: fixed;
        height: auto;
        left: 0;
        bottom: 0;
        right: 0;
        background: #f4f5f7;
        padding: 0 30px;
        margin: 0;
        z-index: 198;

        display: flex;
        flex-direction: row;
        border-bottom: none;
        // box-shadow: inset 0px -4px 12px -10px ;
        // box-shadow: inset 0 2px 1px -1px rgba(50, 50, 50, 0.75);
        box-shadow: inset 0 2px 1px -1px rgba(237, 227, 230, 1);
        overflow-y: scroll;

        & > li {
          flex: 1;
          text-align: center;
          margin: 0;
          padding: 0 10px;

          &:first-child {
            // padding-left: 30px;
          }

          &:last-child {
            padding-right: 30px;
          }
          a.label-only {
            font-size: 0.9rem;
            padding: 1rem;
            font-weight: 900;
          }
          a:not(.active) {
            .icon {
              color: #7d7c7c;
            }
          }
          a:not(.label-only) {
            box-shadow: none;
            padding: 0.3rem;
            font-weight: 900;
            font-size: 0.7rem;

            &.tab-link {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
              .icon {
                display: flex;
                width: 21px;
                height: 21px;
                & > svg {
                  height: 100%;
                  width: 100%;
                  display: flex;
                }
              }

              .label {
                padding-top: 0.4rem;
                font-size: 0.6rem;
              }
            }
          }
        }

        & + .mobile-navigator {
          display: block !important;
          height: 46px;
          left: 0;
          bottom: -46px;
          right: 0;
          position: fixed;
          z-index: 199;

          .arrows {
            position: relative;
            height: 100%;
            .arrow {
              position: absolute;
              justify-content: center;
              align-items: center;
              bottom: 46px;
              height: 100%;
              width: 30px;
              display: flex;
              background: #f4f5f7;
              justify-content: center;
              color: #737373;
            }
            .left-arrow {
              left: 0;
            }

            .right-arrow {
              right: 0;
            }
          }
        }
      }
      & > .tabs-nav-compact {
        padding: 0;

        li {
          &:last-child {
            padding: 0;
          }
        }
        & + .mobile-navigator {
          display: none !important;
        }
      }

      .tabs-content {
        overflow: auto;
        box-shadow: inset 0px -4px 12px -10px rgb(211, 211, 211);
        height: calc(100vh - 150px);

        // .tabs-wrapper {
        //   all: inherit;

        //   .tabs-content {
        //     all: inherit;
        //   }
        // }
      }
    }
  }
}
