@import "vars";
@import "import-once";
@import "typography/mixins";
@import "typography/reset";
@import "snippets/all-initials";

@mixin css-helpers {
  .#{$prefix}--assistive-text,
  .#{$prefix}--visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    visibility: visible;
    white-space: nowrap;
  }

  .#{$prefix}--body {
    @include reset;
    @include font-family;
    color: $text-01;
    background-color: $ui-02;
    line-height: 1;
  }
}

@include exports("css--helpers") {
  @if global-variable-exists("css--helpers") == false or $css--helpers == true {
    @include css-helpers;
  }
}

@include all-initials;

@mixin borderless {
  border: none !important;
  border-radius: 0 !important;
}

@mixin clear-bg {
  background: transparent !important;
}

.borderless {
  @include borderless;
}

.clear-bg {
  @include clear-bg;
}

.first-child-fullwidth {
  & > *:first-child {
    width: 100% !important;
  }
}

.fa-spin {
  animation: fa-spin 0.45s infinite linear;
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
