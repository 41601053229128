@import "import-once";
@import "typography/mixins";
@import "typography/reset";
@import "spacing";

@mixin css-body {
  body {
    @include reset;
    @include font-family;
    color: $text-01;
    background-color: $ui-01;
    line-height: 1;
    font-size: $base-font-size;
  }
}

@include media-breakpoint-down(sm) {
}

html[data-layout="mobile"] {
  body,
  .modal-body {
    background-color: $ui-06;

    img {
      width: 100%;
    }
  }
}

@include exports("css--body") {
  @if global-variable-exists("css--body") == false or $css--body == true {
    @include css-body;
  }
}

h1 {
  margin-bottom: $spacing-md;
}
h2 {
  margin-bottom: $spacing-sm;
}
