@import "../../global/vars";
@import "../../global/colours/mixins";
@import "../../global/typography/mixins";
@import "../../global/spacing";
@import "../../global/snippets/flex";

@import "form_group";

// .#{$prefix}

@include swan-form-group {
  label {
    // flex
    @include swan-form-label-default;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // border and spacing
    border-bottom: 1px solid transparent;
    box-shadow: 0 1px 0 0 rgba($ui-04, 0.3);
    margin-bottom: $spacing-nil;
    padding-left: $spacing-2xs;
    // typography
    @include font-size("13");
    font-weight: map-get($font-weights, "SemiBold");
  }

  // @todo: form-control mixin
  .form-control {
    @include swan-form-control-default;

    &.no-label {
      flex: 1 0 $input-control-100-percent;
    }

    &.form-control-checkbox {
      display: flex;
      flex-direction: column;
      // height: auto !important;

      .form-check {
        flex: 1;
        border-bottom: none;
        box-shadow: none;
      }

      & ~ label {
        // margin-top: 0.5rem;
        // align-items: flex-start;
      }
    }

    &.form-control-readonly {
      background-color: transparent;
      // line-height: $layout-md !important;
      &:not(.wysiwyg-readonly) {
        line-height: 2rem !important;
      }
      &.wysiwyg-readonly {
        min-height: $layout-xl;
        overflow: auto;
        display: -webkit-box !important;
        -webkit-line-clamp: 4 !important;
        -webkit-box-orient: vertical !important;
      }
      white-space: pre-wrap;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .checkbox-label-readonly {
        margin-bottom: $spacing-nil;
        padding-left: $spacing-2xs;
        // typography
        @include font-size("13");
        font-weight: map-get($font-weights, "SemiBold");
      }
    }

    @include font-size("13");
    color: $text-01;
    // background-color: $field-01;
    background-color: rgba($field-01, 0.6);
    border-bottom: 1px solid transparent;
    box-shadow: 0 1px 0 0 rgba($ui-04, 0.3);

    &:not(textarea):not(.quill):not(.wysiwyg) {
      @include swan-form-control-default--spacing;
      @include swan-form-control-default--height;
    }

    & [type="number"] {
      // padding-right: $spacing-xl;
      // appearance: textfield;
    }

    &.control-inside {
      padding-right: 0 !important;
      padding-left: 0 !important;

      .input-group-prepend ~ input[type="number"] {
        flex: 1;
      }
    }

    &.text-only {
      // @include swan-form-control-default--line-height;
      line-height: 2rem !important;
    }

    &::placeholder {
      color: $text-03;
    }

    &:focus {
      outline: none;
      box-shadow: 0 2px 0 0 $brand-01;
      transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);

      & ~ label {
        color: $brand-01;
        font-weight: 600;
        transition: font-weight 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      }
    }

    &[data-invalid] {
      box-shadow: 0 1px 0 0 $support-01;

      & ~ .form-text {
        color: $support-01;
      }

      &:focus {
        box-shadow: 0 2px 0 0 $support-01;

        & ~ label {
          color: $support-01;
        }
      }
    }

    &[data-required] {
      & ~ label {
        position: relative;

        &.label-readonly {
          &::after {
            background: map-get($rainbow, "ui-05--l10");
          }
        }

        &::after {
          content: "";
          width: 2rem;
          height: 2px;
          display: inline-block;
          position: absolute;
          bottom: -3px;
          left: 0;
          background: map-get($rainbow, "support-01--l10");
        }
      }

      & ~ .form-text {
      }
    }
  }

  .form-control.swan-datepicker-input {
    box-shadow: none !important;
  }

  .form-text {
    @include swan-form-text-default;
    text-align: right;
    @include font-size("12");
    color: $text-02;
  }
}

@include media-breakpoint-down(sm) {
  .form-group,
  .form-group-alike,
  .#{$prefix}-form-group,
  .#{$prefix}-form-group-alike {
    margin-top: $spacing-xs;
    margin-bottom: $spacing-4xs;

    @include swan-form-group-sm-default;

    &:not(.form-group-of--mapField):not(.form-group-of--textarea):not(.form-group-of--longText) {
      margin-top: $spacing-xs !important;
      flex-wrap: nowrap;

      label {
        justify-content: flex-end;
        word-break: break-word;
      }
    }

    &.form-group-of--textarea,
    &.form-group-of--maField,
    &.form-group-of--longText {
      label {
        text-align: left;
      }
    }

    label,
    .label-alike {
      box-shadow: none;
      @include swan-form-label-sm-default;
      @include font-size("10");
      display: flex;

      margin-right: 0.5rem;
      font-weight: map-get($font-weights, "Regular");
    }

    .form-text {
      @include swan-form-text-sm-default;
    }

    .form-control,
    .PhoneInput {
      @include swan-form-control-sm-default;
      box-shadow: none;
      // height: $layout-md;
      background-color: white;

      &[data-required] {
        & ~ label {
          position: relative;

          &::after {
            content: "*";
            position: relative;
            color: $support-01;
            width: 0;
            height: 0;
            bottom: 0;
          }
        }
      }

      &.form-control-readonly {
        background-color: white;
        ~ label {
          text-align: right;
        }
      }

      &.form-control-readonly--remoteselect {
        display: flex;
        padding-right: 0 !important;
        .remoteselect-button {
          flex-basis: 0.75rem;
          flex-grow: 0;
          padding-right: 0;
          padding-left: 0;
          color: gray;
        }
        .copy-text-wrapper {
          flex-basis: 90%;
          flex-grow: 1;
        }
      }

      &:not(textarea):not(.quill):not(.wysiwyg) {
        @include swan-form-control-default--spacing--sm;
        @include swan-form-control-default--height--sm;
      }
    }

    .form-control,
    label,
    .form-text {
      // flex: 1 1 100%;
    }

    .form-control {
      flex-basis: 70%;
      flex-shrink: 1;
    }
    label,
    .label-alike {
      flex-basis: 30%;
      text-align: right;
    }
    .label-alike {
      flex-grow: 0;
      justify-content: flex-end;
      word-break: break-all;
    }
  }
}

// @todo: make it nicer
.select2-label-selected {
  color: $brand-01;
  font-weight: 600;
  transition: font-weight 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);

  & ~ .select2-control {
    outline: none;
    box-shadow: 0 2px 0 0 $brand-01;
    transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}

.select2-control {
  font-size: font-size("14");
}

.input-group-prepend,
.input-group-append {
  .input-group-text {
    border: none;
    border-radius: 0;
    // background: transparent !important;
    font-size: inherit;
  }
}

.form-control.input-group {
  padding-left: 0 !important;

  input:focus {
    & ~ .input-group-prepend {
      box-shadow: 0 2px 0 0 $brand-01;
      transition: box-shadow 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}

// phone overrides on mobile devices
html[data-layout="mobile"] {
  .form-group-of--tel {
    overflow: hidden !important;
  }
}
