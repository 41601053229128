.oi-arrow-thick-top {
  width: 21px;
  height: 21px;
  margin-left: 2px !important;
  filter: invert(43%) sepia(14%) saturate(2035%) hue-rotate(174deg)
    brightness(94%) contrast(94%);
  background: url("https://api.iconify.design/bi:arrow-up.svg") no-repeat center
    center / contain;
}

.oi-arrow-thick-bottom {
  width: 21px;
  height: 21px;
  margin-left: 2px !important;
  filter: invert(43%) sepia(14%) saturate(2035%) hue-rotate(174deg)
    brightness(94%) contrast(94%);
  background: url("https://api.iconify.design/bi:arrow-down.svg") no-repeat
    center center / contain;
}

.dx-g-bs4-sorting-indicator.invisible {
  width: 20px;
  height: 20px;
  margin-left: 4px !important;
  filter: invert(62%) sepia(92%) saturate(1%) hue-rotate(350deg)
    brightness(113%) contrast(87%);
  background: url("https://api.iconify.design/carbon:arrows-vertical.svg")
    no-repeat center center / contain;
  visibility: initial !important;
}

.text-primary .dx-rg-bs4-table-header-title {
  color: #3d70b2 !important;
  /*font-weight: bold;*/
}

.grid-actions-bar {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.clear-button {
  font-size: 13px;
  border-radius: 6px;
  padding: 2px 8px 2px 8px;
  border: 1px solid #c7c7c7;
  background: #fff;
  color: #696969;
}

.custom-export-button {
  font-size: 13px;
  border-radius: 6px;
  padding: 2px 8px 2px 8px;
  border: 1px solid #c7c7c7;
  background: #3d70b2;
  color: #ffffff;
  margin-left: 4px;
}

.custom-list-button {
  font-size: 13px;
  border-radius: 6px;
  padding: 2px 8px 2px 8px;
  border: 1px solid #c7c7c7;
  background: #3d70b2;
  color: #ffffff;
  margin-left: 4px;
}

.export-button {
  font-size: 13px;
  border-radius: 6px;
  padding: 2px 8px 2px 8px;
  margin-left: 4px;
}

.export-button:focus,
.export-button:active {
  border: none !important;
  outline: 0 !important;
}

.swan-list-grid .table th,
.swan-list-grid .table td {
  font-size: 13px !important;
  padding: 0.5rem !important;
  vertical-align: initial !important;
}

.swan-list-grid .table tbody tr:nth-child(even) {
  background: #f1f1f1;
  border: 0px;
  color: #000;
}
.swan-list-grid .table tbody tr:nth-child(odd) {
  background: #fff;
}
