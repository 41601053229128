@import "../global/spacing";

.swan-main {
  @include min-width-spacing-break-point(768px, 0, 0.5rem);

  .module {
    @include min-width-spacing-break-point(768px, 0, 0.5rem);

    // .crud-elms {}

    .module-content {
      & > .row {
        &:first-child {
          margin-left: 0;
          margin-right: 0;
        }
      }
      @include min-width-spacing-break-point(768px, $spacing-md, 0.5rem);

      background: white;
    }

    .all-modules-dashboard-container {
      background: #f1f4f9 !important;
      min-height: 100vh;
    }
  }
}

html[data-layout="mobile"] {
  .swan-main {
    padding: 0.5rem;

    .module {
      .module-content {
        background: #f6f6f6;
      }
    }
  }
}
