.form-control-upload {
  height: auto !important;

  & ~ label {
    margin-top: 0.75rem;
    align-items: flex-start;
  }
}
.dropzone-upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.dropzone-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
}

.dropzone-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;

  &:empty {
    display: none;
    height: 0;
  }
}

.dropzone-file-input {
  display: none !important;
}

.dropzone {
  height: 4rem;
  width: 100%;
  background-color: #fff;
  border: 2px dashed rgba(136, 151, 162, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.highlight {
    background-color: #dfe3e6;
  }

  &.disabled {
    cursor: default;
    border: 2px dashed rgba(136, 151, 162, 0.3);
  }
}

.dropzone-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
