@import "../../global/spacing";

$bg-color: #e7e7e7;
$filler-height: 2rem;

@mixin title-wrapper {
  padding: 0 1rem !important;
  margin-left: -1rem !important;
  margin-right: -1rem !important;
  position: relative;
  z-index: 100;
}

@mixin title-filler {
  position: absolute;
  background-color: $bg-color;
  left: -1rem;
  right: -1rem;
  width: calc(100% + 2rem);
  z-index: -100;
}
// mobile
@include media-breakpoint-down(sm) {
  .cwt--wrapper {
    // padding: 0;
    // when closed
    &.cwt--wrapper-with-closed {
      padding: 0 1rem !important;
      margin-left: -1rem !important;
      margin-right: -1rem !important;
      position: relative;
      z-index: 100;
      background-color: $bg-color;
      height: $filler-height;
      margin-bottom: $spacing-4xs !important;

      .cwt--title-filler {
        @include title-filler;
        height: $filler-height;
      }

      .cwt--title {
        padding-left: 1rem;
        padding-right: 0rem;
      }
    }

    // when open
    &.cwt--wrapper-with-open {
      padding: 0 1rem !important;
      position: relative;
      z-index: 100;
      // height: 2rem;
      .cwt--title-filler {
        @include title-filler;
        height: 2rem;
      }

      .cwt--title {
        padding-right: 0px;
        margin-right: -1rem;
      }
    }

    .cwt--content-with-open {
      padding-top: 1rem;
    }

    .cwt--title {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      border-bottom: none;
      line-height: 2rem;
    }
  }

  .cwt--toggle-button {
    button {
      border: none;

      &:focus,
      &:hover,
      &:active {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
        background: none !important;
      }
    }
  }
}
