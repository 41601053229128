// copy fonts directory to your project and specify font directory relative to your main scss file
// using $font-path variable
$font-path: "../fonts" !default;

@import "../import-once";

$unicodes: (
  Pi:
    "U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC",
  Latin3: "U+0102-0103, U+1EA0-1EF9, U+20AB",
  Latin2:
    "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02",
  Latin1:
    "U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02"
);

$families: (
  "Mono": unquote("'ibm-plex-mono'"),
  "Sans": unquote("'ibm-plex-sans'")
);

$fallbacks: (
  "Mono":
    unquote(
      "'ibm-plex-mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace"
    ),
  "Sans": unquote("'ibm-plex-sans', 'Helvetica Neue', Arial, sans-serif")
);

$font-weights: (
  "Light": 300,
  "Regular": 400,
  "SemiBold": 600
) !default;

$weights: (
  "Light": (
    font-style: normal,
    font-weight: map-get($font-weights, "Light")
  ),
  "Regular": (
    font-style: normal,
    font-weight: map-get($font-weights, "Regular")
  ),
  "SemiBold": (
    font-style: normal,
    font-weight: map-get($font-weights, "SemiBold")
  )
) !default;

// ibm flex face
@mixin plex-font-face {
  @each $family, $name in $families {
    @each $weight, $styles in $weights {
      @font-face {
        font-family: $name;
        font-style: map-get($styles, "font-style");
        font-weight: map-get($styles, "font-weight");
        src: url("#{$font-path}/IBMPlex#{$family}-#{$weight}.woff")
          format("woff");
      }

      @each $unicode, $range in $unicodes {
        @font-face {
          font-family: $name;
          font-style: map-get($styles, "font-style");
          font-weight: map-get($styles, "font-weight");
          src: url("#{$font-path}/IBMPlex#{$family}-#{$weight}-#{$unicode}.woff2")
            format("woff2");
          unicode-range: map-get($unicodes, $unicode);
        }
      }
    }
  }
}

// fallback
@mixin helvetica-font-face {
  @font-face {
    font-family: "IBM Helvetica";
    font-style: normal;
    font-weight: 300;
    src: url("#{$font-path}/helvetica-neue-light.woff2") format("woff2"),
      url("#{$font-path}/helvetica-neue-light.woff") format("woff");
  }

  @font-face {
    font-family: "IBM Helvetica";
    font-style: italic;
    font-weight: 300;
    src: url("#{$font-path}/helvetica-neue-light-italic.woff2") format("woff2"),
      url("#{$font-path}/helvetica-neue-light-italic.woff") format("woff");
  }

  @font-face {
    font-family: "IBM Helvetica";
    font-style: normal;
    font-weight: 400;
    src: url("#{$font-path}/helvetica-neue-roman.woff2") format("woff2"),
      url("#{$font-path}/helvetica-neue-roman.woff") format("woff");
  }

  @font-face {
    font-family: "IBM Helvetica";
    font-style: italic;
    font-weight: 400;
    src: url("#{$font-path}/helvetica-neue-roman-italic.woff2") format("woff2"),
      url("#{$font-path}/helvetica-neue-roman-italic.woff") format("woff");
  }

  @font-face {
    font-family: "IBM Helvetica";
    font-style: normal;
    font-weight: 700;
    src: url("#{$font-path}/helvetica-neue-bold.woff2") format("woff2"),
      url("#{$font-path}/helvetica-neue-bold.woff") format("woff");
  }

  @font-face {
    font-family: "IBM Helvetica";
    font-style: italic;
    font-weight: 700;
    src: url("#{$font-path}/helvetica-neue-bold-italic.woff2") format("woff2"),
      url("#{$font-path}/helvetica-neue-bold-italic.woff") format("woff");
  }
}

@include exports("css--font-face") {
  @if not global-variable-exists("css--font-face") or $css--font-face == true {
    @if global-variable-exists("use-ibm-plex-family") and
      $use-ibm-plex-family ==
      true
    {
      @include plex-font-face;
    } @else {
      @include helvetica-font-face;
    }
  }
}
